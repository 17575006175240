/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useEffect, useState } from "react";
import {
  setHipaaStatus,
  setDeleteIsLoading,
  setError,
  setIsLoaded,
  setPollingEnabled,
  setRequestCount,
  setTempAdmin,
  setWorkInProgress,
  setErrorMessage,
} from "../store/tempAdminUser/tempAdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  tempAdminApi,
  useGetProjectHipaaStatusQuery,
  useCreateTempAdminMutation,
  useDeleteTempAdminMutation,
  useGetTempAdminQuery,
} from "../services/tempAdmin";
import { ToastQueue } from "@react-spectrum/toast";
import getProjectId from "../utils/getProjectId";
import getEnvironment from "../utils/getEnvironment";
import { hipaaError } from "../store/dump/dumpSlice";

const useTempAdmin = () => {
  const dispatch = useDispatch();
  const tempAdminObject = useSelector((state: RootState) => state.tempAdmin);
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const userObject = useSelector((state: RootState) => state.user);
  const rengaId = caseObject?.admin?.rengaId || "";
  const orgId = caseObject?.orgId || "";
  const productMetadata = caseObject?.productMetadata ?? [];
  let environment =
    productMetadata
      .find((item) => item.displayName === "Environment")
      ?.value.toLowerCase() || "";

  // temporary solution for getting valid environment value
  environment = getEnvironment(environment);

  const currentUserEmail = userObject.userDetails?.email || "";
  const ticketId = caseObject?.caseId || "";
  const projectUrl =
    productMetadata.find((item) => item.displayName === "Project URL")?.value ||
    "";
  let projectId = "";

  if (projectUrl) {
    projectId = getProjectId(projectUrl);
  }

  let pollingEnabled = useSelector(
    (state: RootState) => state.tempAdmin.pollingEnabled,
  );
  let requestCount =
    useSelector((state: RootState) => state.tempAdmin.requestCount) || 0;
  let deleteIsLoading = tempAdminObject.deleteIsLoading;
  let [createAdminIsLoading, setCreateAdminIsLoading] = useState(false);

  const {
    data: queryData,
    currentData: queryCurrentData,
    isLoading: queryIsLoading,
    error: queryError,
    isSuccess: queryIsSuccess,
    isFetching: queryIsFetching,
  } = useGetTempAdminQuery({ projectId, environment, currentUserEmail });

  const { data, currentData, isLoading, error, isSuccess, isFetching } =
    useGetTempAdminQuery(
      { projectId, environment, currentUserEmail },
      { pollingInterval: pollingEnabled ? 2000 : 0 },
    );

  const [
    createAdmin,
    {
      data: createAdminData,
      isLoading: isCreating,
      error: createAdminError,
      isSuccess: createdSuccessfully,
    },
  ] = useCreateTempAdminMutation();

  const [deleteAdmin, { isLoading: isDeleting, error: deleteError }] =
    useDeleteTempAdminMutation();

  const { data: hipaaStatus } = useGetProjectHipaaStatusQuery(
    { projectId },
    { pollingInterval: 3000 },
  );

  const handleCreateAdmin = () => {
    if (projectId === "" || environment === "") {
      console.log(
        "Make sure that environment, project Id are specified in Dynamics ticket",
      );
      setErrorMessage(
        "Make sure that environment, project Id are specified in Dynamics ticket",
      );
      return;
    }
    setCreateAdminIsLoading(true);
    dispatch(setWorkInProgress(true));
    dispatch(setIsLoaded(false));
    createAdmin({
      projectId,
      environment,
      currentUserEmail,
      ticketId,
      rengaId,
      orgId,
    });
    dispatch(setPollingEnabled(true));
    dispatch(tempAdminApi.util.resetApiState());
    dispatch(setRequestCount(0));
    dispatch(setErrorMessage(""));
    dispatch(setError(""));
  };

  const handleDeleteAdmin = () => {
    if (projectId === "" || environment === "") {
      console.log(
        "Make sure that environment, project Id are specified in Dynamics ticket",
      );
      setErrorMessage(
        "Make sure that environment, project Id are specified in Dynamics ticket",
      );
      return;
    }
    deleteAdmin({ projectId, environment, currentUserEmail, ticketId });
    dispatch(setDeleteIsLoading(true));
    dispatch(setPollingEnabled(true));
    dispatch(setErrorMessage(""));
    dispatch(setError(""));
    dispatch(setWorkInProgress(true));
    dispatch(setIsLoaded(false));
    dispatch(setRequestCount(0));
  };

  useEffect(() => {
    dispatch(setHipaaStatus(hipaaStatus?.hipaa));

    if (createAdminError || deleteError) {
      dispatch(
        setErrorMessage(
          //@ts-ignore
          createAdminError?.data?.errorMessage ||
            //@ts-ignore
            deleteError?.data?.errorMessage ||
            "Server does not respond. Try again later",
        ),
      );
      dispatch(setWorkInProgress(false));
      dispatch(setIsLoaded(true));
      dispatch(setPollingEnabled(false));
    } else if (requestCount >= 30) {
      if (deleteIsLoading && tempAdminObject.user.email) {
        dispatch(setErrorMessage("Server does not respond. Try again later"));
      }
      dispatch(setDeleteIsLoading(false));
      dispatch(setPollingEnabled(false));
      dispatch(setIsLoaded(true));
    } else if (deleteIsLoading && data && isSuccess) {
      dispatch(setRequestCount(requestCount + 1));
    } else if (isLoading) {
      dispatch(setWorkInProgress(true));
    } else if (isSuccess && data && !deleteIsLoading) {
      dispatch(setTempAdmin(data));
      dispatch(setWorkInProgress(false));
      dispatch(setIsLoaded(true));
      dispatch(setPollingEnabled(false));
      dispatch(setRequestCount(requestCount + 1));
      dispatch(setError([]));
    } else if (error) {
      //@ts-ignore
      if (error.status === 500) {
        ToastQueue.negative("Server does not respond. Try again later", {
          timeout: 5000,
        });
      }
      const errorMessage =
        //@ts-ignore
        error.data?.message || "Unknown error. Please report the bug.";
      dispatch(setError(errorMessage));
      dispatch(setWorkInProgress(false));
      dispatch(setRequestCount(requestCount + 1));
      //@ts-ignore
      if (deleteIsLoading && error.status === 404) {
        dispatch(setIsLoaded(true));
        dispatch(setTempAdmin({}));
        dispatch(setPollingEnabled(false));
        dispatch(setDeleteIsLoading(false));
        dispatch(setRequestCount(0));
      }
    }
  }, [
    hipaaStatus,
    deleteError,
    error,
    isLoading,
    isSuccess,
    currentData,
    data,
    isFetching,
  ]);

  // errorMessage handling
  useEffect(() => {
    //@ts-ignore
    if (tempAdminObject?.errorMessage) {
      //@ts-ignore
      console.log(tempAdminObject.errorMessage);
      //@ts-ignore
      ToastQueue.negative(tempAdminObject?.errorMessage, { timeout: 5000 });
      dispatch(setErrorMessage(""));
    }
  }, [tempAdminObject.errorMessage]);

  return {
    handleDeleteAdmin,
    handleCreateAdmin,
    tempAdminObject,
    isCreating,
  };
};

export default useTempAdmin;
