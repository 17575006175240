/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect, useState } from "react";
import MessageComponent from "./MessageComponent";
import useCaseData from "../../hooks/useCaseData";
import { useGetIsProductFamilySupportedQuery } from "../../services/supportInsights";
import { ToastQueue } from "@react-spectrum/toast";
import { CaseResponse } from "../../types/case";
import familyNameToCode from "../../utils/familyNameToCode";
import { addRecentCase } from "../../store/userSlice";
import { useDispatch } from "react-redux";
import { useLocation, matchPath } from "react-router-dom";

interface PageWrapperProps extends React.PropsWithChildren<{}> {
  pageName: string;
}

const defaultErrorMessage =
  "Something went wrong, please verify your case information and refresh the page.";

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  const {
    caseObject,
    refreshError,
    isLoading,
    caseId,
    caseResponseData,
    forceTicketDataReload,
    doneLoading,
  } = useCaseData();
  const dispatch = useDispatch();
  const location = useLocation(); // Access the current location

  // Define the route pattern for Recent Cases
  const routePattern = "/review/case/:caseId/org_id/:orgId/renga_id/:rengaId";
  // Check if the current URL matches the route pattern
  const match = matchPath(routePattern, location.pathname);

  if (match && match.params.caseId) {
    const recentCase = {
      caseId: match.params.caseId,
      url: window.location.href,
    };
    dispatch(addRecentCase(recentCase));
  }

  const productFamily = caseObject?.productFamilyName ?? "";
  const productFamilyCode = familyNameToCode(productFamily);
  const {
    data,
    error: supportError,
    isLoading: supportLoading,
  } = useGetIsProductFamilySupportedQuery(productFamilyCode, {
    skip: !productFamilyCode,
  });
  const isSupported = data?.data.isSupported;

  const [publicMessage, setPublicMessage] = useState<string | null>(null);

  useEffect(() => {
    if (doneLoading) {
      if (refreshError && "data" in refreshError) {
        const response = refreshError.data as CaseResponse;
        const message = response?.meta?.publicMessage ?? "";
        if (message) {
          setPublicMessage(message);
          ToastQueue.negative(message, { timeout: 5000 });
        }
      }
      if (caseResponseData && "meta" in caseResponseData) {
        const message = caseResponseData.meta.publicMessage;
        if (message) {
          setPublicMessage(message);
          ToastQueue.negative(message, { timeout: 5000 });
        }
      }
    }
  }, [refreshError, caseResponseData, doneLoading]);

  if (isLoading || supportLoading || forceTicketDataReload) {
    return (
      <MessageComponent title={"Oasis is Loading"} isLoading={true}>
        We are fetching your Support Insights for case:{" "}
        <strong>{caseId}</strong>.
      </MessageComponent>
    );
  }

  if (caseObject && isSupported) {
    return <>{children}</>;
  }

  if (!caseObject || refreshError || supportError) {
    return (
      <MessageComponent title="Case unavailable">
        The case <strong>{caseId}</strong> you are searching details for is not
        available.
      </MessageComponent>
    );
  }

  if (!isSupported && caseObject.productFamilyName) {
    return (
      <MessageComponent title="Product not supported">
        The case <strong>{caseId}</strong> belongs to the{" "}
        <strong>{productFamily}</strong> product family. Only cases under Adobe
        DX are supported.
      </MessageComponent>
    );
  }

  return (
    <MessageComponent title="Something went wrong">
      {publicMessage ? publicMessage : defaultErrorMessage}
    </MessageComponent>
  );
};

export default PageWrapper;
