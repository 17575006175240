/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { micromark } from "micromark";
import MessageComponent from "../components/common/MessageComponent";
import { DynamicsDataPoint } from "../types/datapoints";
import notEmpty from "./notEmpty";
import TableDataView from "../components/viewers/TableDataView";
import { useEffect, useState } from "react";

const DynamicDataRenderer = (props: any) => {
  const [ViewComponent, setViewComponent] = useState(null);
  const { json } = props;
  const importComponent = async (componentName: string) => {
    return import("../components/viewers/" + componentName + ".tsx");
  };

  useEffect(() => {
    const loadComponent = async () => {
      const { default: LoadedComponent } = await importComponent(
        json.metadata.component,
      );
      setViewComponent(() => LoadedComponent);
    };
    loadComponent().then((r) => r);
  }, [json.metadata.component]);

  if (!ViewComponent) return <div>Loading...</div>;

  // @ts-ignore
  return <ViewComponent json={json} />;
};

export type Aggregated = (string | Record<string, string>)[];

export class DataAggregator {
  private dydap: DynamicsDataPoint[] = [];

  constructor({ data }: { data?: DynamicsDataPoint[] } = {}) {
    if (data) {
      this.add(data);
    }
  }

  add(data: DynamicsDataPoint[]) {
    for (const x of data) {
      this.dydap.push(x);
    }
  }

  aggregate(): Aggregated {
    return this.dydap
      .map((point) => {
        const value = point.value;
        try {
          const parsed = JSON.parse(value);
          if (!Array.isArray(parsed)) {
            throw TypeError("Expected an array");
          }
          if (!parsed.every((x) => typeof x === "object")) {
            throw TypeError("Expected an array of objects");
          }
          return parsed as Record<string, string>[];
        } catch (e) {
          if (!value) return null;
          return value;
        }
      })
      .filter(notEmpty)
      .flat();
  }

  hasData() {
    const aggregated = this.aggregate();
    return aggregated.length !== 0;
  }

  isJsonString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  render(): React.ReactNode {
    const aggregated = this.aggregate();

    if (aggregated.length === 0)
      return (
        <MessageComponent title="No data found">
          No data was collected. Check back later.
        </MessageComponent>
      );

    if (typeof aggregated[0] === "string" && aggregated.length === 1) {
      if (this.isJsonString(aggregated[0])) {
        const json = JSON.parse(aggregated[0]);
        if (
          json.data &&
          json.data.length > 0 &&
          json.metadata &&
          json.metadata.component
        ) {
          return <DynamicDataRenderer json={json} />;
        }
      }
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: micromark(aggregated[0], "utf-8", {
              allowDangerousHtml: true,
            }),
          }}
        ></div>
      );
    }

    if (aggregated.every((v) => typeof v === "string")) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: aggregated
              .map((v) =>
                micromark(v as string, "utf-8", { allowDangerousHtml: true }),
              )
              .join("\n"),
          }}
        ></div>
      );
    }

    if (aggregated.length === 0) {
      return (
        <MessageComponent title="No data found">
          No data was collected. Check back later.
        </MessageComponent>
      );
    }

    return TableDataView(aggregated);
  }
}
