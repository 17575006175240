/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TempAdmin } from "../types/tempAdmin";

interface UserData {
  id: number;
  project: string;
  environment: string;
  email: string;
  password: string;
  username: string;
  ticket: string;
  adminUrl: string;
  adminUri: string;
}
interface hipaaStatusResponse {
  hipaa: boolean;
}

// create tempAdminApi
export const tempAdminApi = createApi({
  reducerPath: "tempAdminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.env.REACT_APP_DUMP_TOOL_API_US,
    credentials: "same-origin",
    timeout: 3000,
  }),
  endpoints: (builder) => ({
    getProjectHipaaStatus: builder.query<
      hipaaStatusResponse,
      { projectId: string }
    >({
      query: ({ projectId }) => `/api/v1/dump-hipaa/${projectId}`,
      transformResponse: (response: { hipaa: string }) => ({
        ...response,
        hipaa: response.hipaa === "true", // Convert the 'hipaa' field to boolean
      }),
    }),
    getTempAdmin: builder.query<
      UserData,
      {
        projectId: string;
        environment: string;
        currentUserEmail: string;
      }
    >({
      query: ({ projectId, environment, currentUserEmail }) =>
        `/api/v1/admin-users/${projectId}/${environment}/${currentUserEmail}`,
    }),
    createTempAdmin: builder.mutation<
      TempAdmin,
      {
        projectId: string;
        environment: string;
        currentUserEmail: string;
        ticketId: string;
        rengaId: string;
        orgId: string;
      }
    >({
      query: ({
        projectId,
        environment,
        currentUserEmail,
        ticketId,
        rengaId,
        orgId,
      }) => ({
        url: `/api/v1/admin-users/${projectId}/${environment}/${currentUserEmail}/${ticketId}/${orgId}/${rengaId}`,
        method: "POST",
      }),
    }),
    deleteTempAdmin: builder.mutation<
      TempAdmin,
      {
        projectId: string;
        environment: string;
        currentUserEmail: string;
        ticketId: string;
      }
    >({
      query: ({ projectId, environment, currentUserEmail, ticketId }) => ({
        url: `/api/v1/admin-users/${projectId}/${environment}/${currentUserEmail}/${ticketId}`,
        method: "DELETE",
      }),
    }),
    deleteTempAdminAll: builder.mutation<
      TempAdmin,
      {
        projectId: string;
        environment: string;
        currentUserEmail: string;
        ticketId: string;
      }
    >({
      query: ({ projectId, environment, ticketId }) => ({
        url: `/api/v1/admin-users-all/${projectId}/${environment}/${ticketId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetProjectHipaaStatusQuery,
  useGetTempAdminQuery,
  useCreateTempAdminMutation,
  useDeleteTempAdminMutation,
  useDeleteTempAdminAllMutation,
} = tempAdminApi;
