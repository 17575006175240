/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { ActionButton, Text } from "@adobe/react-spectrum";
import Edit from "@spectrum-icons/workflow/Edit";
import { setOpenPreferencesModal } from "../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import UserAdmin from "@spectrum-icons/workflow/UserAdmin";
import { RootState } from "../../store/store";

const EditUserPreferencesButton = () => {
  const isUserPersonaFeatureActive =
    localStorage.getItem("userPersonaFeatureActive") === "true";
  const userPreferences = useSelector(
    (state: RootState) => state.user.preferences,
  );
  const productFamily =
    userPreferences?.product_families[0]?.name || "Select a Product Family";
  const jobRole = userPreferences?.job_role_name || "Select a Job Role";

  const dispatch = useDispatch();
  return (
    <>
      {/*TODO: Remove conditional when user persona features are complete*/}
      {isUserPersonaFeatureActive && (
        <ActionButton
          staticColor="white"
          isQuiet
          onPress={() => dispatch(setOpenPreferencesModal(true))}
        >
          <Edit />
          <UserAdmin />
          <Text>{productFamily + " | " + jobRole}</Text>
        </ActionButton>
      )}
    </>
  );
};

export default EditUserPreferencesButton;
