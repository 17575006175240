/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { parseAndSanitizeMarkdown } from "./sanitizeMarkdown";

interface MarkdownRendererProps {
  /** The content to be rendered, can be Markdown or already sanitized HTML. */
  content: string;
  /** Flag to indicate whether the content is already sanitized HTML. Defaults to false. */
  isSanitizedHTML?: boolean;
}

/**
 * Renders Markdown or sanitized HTML content safely.
 * By default, the content(Markdown) is sanitized using DOMPurify, unless the content is already sanitized HTML
 * and the `isSanitizedHTML` flag is set to true.
 *
 * @param content - The content to be rendered, can be Markdown or already sanitized HTML.
 * @param isSanitizedHTML - Flag to indicate whether the content is already sanitized HTML. Defaults to false.
 */
const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  content,
  isSanitizedHTML = false,
}) => {
  const renderedContent = isSanitizedHTML
    ? content
    : parseAndSanitizeMarkdown(content);
  return (
    <div
      className="markdown-container"
      dangerouslySetInnerHTML={{ __html: renderedContent }}
    />
  );
};

export default MarkdownRenderer;
