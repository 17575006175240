/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useState, useEffect } from "react";
import {
  Button,
  View,
  Text,
  Image,
  StatusLight,
  Flex,
  Provider,
  ButtonGroup,
  ProgressCircle,
} from "@adobe/react-spectrum";
import CrossSmall from "@spectrum-icons/ui/CrossSmall";
import InfoSmall from "@spectrum-icons/ui/InfoSmall";
import BannerForm from "./BannerForm";
import { useOktaAuth } from "@okta/okta-react";
import { useDeleteBannerMutation } from "../../services/supportInsights";
import { useDispatch, useSelector } from "react-redux";
import { clearBannerData } from "../../store/bannerSlice";
import { RootState } from "../../store/store";
import { useBannerVisibility } from "../../hooks/useBannerVisibility";
import { useBannerDateTimes } from "../../hooks/useBannerDateTimes";

const AdminBanner: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const [showContainer, setShowContainer] = useState(false);

  const { oktaAuth } = useOktaAuth();
  const token: string | undefined = oktaAuth.getAccessToken();
  const skip = !token;
  const { data, refetch, isMessageVisible } = useBannerVisibility(token);

  const dispatch = useDispatch();
  const [deleteBanner] = useDeleteBannerMutation();
  const bannerData = useSelector((state: RootState) => state.banner.bannerData);
  const { startDateTime, endDateTime } = useBannerDateTimes(
    bannerData?.data?.startDate,
    bannerData?.data?.endDate,
  );
  useEffect(() => {
    const delay = setTimeout(() => {
      setShowContainer(true);
    }, 300);
    if (bannerData) {
      clearTimeout(delay);
      setShowContainer(true);
    }
    return () => clearTimeout(delay);
  }, [bannerData]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleFormSubmit = () => {
    setIsModalOpen(false);
    setEditing(true);
    refetch();
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setEditing(false);
  };
  const handleDelete = async () => {
    try {
      if (bannerData?.id) {
        await deleteBanner({ id: bannerData?.id, token: token! }).unwrap();
        dispatch(clearBannerData());
        refetch();
      }
    } catch (error) {
      console.error("Failed to delete the banner:", error);
    }
  };
  const handleEdit = () => {
    setEditing(true);
    setIsModalOpen(true);
  };
  if (!showContainer) {
    return null;
  }

  return (
    <div className="banner-container">
      <div className="banner-container-box">
        <Flex direction="row" alignItems="center" gap="size-200">
          <InfoSmall UNSAFE_className="info-small" aria-label="Info" />
          <Text>
            {bannerData
              ? `${bannerData.data.message} ${startDateTime} to ${endDateTime}`
              : "Text goes here..."}
          </Text>
        </Flex>
        <div>
          {bannerData ? (
            <ButtonGroup>
              <Button
                variant="primary"
                staticColor="white"
                UNSAFE_style={{ marginLeft: "10px" }}
                onPress={handleEdit}
              >
                Edit
              </Button>
              <Button
                variant="primary"
                staticColor="white"
                UNSAFE_style={{ marginLeft: "10px" }}
                onPress={handleDelete}
              >
                Delete
              </Button>
            </ButtonGroup>
          ) : (
            <Button
              variant="primary"
              staticColor="white"
              UNSAFE_style={{ marginLeft: "20px" }}
              onPress={openModal}
            >
              Add
            </Button>
          )}
          {isModalOpen && (
            <BannerForm
              closeModal={closeModal}
              onClose={handleFormSubmit}
              onCancel={onCancel}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default AdminBanner;
