/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BannerState {
  bannerData: {
    id: number;
    name: string;
    code: string;
    data: {
      message: string;
      startDate: string;
      endDate: string;
    };
    created_at: string;
    updated_at: string;
  } | null;
}

const initialState: BannerState = {
  bannerData: null,
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setBannerData: (
      state,
      action: PayloadAction<BannerState["bannerData"]>,
    ) => {
      state.bannerData = action.payload;
    },
    clearBannerData: (state) => {
      state.bannerData = null;
    },
  },
});

export const { setBannerData, clearBannerData } = bannerSlice.actions;
export default bannerSlice.reducer;
