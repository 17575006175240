/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Divider, View, Flex } from "@adobe/react-spectrum";

interface HomePageCardTableRowProps {
  children: React.ReactNode;
}

const HomePageCardTableRow: React.FC<HomePageCardTableRowProps> = ({
  children,
}) => {
  return (
    <View width="100%">
      <Flex direction="row" gap="size-250">
        {children}
      </Flex>
      <Divider size="S" marginY="size-150" />
    </View>
  );
};

export default HomePageCardTableRow;
