/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useState } from "react";
import { Button, View, Text, Flex } from "@adobe/react-spectrum";
import CrossSmall from "@spectrum-icons/ui/CrossSmall";
import InfoSmall from "@spectrum-icons/ui/InfoSmall";
import { useOktaAuth } from "@okta/okta-react";
import { useBannerVisibility } from "../../hooks/useBannerVisibility";
import { useBannerDateTimes } from "../../hooks/useBannerDateTimes";

const UserBanner: React.FC = () => {
  const [isBannerClosed, setIsBannerClosed] = useState(false);

  const handleClose = () => {
    setIsBannerClosed(true);
  };

  const { oktaAuth } = useOktaAuth();
  const token: string | undefined = oktaAuth.getAccessToken();
  const skip = !token;
  const { data, isMessageVisible } = useBannerVisibility(token);
  const { startDateTime, endDateTime } = useBannerDateTimes(
    data?.[0]?.data?.startDate,
    data?.[0]?.data?.endDate,
  );

  return (
    <>
      {isMessageVisible &&
        !isBannerClosed &&
        data &&
        Array.isArray(data) &&
        data.length > 0 && (
          <div className="banner-container">
            <div className="banner-container-box">
              <Flex direction="row" alignItems="center" gap="size-200">
                <InfoSmall aria-label="Info" />
                <Text>
                  {data[0].data.message} {startDateTime} to {endDateTime}
                </Text>
              </Flex>
              <View
                width="1px"
                height="27px"
                UNSAFE_style={{
                  background: "#FFFFFF33 0% 0% no-repeat padding-box",
                  opacity: 1,
                  margin: "4px 8px 4px 40px",
                }}
              />
              <Button
                variant="secondary"
                onPress={handleClose}
                aria-label="Close"
                UNSAFE_style={{
                  padding: 0,
                  backgroundColor: "transparent",
                  color: "white",
                  border: "none",
                }}
              >
                <CrossSmall />
              </Button>
            </div>
          </div>
        )}
    </>
  );
};
export default UserBanner;
