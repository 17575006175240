/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { View, Flex, Heading, ProgressCircle } from "@adobe/react-spectrum";
import SentimentNegative from "@spectrum-icons/workflow/SentimentNegative";

export interface ErrorMessageProps {
  title: string;
  isLoading?: boolean;
}

const MessageComponent: React.FC<
  React.PropsWithChildren<ErrorMessageProps>
> = ({ title, isLoading = false, children }) => {
  return (
    <Flex
      margin={"single-line-height"}
      alignItems={"safe center"}
      direction={"column"}
    >
      <View>
        {isLoading ? (
          <ProgressCircle size={"M"} isIndeterminate />
        ) : (
          <SentimentNegative
            margin={"size-0"}
            size={"M"}
            UNSAFE_style={{ opacity: ".25" }}
          />
        )}
      </View>
      <View>
        <Heading level={1} margin={"size-0"} marginBottom={"size-100"}>
          {title}
        </Heading>
      </View>
      <View UNSAFE_style={{ textAlign: "center" }}>{children}</View>
    </Flex>
  );
};

export default MessageComponent;
