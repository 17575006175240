/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect, useState } from "react";
import AiMarkdownRenderer from "../../aiAssistant/AiMarkdownRenderer";
import { fetchAIResponseStream } from "../../../services/requestServices";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

export interface JiraTicketSummaryProps {
  ticketId: string;
}

const JiraTicketSummary: React.FC<JiraTicketSummaryProps> = ({ ticketId }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [summary, setSummary] = useState<string>("");

  const summarizeTicket = (ticketId: string) => {
    setSummary("Loading...");
    const url = `${window.env.REACT_APP_API_BASE}cfs/getJiraRcaSummary/${ticketId}`;
    fetchAIResponseStream(url, token, {}, (response) =>
      setSummary(response?.text || ""),
    ).then((obj) => {
      if (obj?.error) {
        setSummary("Error fetching Jira summary");
      }
    });
  };

  // strange issue where the useEffect is running twice while React.StrictMode is enabled
  const hasRun = React.useRef(false);
  useEffect(() => {
    if (!hasRun.current) {
      summarizeTicket(ticketId);
      hasRun.current = true;
    }
  }, [ticketId]);

  return (
    <>
      <AiMarkdownRenderer text={summary} />
    </>
  );
};

export default JiraTicketSummary;
