/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import {
  Cell,
  Column,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
  Flex,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import React from "react";
import SelectableTableCellContent from "../common/SelectableTableCellContent";

function copyTextToClipboard(text: string) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      ToastQueue.positive("Copied to clipboard", { timeout: 5000 });
    })
    .catch((err) => {
      ToastQueue.negative("Failed to copy to clipboard", { timeout: 5000 });
    });
}

function TableDataView(
  json: (string | { [key: string]: string })[],
): JSX.Element {
  const failsafe = <p>{JSON.stringify(json)}</p>;

  try {
    if (!Array.isArray(json)) return failsafe;
    const columns = ["#", ...Object.keys(json[0])];
    const rows = json.map((entry) => Object.values(entry));

    if (!json.every((x) => typeof x === "object")) return failsafe;
    if (columns.length === 0) return failsafe;

    return (
      <Flex direction="column">
        <Flex
          direction="row"
          justifyContent="space-between"
          marginBottom={"size-100"}
        >
          <em>Double click a table row to copy its content.</em>
          <Text>Rows: {rows.length}</Text>
        </Flex>
        <TableView
          aria-label="Dynamic Table"
          selectionMode="single"
          selectionStyle="highlight"
          onAction={(key) => copyTextToClipboard(JSON.stringify(rows[+key]))}
          overflowMode="wrap"
          UNSAFE_className="oasis-data-table"
        >
          <TableHeader>
            {columns.map((key, index) => {
              if (index === 0) {
                return (
                  <Column key={`col-${key}`} width={"1%"}>
                    {key}
                  </Column>
                );
              }
              return <Column key={`col-${key}`}>{key}</Column>;
            })}
          </TableHeader>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <Row key={i}>
                  {[
                    <Cell key={`cell-${i}-0`}>{i + 1}</Cell>,
                    ...row.map((cell, j) => (
                      <Cell key={`cell-${i}-${j + 1}`}>
                        <SelectableTableCellContent>
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            allowedElements={["a", "br", "p", "em"]}
                            components={{
                              a: ({ node, children, ...props }) => {
                                if (props.href?.includes("mailto")) {
                                  return <>{children}</>;
                                }
                                if (props.href?.includes("http")) {
                                  props.target = "_blank";
                                  props.rel = "noopener noreferrer";
                                }
                                return <a {...props}>{children}</a>;
                              },
                            }}
                          >
                            {"" + cell}
                          </ReactMarkdown>
                        </SelectableTableCellContent>
                      </Cell>
                    )),
                  ]}
                </Row>
              );
            })}
          </TableBody>
        </TableView>
      </Flex>
    );
  } catch (error) {
    return failsafe;
  }
}

export default TableDataView;
