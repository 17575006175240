/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import {
  TableView,
  Column,
  TableHeader,
  TableBody,
  Row,
  Cell,
  Flex,
  Picker,
  Item,
  Link,
  ProgressCircle,
  Text,
  Avatar,
  SearchField,
  IllustratedMessage,
  Heading,
  Content,
  DialogTrigger,
  ActionButton,
  Dialog,
  Button,
  TextField,
  Divider,
  ButtonGroup,
  Form,
  CheckboxGroup,
  Checkbox,
} from "@adobe/react-spectrum";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  FormEvent,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import {
  Defect,
  SalesforceCaseItem,
  DefectTableType,
  DynamicsCaseItem,
} from "./types";
import { RootState } from "../../store/store";
import { ToastQueue } from "@react-spectrum/toast";
import Filter from "@spectrum-icons/workflow/Filter";
import FilterCheck from "@spectrum-icons/workflow/FilterCheck";
import { getDate } from "../../utils/formatDate";
import Unavailable from "@spectrum-icons/workflow/DataUnavailable";

export default function DefectsTable({ defectType }: DefectTableType) {
  const [defects, setDefects] = useState<Defect[]>([]);
  const [mySalesforceCases, setMySalesforceCases] = useState<
    SalesforceCaseItem[]
  >([]);
  const [myDynamicsCases, setMyDynamicsCases] = useState<DynamicsCaseItem[]>(
    [],
  );

  // Used to control re-rendering API Calls to Fusion/Hub
  const componentMounted = useRef(false);
  const getMyDefectsRef = useRef<() => void>(); // Ref for getMyDefects function

  //Grab user details
  const user = useSelector((state: RootState) => state.user.userDetails);

  //State management for filtering defects
  const [searchTerm, setSearchTerm] = useState(""); //Sets state for searching defect name
  const [filterDecision, setFilterDecision] = useState<string[]>([]);
  const [filteredDefects, setFilteredDefects] = useState<Defect[]>([]);
  const [filterStatus, setFilterStatus] = useState<string[]>([]);
  const [ticketNumbers, setTicketNumbers] = useState<Record<string, string>>(
    {},
  );

  const [loadingError, setLoadingError] = useState("");
  const [retryKey, setRetryKey] = useState<number>(0); // Track retries

  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    const getMyDefects = async (
      retryCount: number = 3,
      timeout: number = 30000,
    ) => {
      for (let attempt = 1; attempt <= retryCount; attempt++) {
        try {
          const formData = {
            action: defectType,
            user: user,
          };
          const webhookURL =
            "https://hook.app.workfrontfusion.com/p7i181yxax3tgo5842zb6ppde2p1wc2l";
          let controller = new AbortController();
          let fetchTimeout = setTimeout(() => controller.abort(), timeout);

          const request = await fetch(webhookURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-OKTA-Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
            signal: controller.signal,
          });

          clearTimeout(fetchTimeout);

          if (!request.ok) {
            throw new Error(`Request failed with status ${request.status}`);
          }

          attempt = 3;

          const response = await request.json();
          setDefects(response[0]);
          setMySalesforceCases(response[1]);
          setMyDynamicsCases(response[2]);
          setLoadingError(""); // Clear any previous errors

          return;
        } catch (err) {
          if (attempt === retryCount) {
            setLoadingError(
              "Failed to load defects after multiple attempts. Is Fusion down?",
            );
            console.error(err);
          }
        }
      }
    };

    getMyDefectsRef.current = getMyDefects; // Assign getMyDefects to the ref

    if (user && !componentMounted.current) {
      componentMounted.current = true;
      getMyDefects();
    }
    // Initialize filteredDefects with the same data as defects when component mounts
    setFilteredDefects(defects);
  }, [user, defects, retryKey, defectType]); // Added 'user' as a dependency to useEffect

  // Custom debounce function
  const debounce = <Args extends unknown[]>(
    func: (...args: Args) => void,
    delay: number,
  ) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: Args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Debounced filter function
  const debouncedFilterDefects = useMemo(
    () =>
      debounce((value: string, decisions: string[], statuses: string[]) => {
        const filtered = defects.filter((defect) => {
          const matchesName = defect.name
            .toLowerCase()
            .includes(value.toLowerCase());
          const matchesDecision =
            decisions.length === 0 ||
            decisions.includes(
              defect["DE:Will Fix / Won't Fix"] || "Pending Decision",
            );
          const matchesStatus =
            statuses.length === 0 ||
            statuses.includes(defect.statusLabel.toLowerCase());
          return matchesName && matchesDecision && matchesStatus;
        });
        setFilteredDefects(filtered);
      }, 800), // Adjust the delay as needed
    [defects],
  );

  // Function for handling the defect name search changes
  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
      debouncedFilterDefects(value, filterDecision, filterStatus);
    },
    [debouncedFilterDefects, filterDecision, filterStatus],
  );

  // Function for handling the search decision changes
  const handleSearchDecisionChange = useCallback(
    (value: string[]) => {
      setFilterDecision(value);
      debouncedFilterDefects(searchTerm, value, filterStatus);
    },
    [searchTerm, debouncedFilterDefects, filterStatus],
  );

  // Function for handling the search status changes
  const handleFilterStatusChange = useCallback(
    (statuses: string[]) => {
      setFilterStatus(statuses);
      debouncedFilterDefects(searchTerm, filterDecision, statuses);
    },
    [searchTerm, filterDecision, debouncedFilterDefects],
  );

  const handleTicketNumberChange = (defectID: string, newValue: string) => {
    setTicketNumbers((prev) => ({ ...prev, [defectID]: newValue }));
  };

  const handleLinkToDefect = async (defectID: string) => {
    const ticketNumber = ticketNumbers[defectID];
    const formData = {
      action: "linkToDefect",
      user: user,
      ticketNumber: ticketNumber,
      hubDefectID: defectID,
    };
    try {
      const response = await fetch(
        "https://hook.app.workfrontfusion.com/p7i181yxax3tgo5842zb6ppde2p1wc2l",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-OKTA-Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        },
      );

      switch (response.status) {
        case 200:
          // Handle toast queue for successful defect linking
          ToastQueue.positive("Successfully submitted!");
          handleTicketNumberChange(defectID, "");
          return "success";

        case 404:
          // Handle toast queue for when ticket number not found
          ToastQueue.negative(
            "Failed to submit data. Ticket number not found.",
          );
          console.error("Ticket number not found:", response.statusText);
          return "fail";
        case 400:
          // Handle toast queue for when ticket number is closed
          ToastQueue.negative("Failed to submit data. Ticket is closed.");
          console.error("Ticket is closed:", response.statusText);
          return "fail";
        default:
          // Handle errors, e.g., show an error message
          ToastQueue.negative("Failed to submit data. Please try again later.");
          console.error(
            "Failed to submit data to webhook:",
            response.statusText,
          );
          return "fail";
      }
    } catch (error) {
      console.error("Error submitting ticket number:", error);
    }
  };

  const handleFormSubmit = async (
    event: FormEvent<HTMLFormElement>,
    defectID: string,
    close: () => void,
  ) => {
    event.preventDefault(); // Prevent default form submission
    let response = await handleLinkToDefect(defectID);
    if (response === "success") {
      close();
    }
  };

  const handleRetry = () => {
    setLoadingError(""); // Clear any previous error message
    setRetryKey((prevKey) => prevKey + 1); // Increment the retryKey to force re-render and retry
    if (getMyDefectsRef.current) {
      getMyDefectsRef.current();
    }
  };

  return (
    <Flex direction="column" width="100%" height="size-5000" gap="size-250">
      {loadingError ? (
        <Flex direction="column" width="100%" alignItems="center">
          <IllustratedMessage>
            <Unavailable />
            <Heading>Loading Error</Heading>
            <Content>{loadingError}</Content>
            <Button variant="cta" onPress={handleRetry} marginTop="10px">
              Retry
            </Button>
          </IllustratedMessage>
        </Flex>
      ) : defects.length === 0 ? (
        <Flex direction="column" width="100%" alignItems="center">
          <ProgressCircle
            isIndeterminate
            aria-label="Loading Progress Circle"
          />
        </Flex>
      ) : (
        <>
          <TableView
            aria-label="My Defects"
            renderEmptyState={renderEmptyState}
            height={filteredDefects.length === 0 ? "size-3000" : "auto"}
          >
            <TableHeader>
              <Column width="10%">Case #</Column>
              <Column width="40%">
                <Flex direction="row" gap="size-50">
                  Title
                  <DialogTrigger type="popover" placement="right">
                    <ActionButton
                      isQuiet
                      UNSAFE_style={{ width: "15px", height: "15px" }}
                      aria-label="Search Defect Name Filter Button"
                    >
                      {searchTerm === "" ? <Filter /> : <FilterCheck />}
                    </ActionButton>
                    <Dialog size={"S"}>
                      <Content>
                        <Flex direction={"column"} gap={"size-250"}>
                          <SearchField
                            aria-label="Search Defect name"
                            width="100%"
                            description="Search My Defects (Optional)"
                            onChange={(value: string) =>
                              handleSearchChange(value)
                            }
                            value={searchTerm}
                            autoFocus
                          />
                          <Button
                            variant="secondary"
                            aria-label="Clear Title Search"
                            onPress={() => {
                              setSearchTerm("");
                              debouncedFilterDefects(
                                "",
                                filterDecision,
                                filterStatus,
                              );
                            }}
                            alignSelf={"center"}
                          >
                            Clear
                          </Button>
                        </Flex>
                      </Content>
                    </Dialog>
                  </DialogTrigger>
                </Flex>
              </Column>
              <Column width="20%">
                {" "}
                <Flex direction={"row"} gap="size-50">
                  Decision
                  <DialogTrigger type="popover" placement="bottom right">
                    <ActionButton
                      isQuiet
                      UNSAFE_style={{ width: "15px", height: "15px" }}
                      aria-label="Defect Decision Filter Button"
                    >
                      {filterDecision.length === 0 ? (
                        <Filter />
                      ) : (
                        <FilterCheck />
                      )}
                    </ActionButton>
                    <Dialog size={"S"}>
                      <Content>
                        <Flex direction={"column"} gap={"size-250"}>
                          <CheckboxGroup
                            label="Defect Decisions"
                            name="decisions"
                            value={filterDecision}
                            onChange={handleSearchDecisionChange}
                          >
                            {renderDefectDecision(defects)}
                          </CheckboxGroup>

                          <Button
                            variant="secondary"
                            onPress={() => {
                              setFilterDecision([]);
                              debouncedFilterDefects(
                                searchTerm,
                                [],
                                filterStatus,
                              );
                            }}
                            alignSelf={"center"}
                          >
                            Clear
                          </Button>
                        </Flex>
                      </Content>
                    </Dialog>
                  </DialogTrigger>
                </Flex>
              </Column>
              <Column width="10%">
                <Flex direction={"row"} gap="size-50">
                  Status
                  <DialogTrigger type="popover" placement="bottom right">
                    <ActionButton
                      isQuiet
                      UNSAFE_style={{ width: "15px", height: "15px" }}
                      aria-label="Defect Status Filter Button"
                    >
                      {filterStatus.length === 0 ? <Filter /> : <FilterCheck />}
                    </ActionButton>
                    <Dialog size={"S"}>
                      <Content>
                        <Flex direction={"column"} gap={"size-250"}>
                          <CheckboxGroup
                            label="Defect Statuses"
                            name="statuses"
                            value={filterStatus}
                            onChange={handleFilterStatusChange}
                          >
                            {renderDefectStatus(defects)}
                          </CheckboxGroup>

                          <Button
                            variant="secondary"
                            onPress={() => {
                              setFilterStatus([]);
                              debouncedFilterDefects(
                                searchTerm,
                                filterDecision,
                                [],
                              );
                            }}
                            alignSelf={"center"}
                          >
                            Clear
                          </Button>
                        </Flex>
                      </Content>
                    </Dialog>
                  </DialogTrigger>
                </Flex>
              </Column>
              <Column width="10%">Defect Age</Column>
              <Column width="10%">Actions</Column>
            </TableHeader>
            <TableBody>
              {renderTableRows(
                filteredDefects,
                mySalesforceCases,
                myDynamicsCases,
                ticketNumbers,
                handleTicketNumberChange,
                handleFormSubmit,
              )}
            </TableBody>
          </TableView>
        </>
      )}
    </Flex>
  );
}

//Function for filtering the status of defects
function renderDefectStatus(defects: Defect[]): React.JSX.Element[] {
  const defectStatus = defects.map((item) => {
    return item.statusLabel;
  });

  const filteredStatus = defectStatus.filter((n, i) => {
    return defectStatus.indexOf(n) === i;
  });

  return filteredStatus.map((item) => {
    return (
      <Checkbox key={item} value={item.toLowerCase()}>
        {item}
      </Checkbox>
    );
  });
}

//Function for filtering the decision of defects
function renderDefectDecision(defects: Defect[]): React.JSX.Element[] {
  const defectDecision = defects.map((item) => {
    return item["DE:Will Fix / Won't Fix"];
  });

  const defectFilteredDecision = defectDecision.filter((n, i) => {
    return defectDecision.indexOf(n) === i;
  });

  return defectFilteredDecision.map((item) => {
    if (item === null) {
      return (
        <Checkbox key="Pending Decision" value={"Pending Decision"}>
          Pending Decision
        </Checkbox>
      );
    } else {
      return (
        <Checkbox key={item} value={item}>
          {item}
        </Checkbox>
      );
    }
  });
}

function renderTableRows(
  defects: Defect[],
  mySalesforceCases: SalesforceCaseItem[],
  myDynamicsCases: DynamicsCaseItem[],
  ticketNumbers: Record<string, string>,
  handleTicketNumberChange: (defectID: string, newValue: string) => void,
  handleFormSubmit: (
    event: FormEvent<HTMLFormElement>,
    defectID: string,
    close: () => void,
  ) => void,
): JSX.Element[] {
  return defects.map((item) => {
    // Split the comma-separated list of Zendesk IDs into an array
    let ticketIDs = item["DE:Zendesk IDs"].split(",");

    // Collect matching Cases for the current defect item
    const matchingSalesforceCases = mySalesforceCases.filter((caseItem) =>
      ticketIDs.includes(caseItem.CaseNumber),
    );

    const matchingDynamicsCases = myDynamicsCases.filter((caseItem) =>
      ticketIDs.includes(caseItem.ticketnumber),
    );

    // Render each matching case as an Item component
    const renderSalesforceCaseItems = matchingSalesforceCases.map(
      (caseItem, index) => (
        <Item
          key={`Salesforce-${index}`}
          href={`https://workfront.lightning.force.com/lightning/r/Case/${caseItem.Id}/view`}
          target="_blank"
          aria-label={`Case Number: ${caseItem.CaseNumber}, Status: ${caseItem.Status}`}
        >
          <Avatar
            UNSAFE_style={{
              backgroundColor: "rgb(0, 161, 224)",
              padding: "3px",
            }}
            src={require("./img/salesforce.png")}
          />
          <Text>
            {caseItem.CaseNumber} - {caseItem.Status}
          </Text>
        </Item>
      ),
    );

    // Render each matching case as an Item component
    const renderDynamicsCaseItems = matchingDynamicsCases.map(
      (caseItem, index) => {
        let status = caseItem.statecode !== 0 ? "Closed" : "Open";
        return (
          <Item
            key={`Dynamics-${index}`}
            href={caseItem.ent_recordurl}
            target="_blank"
            aria-label={`Case Number: ${caseItem.ticketnumber}, Status: ${status}`}
          >
            <Avatar
              UNSAFE_style={{
                backgroundColor: "rgb(20, 32, 78)",
                padding: "3px",
              }}
              src={require("./img/dynamics.png")}
            />
            <Text>
              {caseItem.ticketnumber} - {status}
            </Text>
          </Item>
        );
      },
    );

    let combinedItems = [
      ...renderSalesforceCaseItems,
      ...renderDynamicsCaseItems,
    ];

    // Render the Picker with the rendered case items
    const renderPicker = (
      <Picker
        isQuiet
        placeholder="View Ticket"
        aria-label="View Ticket Picker"
        direction="top"
      >
        {combinedItems}
      </Picker>
    );

    // Render the Link for the defect
    const renderLink = (
      <Link
        href={`https://experience.adobe.com/#/@adobeinternalworkfront/so:hub-Hub/workfront/issue/view?ID=${item.ID}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Defect Name: ${item.name}`}
      >
        {item.name}
      </Link>
    );

    // Determine the deadline text based on "DE:Will Fix / Won't Fix" property
    let renderDeadline;
    switch (item["DE:Will Fix / Won't Fix"]) {
      case "Will Fix":
        const resolutionDeadline = getDate(item["DE:Resolution Deadline"]);
        renderDeadline = `Will Fix by ${resolutionDeadline}`;
        break;
      case "Won't Fix":
        renderDeadline = "Won't Fix";
        break;
      default:
        const responseDeadline = getDate(item["DE:Response Deadline"]);
        renderDeadline = `Pending Decision by ${responseDeadline}`;
    }

    // Render the defect row with all components
    return (
      <Row key={item.ID}>
        <Cell>{renderPicker}</Cell>
        <Cell>{renderLink}</Cell>
        <Cell>{renderDeadline}</Cell>
        <Cell>{item.statusLabel}</Cell>
        <Cell>{`${item["DE:Internal Open Age"]} days`}</Cell>
        <Cell>
          <Flex>
            <DialogTrigger>
              <ActionButton>Link to Defect</ActionButton>
              {(close) => (
                <Dialog>
                  <Heading justifySelf="center">Link to Defect</Heading>
                  <Content>
                    <Divider
                      size="M"
                      marginTop="size-250"
                      marginBottom="size-250"
                    />
                    <Flex
                      marginTop={"size-250"}
                      marginStart={"size-500"}
                      marginEnd={"size-500"}
                    >
                      <Text>Defect Name: {item.name}</Text>
                    </Flex>
                    <Form
                      onSubmit={(event) =>
                        handleFormSubmit(event, item.ID, close)
                      }
                      validationBehavior="native"
                      marginTop={"size-250"}
                      marginStart={"size-500"}
                      marginEnd={"size-500"}
                    >
                      <TextField
                        label="Ticket Number"
                        name="Ticket Number"
                        value={ticketNumbers[item.ID] || ""}
                        onChange={(newValue) =>
                          handleTicketNumberChange(item.ID, newValue)
                        }
                        type="text"
                        isRequired
                      />
                      <Divider
                        size="M"
                        marginTop="size-500"
                        marginBottom="size-250"
                      />
                      <Flex justifyContent="end">
                        <ButtonGroup>
                          <Button
                            type="reset"
                            variant="secondary"
                            onPress={close}
                          >
                            Cancel
                          </Button>

                          <Button type="submit" variant="accent">
                            Link To Defect
                          </Button>
                        </ButtonGroup>
                      </Flex>
                    </Form>
                  </Content>
                </Dialog>
              )}
            </DialogTrigger>
          </Flex>
        </Cell>
      </Row>
    );
  });
}

//Renders empty state of table when no results are found
function renderEmptyState() {
  return (
    <IllustratedMessage>
      <Heading>No results</Heading>
      <Content>No results found</Content>
    </IllustratedMessage>
  );
}
