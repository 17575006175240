/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Flex, View, Text } from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { TicketInfoBannerElement } from "./TicketInfoBannerElement";
import { CaseInfo } from "../../types/case";
import { DSATCell } from "./DSATCell";
import SolutionStatusLogician from "./SolutionStatus";
import OrgInfo from "../common/OrgInfo";
import CaseNumber from "../common/CaseNumber";

const HelpText: Partial<Record<string, JSX.Element>> = {
  "Customer DSAT": (
    <>
      Aim to maintain Historical DSAT below 30%. The Predictive DSAT score is
      the probability that the customer interaction will result in a DSAT
      survey. This value is updated daily at 8:30 a.m. PT.
    </>
  ),
};

const TicketInfoBanner: React.FC = () => {
  const dynamicsCaseId = useSelector((state: RootState) => state.case.ticketId);
  const caseMeta = useSelector((state: RootState) => state.case.meta);
  const orgName = useSelector(
    (state: RootState) => state.case.caseObject?.orgName,
  );
  const orgCode = useSelector(
    (state: RootState) => state.case.caseObject?.orgId,
  );
  const orgInternalNotes = useSelector(
    (state: RootState) => state.case.caseObject?.internalNotes,
  );
  const family =
    useSelector(
      (state: RootState) => state.case.caseObject?.productFamilyName,
    ) ?? ":(";
  const reasons = [
    useSelector((state: RootState) => state.case.caseObject?.issueReasonText),
    useSelector(
      (state: RootState) => state.case.caseObject?.secondaryIssueReasonText,
    ),
  ]
    .filter(Boolean)
    .join("\n");

  const ticketInfo: CaseInfo[] = [
    {
      title: "Case Number",
      value: (
        <CaseNumber
          dynamicsCaseId={dynamicsCaseId}
          ticketId={caseMeta.ticket_id}
        />
      ),
      size: "1 1 3%",
    },
    {
      title: "Solution",
      value: (
        <Flex direction="column">
          <Text>{family}</Text>
          <SolutionStatusLogician />
        </Flex>
      ),
      size: "1 1 0%",
    },
    {
      title: "Org ID",
      value: (
        <OrgInfo
          orgId={caseMeta.org_id}
          orgName={orgName}
          orgInternalNotes={orgInternalNotes}
          orgCode={orgCode}
        />
      ),
      size: "1 1 0%",
      wideElementClass: "ticket-info__org-id",
    },
    {
      title: "Org Name",
      value: (
        <Flex alignItems="end">
          <Text>{orgName ?? "Name Unavailable"}</Text>
        </Flex>
      ),
      size: "1 1 0%",
      wideElementClass: "ticket-info__org-id",
    },
    {
      title: "Contact Reason",
      value: reasons || "No reason provided",
      size: "1 1 0%",
      wideElementClass: "ticket-info__org-id",
    },
    {
      title: "Customer DSAT",
      value: DSATCell(family, dynamicsCaseId),
      size: "2 1 0%",
      wideElementClass: "ticket-info__org-id",
    },
  ];

  return (
    <View
      UNSAFE_className="ticket-info"
      backgroundColor="gray-50"
      borderRadius="regular"
      paddingX="size-100"
      paddingY="size-150"
    >
      {ticketInfo.map((info) => (
        <TicketInfoBannerElement
          title={info.title}
          value={info.value}
          help={HelpText[info.title as keyof CaseInfo]}
          size={info.size ?? "1 1 0%"}
          wideElementClass={info.wideElementClass}
          analyticCaseFieldClass={info.analyticCaseFieldClass}
        />
      ))}
    </View>
  );
};

export default TicketInfoBanner;
