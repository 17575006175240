/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect, useState } from "react";
import { ProgressCircle, SearchField } from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { useDispatch } from "react-redux";
import Search from "@spectrum-icons/workflow/Search";

import { useGetTicketListMutation } from "../../services/supportInsights";
import { setSearchText, setCaseList } from "../../store/case/caseSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { get, trim } from "lodash";

const defaultValue = {
  data: [],
  meta: {
    tickets_count: 0,
    publicMessage: "",
    paging: {
      current_page: 1,
      has_more_pages: false,
    },
  },
};
const SearchBar: React.FC = () => {
  const [caseNumberOrIMSOrgID, setCaseNumberOrIMSOrgID] = useState("");
  const [errorPublic, setErrorPublic] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [caseList, { isLoading: caseIsLoading }] = useGetTicketListMutation();

  useEffect(() => {
    if (caseNumberOrIMSOrgID !== undefined && caseNumberOrIMSOrgID !== "") {
      caseList({ query: caseNumberOrIMSOrgID, sortOrder: {updated_at: "descending"}})
        .unwrap()
        .then((response) => {
          const errorMessage = get(response, "meta.publicMessage", "");
          if (errorMessage !== "") {
            setErrorPublic(errorMessage);
            dispatch(setSearchText(caseNumberOrIMSOrgID));
            dispatch(setCaseList({ ...defaultValue }));
            ToastQueue.negative(errorMessage, { timeout: 5000 });
          } else {
            dispatch(setSearchText(caseNumberOrIMSOrgID));
            dispatch(setCaseList({ ...response }));
            if (pathname !== "/") {
              navigate("/");
            }
          }
        })
        .catch((error: any) => {
          ToastQueue.negative(
            "Sorry for the inconvenience. We are facing Data Retrieval error Please visit after sometime.",
            { timeout: 5000 },
          );
        });
    }
  }, [caseNumberOrIMSOrgID]);

  const handleSearch = (searchQuery: string) => {
    if (trim(searchQuery) === "") {
      ToastQueue.negative(
        "Please enter valid input to get the search results",
        {
          timeout: 5000,
        },
      );
      return;
    }
    if (searchQuery.length < 3) {
      ToastQueue.negative("The query field must be at least 3 characters.", {
        timeout: 5000,
      });
      return;
    }
    if (searchQuery !== caseNumberOrIMSOrgID) {
      setErrorPublic("");
      dispatch(setSearchText(""));
      setCaseNumberOrIMSOrgID(searchQuery);
      return;
    }

    if (errorPublic !== "") {
      ToastQueue.negative(errorPublic, { timeout: 5000 });
    }
  };
  return (
    <SearchField
      onSubmit={handleSearch}
      defaultValue=""
      width={624}
      icon={
        caseIsLoading ? (
          <ProgressCircle
            aria-label="Loading…"
            staticColor="black"
            isIndeterminate
            width={16}
            height={16}
          />
        ) : (
          <Search />
        )
      }
      placeholder="Search for a Case Number or IMS Org Id or Customer Name"
    />
  );
};

export default SearchBar;
