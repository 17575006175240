/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { IMenuItem } from "../../types/ISideNavbar";
import AugmentedAnalysisPage from "../../pages/AugmentedAnalysisPage";
import AiAssistantPage from "../../pages/AiAssistant";

// Do not use "All Solutions" unless the page you are building is of use for all Dx Products, for everything else use the appropriate product.
// See <https://wiki.corp.adobe.com/x/NLK_uQ>
const AllSolutionsMenuData: IMenuItem[] = [
  {
    Solution: "All Solutions",
    LinkName: "AI Assistant",
    LinkURL: `/review/:caseInfoSuffix`,
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "Internal",
    ParameterType: "",
    component: <AiAssistantPage />,
  },
  {
    Solution: "All Solutions",
    LinkName: "Augmented Analysis",
    LinkURL: `/augmented-analysis/:caseInfoSuffix`,
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "Internal",
    ParameterType: "",
    component: <AugmentedAnalysisPage />,
  },
  {
    Solution: "All Solutions",
    LinkName: "C14E Text Resize Chrome Extensions",
    LinkURL:
      "https://wiki.corp.adobe.com/display/MKTOCS/C14E+Text+Resize+Chrome+Extension",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "All Solutions",
    LinkName: "Executive Summary Generator",
    LinkURL: "https://adobe-csm-assistant.azurewebsites.net/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
];

export default AllSolutionsMenuData;
