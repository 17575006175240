/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Flex } from "@adobe/react-spectrum";
import ContributionComponent from "../common/ContributionComponent";
import TempAdmin from "./tempAdmin/TempAdmin";
import TicketInfoBanner from "../augmentedAnalysis/TicketInfoBanner";

const CreateAdmin: React.FC = () => {
  return (
    <Flex direction="column" gap="size-400">
      {/* <TicketInfoBanner /> */}
      <ContributionComponent
        title={"Create Temporary Admin"}
        developerEmail={"poltorat@adobe.com, atw24003@adobe.com"}
      >
        <Flex direction="row" gap="size-500">
          <TempAdmin />
        </Flex>
      </ContributionComponent>
    </Flex>
  );
};

export default CreateAdmin;
