/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { OptionsMenu } from "../components/common/DropDownFilter";
import { CaseObject } from "../types/case";

export interface SearchTableColumns {
  name: string;
  uid: string;
  width: number | null;
  filter: boolean;
  sortBy: boolean;
  filterList: OptionsMenu[];
}

export interface SearchResult {
  caseItems: CaseObject[];
  errorMessage: string;
  searchValue: string;
  totalItem: number;
}
export interface SearchResultTableProps {
  searchText?: string;
}
export type FilterCase = { [key: string | number]: string[] };
export type SortOrder = { [key: string | number]: string };

export interface SearchRequest {
  query: string;
  filter?: FilterCase;
  sortOrder?: SortOrder;
  page?: number;
}

export const COLUMNS_SEARCH_TABLE = [
  {
    name: "Case Number",
    uid: "caseId",
    width: "1fr",
    filter: false,
    sortBy: true,
    filterList: [],
  },
  {
    name: "Solution",
    uid: "productFamilyName",
    width: "1fr",
    filter: true,
    sortBy: true,
    filterList: [],
  },
  {
    name: "IMS Org ID",
    uid: "orgId",
    width: "2fr",
    filter: true,
    sortBy: true,
    filterList: [],
  },
  {
    name: "Org Name",
    uid: "orgName",
    width: "2fr",
    filter: true,
    sortBy: true,
    filterList: [],
  },
  {
    name: "Status",
    uid: "status",
    width: "1fr",
    filter: false,
    sortBy: true,
    filterList: [],
  },
  {
    name: "Case Title",
    uid: "caseTitle",
    width: "2fr",
    filter: false,
    sortBy: false,
    filterList: [],
  },
  {
    name: "Updated At",
    uid: "updated_at",
    width: "2fr",
    filter: false,
    sortBy: false,
    filterList: [],
  },
];
export const SEARCH_PER_PAGE = 20;

export const defaultSearchResultValue = {
  caseItems: [],
  errorMessage: "",
  searchValue: "",
  totalItem: 0,
};
