/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

export function convertToLocalTimeDate(date: string): string {
  if (date === "") return "";
  const formatter = new Intl.DateTimeFormat(["en"], {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    // timeZone: "UTC",
  });
  return formatter.format(new Date(convertToISODateUTCTimeZone(date)));
}

export function convertToISODateUTCTimeZone(date: string): string {
  return date.replace(" ", "T") + "Z";
}

export function getDate(inputString: string) {
  const date = new Date(inputString);
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${month}/${day}/${year}`;
}
