/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  TextArea,
  View,
} from "@adobe/react-spectrum";
import Draw from "@spectrum-icons/workflow/Draw";

interface AiQuestionProps {
  prompt: string;
  askQuestion: (question: string) => void;
  editable: boolean;
  isHidden?: boolean;
}

const AiQuestion: React.FC<React.PropsWithChildren<AiQuestionProps>> = ({
  prompt,
  askQuestion,
  editable = true,
  children,
  isHidden = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [changedPrompt, setChangedPrompt] = useState(prompt);

  return (
    <Flex
      isHidden={isHidden}
      direction="row-reverse"
      justifyContent="start"
      gap="size-100"
    >
      <View
        backgroundColor="static-gray-200"
        padding="size-100"
        borderRadius="medium"
        width={isEditing ? "100%" : "auto"}
        UNSAFE_style={{ whiteSpace: "preserve" }}
      >
        {!isEditing ? (
          children
        ) : (
          <Flex direction="column" gap="size-100">
            <TextArea
              value={changedPrompt}
              onChange={setChangedPrompt}
              onKeyDown={(e) => {
                if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
                  askQuestion(changedPrompt);
                  setChangedPrompt("");
                  setIsEditing(false);
                }
              }}
              width="100%"
            />
            <ButtonGroup align="end">
              <Button variant="secondary" onPress={() => setIsEditing(false)}>
                Cancel
              </Button>
              <Button
                UNSAFE_className="analytics-track-me"
                data-analytics-link-name="Summary. Custom promt"
                data-analytics-view-name="AI Summary"
                variant="primary"
                onPress={() => {
                  askQuestion(changedPrompt);
                  setIsEditing(false);
                }}
              >
                Submit
              </Button>
            </ButtonGroup>
          </Flex>
        )}
      </View>
      {!isEditing && (
        <Button
          UNSAFE_className={`analytics-generate-ai-summary-button analytics-generate-ai-summary-button__edit-prompt`}
          onPress={() => {
            setIsEditing(true);
            setChangedPrompt(prompt);
          }}
          variant="primary"
          isDisabled={!editable}
        >
          <Draw />
        </Button>
      )}
    </Flex>
  );
};

export default AiQuestion;
