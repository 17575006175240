/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Flex, Text, View } from "@adobe/react-spectrum";
import Info from "@spectrum-icons/workflow/Info";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { formatDate } from "../../utils/formatDate";

const LastAnalysisBar: React.FC = () => {
  const lastAnalysisDate = useSelector(
    (state: RootState) => state.lastAnalysis.lastAnalysisDate,
  );
  const formattedDate = formatDate(lastAnalysisDate);

  return (
    <View paddingY="size-150" flex>
      <Flex alignItems="center" gap="size-250">
        <Flex alignItems="center" gap="size-50">
          <Info size="S" color="informative" aria-label="Info Icon" />
          <Text marginTop="5px">{`Last Analysis Done On: ${formattedDate}`}</Text>
        </Flex>
      </Flex>
    </View>
  );
};

export default LastAnalysisBar;
