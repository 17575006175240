/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useState } from "react";
import { ComboBox, Item, Button } from "@adobe/react-spectrum";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import CrossSmall from "@spectrum-icons/ui/CrossSmall";

interface FilterOption {
  label: string;
  key: string;
  options: string[];
}

interface CommonFilterProps {
  filtersConfig: FilterOption[];
  onFilterChange: (filters: Record<string, string | null>) => void;
}

const CommonFilter: React.FC<CommonFilterProps> = ({
  filtersConfig,
  onFilterChange,
}) => {
  const [filters, setFilters] = useState<Record<string, string | null>>(() =>
    filtersConfig.reduce((acc, config) => ({ ...acc, [config.key]: null }), {}),
  );

  const handleFilterChange = (key: string, value: string | null) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const clearFilter = (key: string) => {
    handleFilterChange(key, null);
  };

  const clearAllFilters = () => {
    const clearedFilters = filtersConfig.reduce(
      (acc, config) => ({ ...acc, [config.key]: null }),
      {},
    );
    setFilters(clearedFilters);
    onFilterChange(clearedFilters);
  };

  const selectedFilterCount = Object.values(filters).filter(Boolean).length;

  return (
    <div>
      <div className="filter-container">
        <p className="filter-label">FILTER APPLIED ON:</p>
        {filtersConfig.map((config) => (
          <ComboBox
            key={config.key}
            placeholder={config.label}
            defaultItems={config.options.map((option) => ({
              id: option,
              name: option,
            }))}
            selectedKey={filters[config.key]}
            onSelectionChange={(key) =>
              handleFilterChange(config.key, key as string)
            }
            UNSAFE_className="marginRight"
          >
            {(item) => <Item key={item.id}>{item.name}</Item>}
          </ComboBox>
        ))}
      </div>

      <div className="filter-list-container">
        {Object.entries(filters).map(([key, value]) =>
          value ? (
            <div key={key} className="selected-filter-container">
              <CheckmarkCircle UNSAFE_className="filter-checkmark" />
              <span className="selected-filter">{value}</span>
              <Button
                variant="secondary"
                aria-label={`Clear ${key} Filter`}
                UNSAFE_style={{
                  padding: 0,
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#0265DC",
                }}
                onPress={() => clearFilter(key)}
              >
                <CrossSmall />
              </Button>
            </div>
          ) : null,
        )}
        {selectedFilterCount > 1 && (
          <Button
            variant="secondary"
            aria-label="Clear All Filters"
            UNSAFE_style={{
              padding: "0px",
              margin: "0px",
              backgroundColor: "transparent",
              color: "#0265DC",
              border: "none",
              fontSize: "12px",
              verticalAlign: "middle",
            }}
            onPress={clearAllFilters}
          >
            Clear All
          </Button>
        )}
      </div>
    </div>
  );
};
export default CommonFilter;
