/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { IMenuItem } from "../../types/ISideNavbar";

const AdobeCampaignMenuData: IMenuItem[] = [
  {
    Solution: "Adobe Campaign",
    LinkName: "Control Panel",
    LinkURL: "https://experience.adobe.com/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Control Panel allows Adobe Campaign administrators to monitor key assets and perform administrative tasks, such as managing the SFTP storage by instance, managing GPG keys, or subdomains and certificates.",
    PointOfContact: "PM : Madhupriya Gupta",
    DocumentationLink:
      "https://experienceleague.adobe.com/en/docs/control-panel-learn/tutorials/control-panel-overview",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Campaign",
    LinkName: "UCO",
    LinkURL: "https://uco.adobe-campaign.com/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      '"The primary goal of ""UCO"" is to unify the experience of using various operations oriented tools developed on top of Campaign\'s sprawling infrastructure. Therefore, we\'re introducing an abstraction layer in-front of the various tools and executors that are currently in use (or will be added in the future). We want to take the various tools from being an SRE convenience to a properly audited and RBAC\'d system with a common interface. ACI (Adobe Campaign Inventory) is the source of Campaign\'s inventory (represented as CMDB in the UI) and UCO provides a unified interface over the various (existing/future) utility tools."',
    PointOfContact: "Engg: Tarun Kumar Jaiswal",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/neolane/ACC+New+Relic+monitoring",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Campaign",
    LinkName: "New Relic",
    LinkURL:
      "https://one.newrelic.com/dashboards/detail/MTIwOTMyN3xWSVp8REFTSEJPQVJEfGRhOjM0MTM2?account=1209327&state=50ff829a-c56b-d9dd-d9c1-efb96fc82cbf",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "New Relic is an internal monitoring tool available on AC instances running on Debian 9 and above",
    PointOfContact: "AMSE: Peter S",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/neolane/Read-only+access+to+CC+on+Production+AWS+Account",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Campaign",
    ImpType: "Hosted/Hybrid",
    LinkName: "AWS",
    LinkURL: "https://klam.corp.adobe.com/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "AWS read-only cloud access is available for customer support team to be able to check system utilization and EC2/RDS hardware allocation",
    PointOfContact: "Tech-Ops",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/neolane/Read-only+access+to+CC+on+Production+AWS+Account",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Campaign",
    LinkName: "Ladybug/Core dump analyzer",
    LinkURL: "https://toolbox.rd.campaign.adobe.com/auth/signin",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Ladybug is our AI-driven initiative focused on enhancing product issue resolution within Jira, starting with Adobe Campaign. This tool aims to bolster the capabilities of Customer Care and engineering teams by efficiently analyzing Jira tickets, identifying duplicates, and speeding up ticket closures.",
    PointOfContact: "Engg: Camille",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/neolane/Introducing+LADYBUG%2C+your+AI+Assistant+for+Product+Issue+Management",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Campaign",
    LinkName: "Columbus",
    LinkURL: "https://columbus.corp.adobe.com/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Campaign",
    LinkName: "Splunk",
    LinkURL: "https://splunk.or1.adobe.net/en-US/app/TA-campaign_ui/search",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Campaign",
    LinkName: "ABO / Login-As",
    LinkURL:
      "https://abo.services.adobe.com/customers/search?query={requestor_email}",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Campaign",
    LinkName: "Panorama",
    LinkURL: "https://pmg.corp.adobe.com/PanoramaECJ",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Campaign",
    LinkName: "Cyberark",
    LinkURL: "https://cyberark.corp.adobe.com/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Campaign",
    LinkName: "SKMS",
    LinkURL: "https://skms.adobe.com/tools.rt_portal.request/create",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
];

export default AdobeCampaignMenuData;
