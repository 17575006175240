/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { DataPoint } from "../types/datapoints";

/**
 * Finds the earliest datapoint based on the `created_at` property.
 * @param datapoints - An array of datapoint objects with a `created_at` property.
 * @returns The `created_at` value of the earliest datapoint, or null if the array is empty.
 */
export function latestDataPointDate(datapoints: DataPoint[]): string | null {
  if (datapoints.length === 0) {
    return null;
  }

  let lastDataPoint = datapoints[0];

  for (let i = 1; i < datapoints.length; i++) {
    const currentDataPoint = datapoints[i];
    if (currentDataPoint.created_at > lastDataPoint.created_at) {
      lastDataPoint = currentDataPoint;
    }
  }

  return lastDataPoint.created_at;
}
