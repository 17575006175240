/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import {
  Button,
  Flex,
  TextArea,
  Text,
  Heading,
  ContextualHelp,
  Content,
  TextField,
  LabeledValue,
} from "@adobe/react-spectrum";
import MagicWand from "@spectrum-icons/workflow/MagicWand";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import React from "react";
import CfsSystemPrompt from "./CfsSystemPrompt";
import CfsProductPicker from "./CfsProductPicker";
import CfsTickets from "./CfsTickets";
import { useGenerateCfs } from "../../../hooks/cfsTool/useGenerateCfs";
import { setAdditionalContext } from "../../../store/cfs/cfsSlice";
import { useDispatch } from "react-redux";

export interface CfsGeneratorProps {}

const CfsGenerator: React.FC<CfsGeneratorProps> = ({}) => {
  const dispatch = useDispatch();
  const [cfsState, generateCfs] = useGenerateCfs();

  const handleUpdateAdditionalContext = (ctx: string): void => {
    dispatch(setAdditionalContext(ctx));
  };

  const additionalContext = cfsState.additionalContext;

  const hasResponse = cfsState.aiSummary?.text !== "";
  const canSubmit =
    cfsState.aiSummary?.isLoading ||
    cfsState.jiraTickets.length == 0 ||
    !cfsState.product;

  return (
    <>
      <Flex direction="row" gap="size-100" wrap>
        <CfsProductPicker width="size-3000" label="Choose the Product" />
        <CfsTickets width="size-3000" label="Add One or more Jira Tickets" />
        <CfsSystemPrompt
          width="size-3000"
          title="System Prompt"
          label="Edit the CFS System Prompt"
        />
        <TextField
          label="Add Additional Context"
          contextualHelp={
            <ContextualHelp variant="info">
              <Heading>Additional Context</Heading>
              <Content>
                <p>
                  You may include additional information that might not be
                  available in the Jira ticket data, but could be useful for the
                  AI to know before generating a response.
                </p>
                <p>Examples of such information include:</p>
                <ul>
                  <li>"The error was caused by a Feature Flag change."</li>
                  <li>
                    "Customers were also experiencing issues with their login
                    page."
                  </li>
                </ul>
              </Content>
            </ContextualHelp>
          }
          value={additionalContext}
          onChange={handleUpdateAdditionalContext}
        />
      </Flex>
      <Flex direction="row" marginTop={15}>
        <Button
          variant="cta"
          UNSAFE_className="analytics-track-me"
          data-analytics-link-name="CFS Summary. Custom prompt"
          data-analytics-view-name="AI CFS Summary"
          onPress={generateCfs}
          isDisabled={canSubmit}
        >
          <Text>{hasResponse ? "Reg" : "G"}enerate</Text>
          <MagicWand />
        </Button>
      </Flex>
    </>
  );
};

export default CfsGenerator;
