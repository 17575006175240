/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { micromark } from "micromark";
import { gfm, gfmHtml } from "micromark-extension-gfm";
import DOMPurify from "dompurify";

/**
 * Parses Markdown and sanitizes the resulting HTML string.
 *
 * @param markdown - The Markdown string to parse and sanitize.
 * @returns The sanitized HTML string.
 */
export function parseAndSanitizeMarkdown(markdown: string): string {
  const html = micromark(markdown, "utf-8", {
    extensions: [gfm()],
    htmlExtensions: [gfmHtml()],
  });
  return DOMPurify.sanitize(html);
}
