/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { IMenuItem } from "../../types/ISideNavbar";
import WorkfrontDefectDashboardPage from "../../pages/WorkfrontDefectDashboardPage";
import P2EAutomationPage from "../../pages/WorkfrontP2EAutomationPage";

const WorkfrontMenuData: IMenuItem[] = [
  {
    Solution: "Workfront",
    LinkName: "Workfront Automation",
    LinkURL: "/workfront",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Workfront Automation",
    PointOfContact: "grimaud@adobe.com, nfinsand@adobe.com",
    DocumentationLink: "",
    LinkType: "Internal",
    ParameterType: "",
    component: <WorkfrontDefectDashboardPage />,
  },
  {
    Solution: "Workfront",
    LinkName: "Create Defect",
    LinkURL: "/workfront/create-defect",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Allows T3 to create defects to Engineering.",
    PointOfContact: "nfinsand@adobe.com, grimaud@adobe.com",
    DocumentationLink: "",
    LinkType: "Internal",
    ParameterType: "",
    component: <P2EAutomationPage />,
  },
  {
    Solution: "Workfront",
    LinkName: "Support Login Tool",
    LinkURL: "https://login.workfront.com/ui/#/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Allows internal teams to login to Workfront accounts of the customers.",
    PointOfContact: "Joshua Bloom",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Workfront",
    LinkName: "Blocklist Remover",
    LinkURL: "https://blocklist-remover.prod.us-west-2.aws.wfk8s.com/api/v1",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Allows internal teams to unblock customer email addresses",
    PointOfContact: "Saeid Zoonemat Kermani",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Workfront",
    LinkName: "Splunk",
    LinkURL: "https://splunk.or1.adobe.net/en-US/app/launcher/home",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Workfront",
    LinkName: "Columbus",
    LinkURL: "https://columbus.corp.adobe.com/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Workfront",
    LinkName: "ABO / Login-As",
    LinkURL:
      "https://abo.services.adobe.com/customers/search?query={requestor_email}",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Workfront",
    LinkName: "Panorama",
    LinkURL: "https://pmg.corp.adobe.com/PanoramaECJ",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
];

export default WorkfrontMenuData;
