/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import UserBanner from "./UserBanner";
import AdminBanner from "./AdminBanner";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Banner: React.FC = () => {
  const userRole = useSelector((state: RootState) => state.userRole.userRole);
  if (!userRole) {
    return null;
  }
  return userRole === "admin" ? <AdminBanner /> : <UserBanner />;
};
export default Banner;
