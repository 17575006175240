/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useDispatch, useSelector } from "react-redux";
import { setJiraTickets, setJiraSummaries } from "../../store/cfs/cfsSlice";
import { RootState } from "../../store/store";
import { ToastQueue } from "@react-spectrum/toast";
import { useEffect, Key } from "react";
import { AiSummary } from "../../types/case";
import { fetchAIResponseStream } from "../../services/requestServices";

export const useTicketHandler = (
  initialJiraTickets: string[] = [],
  supportedJiraPrefixes: string[] = [],
) => {
  const dispatch = useDispatch();
  const cfsState = useSelector((state: RootState) => state.cfsAiSummery);
  const token = useSelector((state: RootState) => state.auth.token);
  const jiraTickets = cfsState.jiraTickets;
  const jiraSummaries = cfsState.jiraSummaries;

  const handleRemoveTicketID = (keys: Set<Key>): void => {
    dispatch(setJiraTickets(jiraTickets.filter((item) => !keys.has(item))));
  };

  const updateJiraSummaries = (k: string, v: AiSummary) => {
    return setJiraSummaries({ ...jiraSummaries, ...{ [k]: v } });
  };

  const summarizeTicket = (ticketId: string) => {
    dispatch(
      updateJiraSummaries(ticketId, {
        text: "",
        isLoading: true,
        prompt: ticketId,
      }),
    );
    const url = `${window.env.REACT_APP_API_BASE}cfs/getJiraRcaSummary/${ticketId}`;
    fetchAIResponseStream(url, token, {}, (response) =>
      dispatch(
        updateJiraSummaries(ticketId, {
          text: response.text,
          isLoading: response.isLoading,
          prompt: "",
        }),
      ),
    ).then((obj) => {
      if (obj?.error) {
        dispatch(
          updateJiraSummaries(ticketId, {
            text: "Error fetching Jira summary",
            isLoading: false,
            prompt: "",
          }),
        );
      }
    });
  };

  const handleAddTicketID = (...incomingTicketIds: string[]): void => {
    const ticketRegex = new RegExp(
      `(${supportedJiraPrefixes.join("|")}-\\d{2,})`,
      "g",
    );

    const ticketIds = incomingTicketIds
      .map((ticket) => {
        const match = ticket.match(ticketRegex);
        return match ? match[0] : "";
      })
      .filter((ticket) => ticket !== "");

    const invalidTickets = incomingTicketIds.filter(
      (ticket) => !ticket.match(ticketRegex),
    );

    if (invalidTickets.length > 0) {
      ToastQueue.negative(
        `Invalid ticket ID format for: ${invalidTickets.join(", ")}. Please use a string containing the correct ticket format, ie 'CSOPM-12345' or 'https://jira.corp.adobe.com/browse/CSOPM-12345'. Note only the following Jira ticket prefixes are allowed (${supportedJiraPrefixes.join(",")})`,
        {
          timeout: 10000,
        },
      );
    }

    const validTickets = ticketIds.filter((ticket) =>
      ticket.match(ticketRegex),
    );
    const uniqueMatches = Array.from(new Set(validTickets));
    const newTickets = uniqueMatches.filter(
      (ticket) => !jiraTickets.includes(ticket),
    );
    if (newTickets.length > 0) {
      dispatch(setJiraTickets([...jiraTickets, ...newTickets]));
    }
  };

  useEffect(() => {
    handleAddTicketID(...initialJiraTickets);
  }, []);

  return [
    summarizeTicket,
    jiraSummaries,
    jiraTickets,
    handleAddTicketID,
    handleRemoveTicketID,
  ] as const;
};
