/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

export default class BugReporter {
  static formURL =
    "https://wiki.corp.adobe.com/display/CESTRATINTELandOPS/Oasis+-+Report+a+Bug" as const;

  static featureRequestURL =
    "https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=CESTRATINTELandOPS&title=CCF+Feature+Request" as const;

  static generateUrl(
    { summary, description } = { summary: "", description: "" },
  ) {
    const url = new URL(BugReporter.formURL);
    url.searchParams.append("summary", summary);
    url.searchParams.append("description", description);
    return url;
  }
}
