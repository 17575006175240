/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { Dump } from "../../types/dump";

/**
 * Defines the Initial state.
 */
const initialState: Dump = {
  currentState: "BRANCHES_NOT_COLLECTED",
  data: {
    hipaaStatus: false,
    apiUrl: "",
    isDeleteButtonDisabled: false,
    isAllTablesSelected: false,
    intermediateState: false,
    intermediateMessage: "",
    mapError: true,
    dump_status: "",
    progress: 0,
    stage: "",
    latestDeployLogs: "",
    logLocation: "",
    deployed: false,
    chosenBranch: "",
    branchError: "",
    environmentList: [],
    checkedLinks: [],
    tableSize: {
      items: [],
    },
    dump: {
      installation_date: "",
      collected_by: "",
      chosen_branch: "",
      code_dump: "",
      db_dump: "",
      dump_dir: "",
      link: "",
      admin: "",
      message: "",
    },
  },
};
export default initialState;
