/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Flex, Text } from "@adobe/react-spectrum";
import WatchlistSwitch from "./WatchlistSwitch";

interface CaseNumberProps {
  dynamicsCaseId: string;
  ticketId: number;
}

const CaseNumber: React.FC<CaseNumberProps> = ({
  dynamicsCaseId,
  ticketId,
}) => {
  const isUserPersonaFeatureActive =
    localStorage.getItem("userPersonaFeatureActive") === "true";
  return (
    <Flex gap={5} direction="column">
      <Text>{dynamicsCaseId}</Text>
      {isUserPersonaFeatureActive && (
        <WatchlistSwitch itemId={ticketId} itemType="ticket" />
      )}
    </Flex>
  );
};

export default CaseNumber;
