/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import axios from "axios";
import getApiRoute from "../utils/getApiRoute";
import { Finding } from "../types/finding";
import { isEmpty, uniq } from "lodash";

export type sortOrder = "ascending" | "descending";
export type sortOrderByName = { name: sortOrder };
export type sortOrderByDocumentation = { documentation: sortOrder };
export type sortOrderByImpact = { impact: sortOrder };
export type sortOrderByCategories = { categories: sortOrder };

export interface FindingRequest {
  impact?: string[];
  categories?: string[];
  page?: number;
  sortOrder?:
    | sortOrderByName
    | sortOrderByDocumentation
    | sortOrderByImpact
    | sortOrderByCategories;
  productFamily: string;
  caseId: string;
}
export interface CreateJiraRequest {
  projectId: string;
  summary: string;
  description: string;
  issueType: string;
  userName: string;
  labels: string[];
}

export interface CreateJiraResponse {
  data: {
    id: string;
    key: string;
    self: string;
  };
}
export interface CreateKbArticleRequest {
  jira_id: string;
  dynamics_id: string;
  summary: string;
  is_public: boolean;
  created_by: string;
  reviewed_by: string;
  solution: string;
  status: string;
  content: string;
}

export interface KbArticle {
  id: bigint;
  jira_id: string;
  dynamics_id: string;
  summary: string;
  is_public: boolean;
  created_by: string;
  reviewed_by: string;
  solution: string;
  status: string;
  content: string;
}

export interface FindingResponse {
  data: Finding[] | { status: number };
  meta?: {
    findings_count?: number;
    publicMessage?: string;
    errors?: string;
    paging?: {
      current_page?: number;
      has_more_pages?: boolean;
    };
  };
}

export async function fetchFindingByCategoryOrImpact(
  formData: FindingRequest,
  token: string,
): Promise<FindingResponse> {
  const { impact, categories, page, sortOrder, productFamily, caseId } =
    formData;
  let urlParams = `?page=${page}`;

  if (!isEmpty(impact)) {
    const impactList = uniq(impact?.join(",").split(","));
    urlParams += `&filter[impact]=["${impactList?.join('","')}"]`;
  }
  if (!isEmpty(categories)) {
    const categoryList = uniq(categories?.join(",").split(","));
    urlParams += `&filter[categories]=["${categoryList?.join('","')}"]`;
  }
  if (!isEmpty(sortOrder)) {
    urlParams += `&sortOrder=${sortOrder}`;
  }
  const url = getApiRoute(
    `/api/v1/products/${productFamily}/dynamics/${caseId}/findings${urlParams}`,
  );
  return axios
    .get(url.href, {
      headers: {
        "X-OKTA-Authorization": `Bearer ${token}`,
      },
    })
    .then((res) => res);
}

export async function createJiraTicketRequest(
  formData: CreateJiraRequest,
  token: string,
): Promise<CreateJiraResponse> {
  const { projectId, summary, description, issueType, userName, labels } =
    formData;

  const url = getApiRoute(`/api/v1/jira/issues`);
  return axios
    .post(
      url.href,
      {
        project_id: projectId,
        summary: summary,
        description: description,
        issue_type: issueType,
        user_name: userName,
        labels: labels,
      },
      {
        headers: {
          "X-OKTA-Authorization": `Bearer ${token}`,
        },
      },
    )
    .then((res) => res);
}

export async function createKbArticleRequest(
  formData: CreateKbArticleRequest,
  token: string,
): Promise<CreateJiraResponse> {
  const {
    jira_id,
    dynamics_id,
    summary,
    is_public,
    created_by,
    reviewed_by,
    solution,
    status,
    content,
  } = formData;

  const url = getApiRoute(`/api/v1/knowledgeCenter/article`);
  return axios
    .post(
      url.href,
      {
        jira_id: jira_id,
        dynamics_id: dynamics_id,
        summary: summary,
        is_public: is_public,
        created_by: created_by,
        reviewed_by: reviewed_by,
        solution: solution,
        status: status,
        content: content,
      },
      {
        headers: {
          "X-OKTA-Authorization": `Bearer ${token}`,
        },
      },
    )
    .then((res) => res);
}

export const fetchAIResponseStream = async (
  url: string,
  token: string,
  requestBody: object,
  dispatchAction: (response: { text?: string; isLoading: boolean }) => void,
) => {
  try {
    const aiAssistantRequest = localStorage.getItem("ai-assistant-request");
    if (aiAssistantRequest) {
      const aiAssistantRequestJson = JSON.parse(aiAssistantRequest);
      requestBody = { ...requestBody, ...aiAssistantRequestJson };
    }
  } catch (error) {
    console.error(
      "Error retrieving or parsing ai-assistant-request from localStorage:",
      error,
    );
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-OKTA-Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.body || !response.ok) {
    return {
      error: { status: "FETCH_ERROR", error: "Response body is null" },
      data: undefined,
      meta: undefined,
    };
  }

  const downloadJsonFile = (data: object, filename: string) => {
    const jsonData = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const reader = response.body.getReader();
  let responseText = "";
  let buffer = "";

  const processStream = async ({
    done,
    value,
  }: ReadableStreamReadResult<Uint8Array>) => {
    if (done) {
      if (buffer.trim()) {
        responseText += buffer;
      }
      dispatchAction({ text: responseText, isLoading: false });
      return;
    }

    const chunk = new TextDecoder().decode(value);
    buffer += chunk;

    while (buffer.includes("\n")) {
      const lineEndIndex = buffer.indexOf("\n");
      let completeLine = buffer.slice(0, lineEndIndex).trim();
      buffer = buffer.slice(lineEndIndex + 1);

      if (completeLine) {
        if (completeLine.trim() === "[DONE]") {
          dispatchAction({ text: responseText, isLoading: false });
          return;
        }

        try {
          const chunkTrimJson = completeLine.startsWith("data: ")
            ? completeLine.slice(6)
            : completeLine;
          const data = JSON.parse(chunkTrimJson);
          if (data.choices && data.choices.length > 0) {
            responseText += data.choices[0].delta?.content || "";
            dispatchAction({ text: responseText, isLoading: true });
          } else if (data.gptRequestDebug) {
            const debugData = Object.entries(data.gptRequestDebug).map(
              ([key, value]) => ({
                data: value as object,
                filename: `${key}.json`,
              }),
            );
            for (const { data, filename } of debugData) {
              downloadJsonFile(data, filename);
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          }
        } catch (error) {}
      }
    }

    reader.read().then(processStream).catch();
  };

  await reader.read().then(processStream).catch();
};
