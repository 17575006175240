/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  codeBlockPlugin,
  codeMirrorPlugin,
  CodeToggle,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  headingsPlugin,
  InsertTable,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  quotePlugin,
  sandpackPlugin,
  StrikeThroughSupSubToggles,
  tablePlugin,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";
import { Button, Flex } from "@adobe/react-spectrum";
import { useAiArticle } from "../../../hooks/useAiArticle";
import {
  resetState,
  transitionState,
} from "../../../store/knowledgeCenter/knowledgeCenterSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  createJiraTicketRequest,
  createKbArticleRequest,
  CreateKbArticleRequest,
} from "../../../services/requestServices";
import { get } from "lodash";
import { ToastQueue } from "@react-spectrum/toast";

export function KbArticleEdit() {
  const ref = React.useRef<MDXEditorMethods>(null);
  let [isReviewed, setIsReviewed] = React.useState(false);
  let [isPublic, setIsPublic] = React.useState(false);
  const [aiArticleText, aiArticleIsLoading, getAiArticle, reload] =
    useAiArticle();
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const caseId = caseObject?.caseId || "";
  const productFamily = caseObject?.productFamilyName || "";
  const user = useSelector((state: RootState) => state.user.userDetails);
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch();

  const createKbArticle = (body: string) => {
    dispatch(transitionState({ targetState: "loading" }));
    let labels = ["oasis"];
    labels.push(isPublic ? "public" : "internal");
    let userName = "";
    let userNameMatchArr = user?.email?.match(/([a-zA-Z0-9._%+-]+)@/);
    if (userNameMatchArr) {
      userName = userNameMatchArr[1];
    } else {
      if (user?.userid) {
        userName = user?.userid.toString();
      }
    }

    const jiraRequest = {
      projectId: "DXSKB",
      summary: "Adobe Support Insights: " + caseId + " - Article Request",
      description: "{code}" + body + "{code}",
      issueType: "Documentation",
      labels: labels,
      userName: userName || "",
    };
    createJiraTicketRequest(jiraRequest, token)
      .then((response) => {
        const jiraTicketKey = get(response, "data.data.key", "000000");
        ToastQueue.positive(
          "Knowledge article submitted and Jira ticket " +
            jiraTicketKey +
            " has been created successfully.",
          {
            timeout: 3000,
          },
        );
        const kbArticleRequest: CreateKbArticleRequest = {
          jira_id: jiraTicketKey,
          dynamics_id: caseId,
          summary: jiraRequest["summary"],
          is_public: isPublic,
          created_by: jiraRequest["userName"],
          reviewed_by: "Not Reviewed Yet",
          solution: productFamily,
          status: "in-review",
          content: body,
        };
        createKbArticleRequest(kbArticleRequest, token)
          .then((response) => {
            const internalId = get(response, "data.data.id", "000000");
            ToastQueue.positive(
              "Knowledge article submitted and internal id " + internalId,
              {
                timeout: 3000,
              },
            );
            dispatch(
              transitionState({
                targetState: "loadingKnowledgeArticles",
                kbArticlesFetched: false,
              }),
            );
          })
          .catch((error: any) => {
            ToastQueue.negative(
              "Sorry for the inconvenience, we are facing Creating an Article Error. Please visit after sometime.",
              { timeout: 5000 },
            );
          });
      })
      .catch((error: any) => {
        ToastQueue.negative(
          "Sorry for the inconvenience, we are facing Creating Jira Backend Error. Please visit after sometime.",
          { timeout: 5000 },
        );
      });
  };

  // @fixme: work around for MDXEditor syntax error for ``` and ---
  const fixMdFormatting = (text: string) => {
    const codeBlockRegex = /```[a-zA-Z]*/g;
    return text.replace(codeBlockRegex, "`").replace(/---/g, "");
  };

  return (
    <Flex direction="column">
      <Flex direction="row" gap="size-200" wrap="wrap">
        <MDXEditor
          ref={ref}
          markdown={fixMdFormatting(aiArticleText || "")}
          plugins={[
            headingsPlugin(),
            diffSourcePlugin({
              diffMarkdown: aiArticleText || "",
              viewMode: "rich-text",
            }),
            codeBlockPlugin({ defaultCodeBlockLanguage: "sql" }),
            listsPlugin(),
            quotePlugin(),
            tablePlugin(),
            codeMirrorPlugin(),
            linkPlugin(),
            quotePlugin(),
            markdownShortcutPlugin(),
            sandpackPlugin(),
            toolbarPlugin({
              toolbarContents: () => (
                <DiffSourceToggleWrapper>
                  {" "}
                  <BlockTypeSelect />
                  <BoldItalicUnderlineToggles />
                  <CodeToggle />
                  <StrikeThroughSupSubToggles />
                  <ListsToggle />
                  <InsertTable />
                  <UndoRedo />
                  {/*<InsertCodeBlock />*/}
                </DiffSourceToggleWrapper>
              ),
            }),
          ]}
        />
        <Flex direction="column">
          <Flex direction="row" marginBottom={"size-150"}>
            <input
              type="checkbox"
              id="scales"
              name="scales"
              checked={isReviewed}
              onChange={(e) => setIsReviewed(e.target.checked)}
            />
            <label htmlFor="scales">
              I have personally reviewed and verified the modified content.
            </label>
          </Flex>
          <Flex direction="row" marginBottom={"size-150"}>
            <input
              type="checkbox"
              id="publicly_available"
              name="publicly_available"
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
            />
            <label htmlFor="publicly_available">
              Make this article external (public).
            </label>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="row" gap="size-200" wrap="wrap">
        <Flex>
          <Button
            variant="cta"
            onPress={() => {
              const knowledgeArticle = ref.current?.getMarkdown() || "";
              localStorage.setItem(
                "knowledgeArticle" + caseId,
                knowledgeArticle,
              );
              dispatch(
                transitionState({
                  targetState: "view",
                  aiArticle: knowledgeArticle,
                }),
              );
            }}
          >
            Save
          </Button>
        </Flex>
        <Flex>
          <Button
            variant="cta"
            onPress={() => {
              localStorage.removeItem("knowledgeArticle" + caseId);
              createKbArticle(ref.current?.getMarkdown() || "");
            }}
          >
            Save & Submit
          </Button>
        </Flex>
        <Flex>
          <Button
            variant="primary"
            onPress={() => {
              dispatch(resetState());
            }}
          >
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default KbArticleEdit;
