/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

// src/App.tsx
import React from "react";
import AppRoutes from "./routes/AppRoutes";
import "./styles/main.scss";
import { Provider, defaultTheme } from "@adobe/react-spectrum";
import { store } from "./store/store";
import { Provider as AppProvider } from "react-redux";
import { Security } from "@okta/okta-react";
import { RestoreOriginalUriFunction } from "@okta/okta-react/bundles/types/OktaContext";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import config from "./config/OktaConfig";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "@react-spectrum/toast";

const oktaAuth = new OktaAuth(config.oidc);

const App: React.FC = () => {
  const navigate = useNavigate();

  const restoreOriginalUri: RestoreOriginalUriFunction = async (
    _,
    originalUri,
  ) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };

  return (
    <AppProvider store={store}>
      <Provider
        theme={defaultTheme}
        colorScheme="light"
        UNSAFE_className="AdobeClean"
      >
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <AppRoutes />
        </Security>
        <ToastContainer />
      </Provider>
    </AppProvider>
  );
};

export default App;
