/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect, useState } from "react";
import {
  Button,
  TextArea,
  ButtonGroup,
  DialogTrigger,
  Dialog,
  Heading,
  Content,
  Divider,
  Form,
  Header,
  ActionButton,
  Text,
  DialogContainer,
  Flex,
  ContextualHelp,
} from "@adobe/react-spectrum";
import GearsEdit from "@spectrum-icons/workflow/GearsEdit";
import AiMarkdownRenderer from "../../aiAssistant/AiMarkdownRenderer";
import { RootState } from "../../../store/store";
import { setSystemPrompt } from "../../../store/cfs/cfsSlice";
import { useSelector, useDispatch } from "react-redux";
import prompt from "./prompt";
import { LabelableProps, StyleProps } from "@react-types/shared";

interface TitleProps {
  title: string;
}

type CfsSystemPromptProps = TitleProps & LabelableProps & StyleProps;

const CfsSystemPrompt: React.FC<CfsSystemPromptProps> = ({
  title,
  label,
  ...styleProps
}) => {
  const dispatch = useDispatch();
  const cfsState = useSelector((state: RootState) => state.cfsAiSummery);
  const systemPrompt =
    cfsState.systemPrompt == "" ? prompt.prompt : cfsState.systemPrompt;
  const [isEdited, setIsEdited] = useState(false);
  const [viewingMarkdown, setViewingMarkdown] = useState(false);
  const [changedPrompt, setChangedPrompt] = useState(systemPrompt);
  let [isOpen, setOpen] = useState(false);

  const header = isEdited ? `${title} (edited)` : title;

  const handleUpdatePrompt = () => {
    if (changedPrompt !== systemPrompt) {
      setIsEdited(true);
    }
    dispatch(setSystemPrompt(changedPrompt));
    setViewingMarkdown(false);
  };

  useEffect(() => {
    dispatch(setSystemPrompt(prompt.prompt));
  }, []);

  return (
    <Flex direction="column">
      {label && (
        <Flex direction="row">
          <label className="cfs-system-prompt-label">{label}</label>
          <ContextualHelp variant="info">
            <Heading>System Prompt</Heading>
            <Content>
              <Text>
                Edit the base system prompt used to generate the response.
              </Text>
            </Content>
          </ContextualHelp>
        </Flex>
      )}
      <ActionButton {...styleProps} onPress={() => setOpen(true)}>
        <GearsEdit />
        <Text>{header}</Text>
      </ActionButton>
      <DialogContainer onDismiss={() => setOpen(false)}>
        {isOpen && (
          <Dialog>
            <Heading>
              Edit System Prompt {viewingMarkdown ? "(Markdown Preview)" : ""}
            </Heading>
            <Header>
              <Button
                variant="secondary"
                onPress={() => setViewingMarkdown(!viewingMarkdown)}
              >
                {viewingMarkdown ? "Edit System Prompt" : "Preview Markdown"}
              </Button>
            </Header>
            <Divider />
            <Content>
              <Form>
                {viewingMarkdown ? (
                  <AiMarkdownRenderer text={changedPrompt} />
                ) : (
                  <TextArea
                    value={changedPrompt}
                    onChange={setChangedPrompt}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
                        handleUpdatePrompt();
                        setOpen(false);
                      }
                    }}
                    width="100%"
                  />
                )}
              </Form>
            </Content>
            <ButtonGroup>
              <Button
                variant="secondary"
                onPress={() => {
                  setChangedPrompt(systemPrompt);
                  setViewingMarkdown(false);
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="accent"
                onPress={() => {
                  handleUpdatePrompt();
                  setOpen(false);
                }}
              >
                Save
              </Button>
            </ButtonGroup>
          </Dialog>
        )}
      </DialogContainer>
    </Flex>
  );
};

export default CfsSystemPrompt;
