/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";

interface SelectableTableCellProps {
  children: React.ReactNode;
}

/**
 * This component is used to wrap content for a table cell that can be selected and copied by a user.
 * @param {React.ReactNode} children - The content to be wrapped.
 * @returns {React.ReactElement} A span element with the content that can be selected and copied.
 */
const SelectableTableCellContent: React.FC<SelectableTableCellProps> = ({
  children,
}) => {
  return (
    <span
      style={{ cursor: "text", WebkitUserSelect: "text" }}
      onPointerDown={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {children}
    </span>
  );
};

export default SelectableTableCellContent;
