/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface branchObject {
  id: string;
  title: string;
  selected: string;
}

interface hipaaStatusResponse {
  hipaa: boolean;
}

interface regionResponse {
  region: string;
}

interface environmentListResponse {
  dump_status: string;
  message: string;
  environments: branchObject[];
  tables: string[];
  progress?: number;
  stage?: string;
  deployLogLocation?: string;
  latestDeployLogs?: string;
}

interface skipTablesResponse {
  dump_status: string;
  tables: string[];
  message: string;
}

interface environmentResponse {
  dump_status: string;
  environment: string;
  code?: string;
  message?: string;
}

interface updateBranch {
  branch: string;
  dump_status: string;
}
interface deleteDump {
  dump_status: string;
  message: string;
}

export const dumpToolApi = createApi({
  reducerPath: "dumpApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    credentials: "same-origin",
    timeout: 3000,
  }),
  endpoints: (builder) => ({
    getProjectHipaaStatus: builder.query<
      hipaaStatusResponse,
      { baseUrl: string; projectId: string }
    >({
      query: ({ baseUrl, projectId }) =>
        `${baseUrl}/api/v1/dump-hipaa/${projectId}`,
      transformResponse: (response: { hipaa: string }) => ({
        ...response,
        hipaa: response.hipaa === "true", // Convert the 'hipaa' field to boolean
      }),
    }),
    getProjectRegion: builder.query<
      regionResponse,
      { baseUrl: string; projectId: string }
    >({
      query: ({ baseUrl, projectId }) =>
        `${baseUrl}/api/v1/dump-region/${projectId}`,
    }),
    getEnvironmentList: builder.query<
      environmentListResponse,
      { baseUrl: string; projectId: string; branch: string; ticketId: string }
    >({
      query: ({ baseUrl, projectId, branch, ticketId }) =>
        `${baseUrl}/api/v1/dump/links/${projectId}/${branch}/${ticketId}`,
    }),
    skipTablesModal: builder.query<
      skipTablesResponse,
      { baseUrl: string; projectId: string; branch: string; user: string }
    >({
      query: ({ baseUrl, projectId, branch, user }) =>
        `${baseUrl}/api/v1/dump/tables/${projectId}/${branch}?requester=${user}`,
    }),
    getEnvironment: builder.query<
      environmentResponse,
      { baseUrl: string; projectId: string; ticketId: string }
    >({
      query: ({ baseUrl, projectId, ticketId }) =>
        `${baseUrl}/api/v1/dump/environment/${ticketId}`,
    }),
    updateBranch: builder.mutation<
      updateBranch,
      { baseUrl: string; branch: string; ticketId: string }
    >({
      query: ({ baseUrl, branch, ticketId }) => ({
        url: `${baseUrl}/api/v1/dump/environment/${branch}/${ticketId}`,
        method: "POST",
      }),
    }),
    deleteButton: builder.mutation<
      deleteDump,
      {
        baseUrl: string;
        projectId: string;
        branch: string;
        queryString: string;
        name: string;
      }
    >({
      query: ({ baseUrl, projectId, branch, queryString, name }) => ({
        url: `${baseUrl}/api/v1/dump/${projectId}/${branch}?requester=${name}`,
        method: "DELETE",
      }),
    }),
    createDump: builder.query<
      any,
      { baseUrl: string; checkLinks: string; projectId: string; branch: string }
    >({
      query: ({ baseUrl, checkLinks, projectId, branch }) =>
        `${baseUrl}/api/v1/dump/${projectId}/${branch}${checkLinks}`,
    }),
  }),
});

export const {
  useGetEnvironmentListQuery,
  useLazySkipTablesModalQuery,
  useGetEnvironmentQuery,
  useUpdateBranchMutation,
  useDeleteButtonMutation,
  useLazyCreateDumpQuery,
  useGetProjectRegionQuery,
  useGetProjectHipaaStatusQuery,
} = dumpToolApi;
