/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { View, ProgressCircle, Text } from "@adobe/react-spectrum";
import { Button } from "@adobe/react-spectrum";
import BranchSelector from "./BranchSelector";
import FormView from "../../common/FormView";
import useCollectTableSize from "../../../hooks/useCollectTableSize";

const CollectTableSizesButton: React.FC = () => {
  const {
    showSkipTablesModal,
    tableListIsLoading,
    environmentLoading,
    branch,
  } = useCollectTableSize();

  return (
    <FormView
      title={"Deploy Dumps"}
      body={
        <View marginTop={20}>
          <BranchSelector />
        </View>
      }
      footerInfo={'Select branch and click on "Collect table sizes" button'}
      footerButtonComponent={
        <Button
          variant="cta"
          aria-label="Collect Table Size"
          isDisabled={tableListIsLoading || environmentLoading}
          onPress={() => showSkipTablesModal(branch)}
          margin={"size-150"}
        >
          {tableListIsLoading || environmentLoading ? (
            <ProgressCircle
              aria-label="Loading…"
              isIndeterminate
              size="S"
              marginEnd="size-150"
            />
          ) : (
            <></>
          )}
          <Text>Collect Table Sizes</Text>
        </Button>
      }
    />
  );
};

export default CollectTableSizesButton;
