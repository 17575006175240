/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import {
  Cell,
  Column,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Button,
  Flex,
  View,
  ProgressCircle,
} from "@adobe/react-spectrum";
import BranchSelector from "./BranchSelector";
import FormView from "../../common/FormView";
import { useSizeTableList, Item } from "../../../hooks/useSizeTableList";

const SizeTableList: React.FC = () => {
  const { selectedKeys, onChange, onAction, columns, rows, isLoading, error } =
    useSizeTableList();

  if (error) {
    return <p>Error Happened</p>;
  }

  return (
    <FormView
      title={"Deploy Dumps"}
      body={
        <Flex direction="column">
          <View>
            <Flex direction="row">
              <BranchSelector />
            </Flex>
            <p>Choose tables to exclude from dump:</p>
            <TableView
              aria-label="Table with controlled selection"
              selectionMode="multiple"
              selectedKeys={selectedKeys}
              onSelectionChange={(keys) => onChange(keys)}
            >
              <TableHeader columns={columns}>
                {(column) => (
                  <Column
                    key={column.uid}
                    align={column.uid === "level" ? "end" : "start"}
                  >
                    {column.name}
                  </Column>
                )}
              </TableHeader>
              <TableBody items={rows}>
                {(item) => (
                  <Row key={item.table}>
                    {(columnKey) => <Cell>{(item as Item)[columnKey]}</Cell>}
                  </Row>
                )}
              </TableBody>
            </TableView>
          </View>
        </Flex>
      }
      footerInfo={
        'Check the required tables and click on "Collect & deploy dump" button '
      }
      footerButtonComponent={
        <Button
          variant="cta"
          aria-label="Collect Table Size"
          isDisabled={isLoading}
          onPress={() => onAction(selectedKeys)}
          margin={"size-150"}
        >
          {isLoading ? (
            <ProgressCircle size="S" aria-label="Loading…" isIndeterminate />
          ) : (
            "Collect & deploy dump"
          )}
        </Button>
      }
    />
  );
};

export default SizeTableList;
