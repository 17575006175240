/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useState, useEffect } from "react";
import { formatDate } from "../utils/formatDate";
import { convertToLocalTimeDate } from "../utils/dateTimeUtils";

export const useBannerDateTimes = (
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [endDateTime, setEndDateTime] = useState<string | null>(null);

  useEffect(() => {
    if (startDate) {
      setStartDateTime(convertToLocalTimeDate(startDate));
    }

    if (endDate) {
      setEndDateTime(convertToLocalTimeDate(endDate));
    }
  }, [startDate, endDate]);

  return { startDateTime, endDateTime };
};
