/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { Key, useEffect } from "react";
import {
  Content,
  ContextualHelp,
  Heading,
  Item,
  Picker,
} from "@adobe/react-spectrum";
import { RootState } from "../../../store/store";
import { setProduct } from "../../../store/cfs/cfsSlice";
import { useSelector, useDispatch } from "react-redux";
import prompt from "./prompt";
import { useLocation } from "react-router-dom";
import { LabelableProps, StyleProps } from "@react-types/shared";

type CfsProductPickerProps = LabelableProps & StyleProps;

const CfsProductPicker: React.FC<CfsProductPickerProps> = ({
  label,
  ...styleProps
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const initialProduct = queryParams.get("product");
  const cfsState = useSelector((state: RootState) => state.cfsAiSummery);
  const selectedProduct = cfsState.product;

  const handleUpdateProduct = (product: string): void => {
    dispatch(setProduct(product));
  };

  useEffect(() => {
    if (initialProduct) {
      dispatch(setProduct(initialProduct));
    }
  }, []);

  const products = prompt.supportedProducts.map((product) => ({
    id: product,
  }));

  return (
    <Picker
      {...styleProps}
      contextualHelp={
        <ContextualHelp variant="info">
          <Heading>Product</Heading>
          <Content>The product impacted by the outage.</Content>
        </ContextualHelp>
      }
      label={label}
      onSelectionChange={(key: Key) => handleUpdateProduct(key.toString())}
      items={products}
      selectedKey={selectedProduct}
    >
      {(item) => <Item>{item.id}</Item>}
    </Picker>
  );
};

export default CfsProductPicker;
