/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Aggregated } from "./dataAggregator";
import { DataPoint } from "../types/datapoints";

export const enum Format {
  html,
  csv,
  pdf,
  htmlToPdf,
}

export async function convertAndCopyForFindings(
  json: Aggregated,
  format: Format,
  fileName: string = "",
): Promise<void> {
  try {
    if (format === Format.htmlToPdf) {
      htmlToPDF(json.join("\n"), fileName);
      return;
    }

    const pdfdata: Record<"host" | "value" | string, string>[] = [];
    json.forEach((record) => {
      const value = JSON.parse(
        typeof record === "object" ? record.value : record,
      ) as unknown;
      const host = typeof record === "object" ? record.value : "";
      if (Array.isArray(value)) {
        pdfdata.push(...value);
      } else {
        pdfdata.push({
          host,
          value: typeof value === "string" ? value : JSON.stringify(value),
        });
      }
    });

    if (format === Format.pdf) {
      jsonToPDF(pdfdata, fileName);
      return;
    }
  } catch (error) {
    console.error("Failed to process data or copy to clipboard:", error);
  }
}

export async function convertAndCopyForDataPoints(
  json: Aggregated,
  format: Format,
  fileName: string = "",
): Promise<void> {
  try {
    if (format === Format.htmlToPdf) {
      htmlToPDF(json.join("\n"), fileName);
      return;
    }

    const pdfdata: Record<string, string>[] = [];
    json.forEach((record) => {
      if (typeof record === "string") {
        pdfdata.push({ Value: record });
        return;
      }
      //remove hostname from the table if hostname is not present (Indicates that data is not from a specific host).
      if (typeof record.Hostname === "undefined") delete record.Hostname;
      pdfdata.push(record);
    });

    if (format === Format.pdf) {
      jsonToPDF(pdfdata, fileName);
      return;
    }
  } catch (error) {
    console.error("Failed to process data or copy to clipboard:", error);
  }
}

export async function convertAndCopy(
  json: string,
  format: Format,
  fileName: string = "",
): Promise<void> {
  try {
    if (format === Format.htmlToPdf) {
      htmlToPDF(json, fileName);
      return;
    }
    const data: Record<string, string>[] = JSON.parse(json);

    if (format === Format.pdf) {
      jsonToPDF(data, fileName);
      return;
    }
    let outputString: string;

    if (format === Format.html) {
      outputString = jsonToHTML(data);
    } else if (format === Format.csv) {
      outputString = jsonToCSV(data);
    } else {
      throw new Error("Unsupported format");
    }

    await navigator.clipboard.writeText(outputString);
  } catch (error) {
    console.error("Failed to process data or copy to clipboard:", error);
  }
}

function isSimpleOrEmptyData(json: string): boolean {
  try {
    const data = JSON.parse(json);
    if (!Array.isArray(data) || data.length === 0) return true; // Check for empty array
    if (data.some((entry) => typeof entry == "object")) return false;
    return false;
  } catch {
    return true;
  }
}

export async function convertDataPointsToPDF(
  DataPoints: DataPoint[],
  fileName: string = "",
): Promise<void> {
  try {
    const doc = new jsPDF();

    const body = DataPoints.filter((row) => isSimpleOrEmptyData(row.value)).map(
      (row) => [row.name, row.value],
    );

    if (body.length > 0) {
      doc.text("Data Points with single value", 20, 10);
      //DataPoints.map((record:any) => Object.values(record));
      const columns = ["Name", "Value"];

      autoTable(doc, {
        head: [columns],
        body: body,
        startY: 20,
        theme: "grid",
        headStyles: { fillColor: "#EB1002" },
      });
    }

    //doc.text("Data Point with Data", 10, 10);
    const DataPointsWithData = DataPoints.filter(
      (row) => !isSimpleOrEmptyData(row.value),
    );

    DataPointsWithData.forEach((element, index) => {
      if (index > 0 || body.length > 0) {
        doc.addPage(); // Add new page except for the first complex data point if simpleDataPoints is empty
      }
      doc.text(element.name, 20, 10);
      const data: Record<string, string>[] = JSON.parse(element.value);
      jsonToPDFDoc(doc, data);
    });

    doc.save(`${fileName}.pdf`);
  } catch (error) {
    console.error("Failed to process data or copy to clipboard:", error);
  }
}

export async function downloadDataPointsAsCsv(
  DataPoints: DataPoint[],
  fileName: string = "",
  columns: string[],
  valueFields: string[],
): Promise<void> {
  try {
    const datapoints: Array<[string, DataPoint[]]> =
      aggregateDataPoints(DataPoints);

    // Add the header row
    let csvString = columns.join(",") + "\r\n"; // Add the header row

    datapoints.forEach(([code, datapoints]) => {
      const datapoint: DataPoint = datapoints[0];
      let csvRow: string =
        '"' +
        valueFields
          .map((valueField) => getProperty(datapoint, valueField))
          .map((value: string | null) => value?.replace(/"/g, '""'))
          .join('","') +
        '"\r\n';
      csvString += csvRow;
    });

    download(csvString, `${fileName}.csv`);
  } catch (error) {
    console.error("Failed to process data or copy to clipboard:", error);
  }
}

function getProperty<T extends object>(obj: T, key: string): any {
  if (key in obj) {
    return obj[key as keyof T];
  }
  return null;
}

export function aggregateDataPoints(
  DataPoints: DataPoint[],
): Array<[string, DataPoint[]]> {
  const codesToDataPointsMap = new Map<string, DataPoint[]>();

  /** Datapoints with these codes will be omitted from the aggregation */
  const excludedDatapointsFromTable = ["bash-dsat", "bash-predictive-dsat"];
  for (const datapoint of DataPoints) {
    if (excludedDatapointsFromTable.includes(datapoint.code)) {
      continue;
    }
    if (codesToDataPointsMap.has(datapoint.code)) {
      codesToDataPointsMap.get(datapoint.code)?.push(datapoint);
    } else {
      codesToDataPointsMap.set(datapoint.code, [datapoint]);
    }
  }

  return Array.from(codesToDataPointsMap.entries());
}

function htmlToPDF(htmlString: string, fileName: string = "document"): void {
  const doc = new jsPDF();

  doc.html(htmlString);

  // Actual conversion code would go here.

  doc.save(`${fileName}.pdf`);
}

function jsonToPDFDoc(doc: jsPDF, data: Record<string, string>[]): void {
  // You might need to cast your data appropriately for autoTable
  const columns = Object.keys(data[0]).map((key) => ({
    title: key,
    dataKey: key,
  }));
  const body = data.map((record) => Object.values(record));

  // Using autoTable directly if the method extension isn't recognized
  autoTable(doc, {
    head: [columns.map((col) => col.title)],
    body: body,
    startY: 20,
    theme: "grid",
    headStyles: { fillColor: "#EB1002" },
  });
}

function jsonToPDF(
  data: Record<string, string>[],
  fileName: string = "",
): void {
  const doc = new jsPDF();

  // You might need to cast your data appropriately for autoTable
  const columns = Object.keys(data[0]).map((key) => ({
    title: key,
    dataKey: key,
  }));
  const body = data.map((record) => Object.values<string>(record));

  // Using autoTable directly if the method extension isn't recognized
  autoTable(doc, {
    head: [columns.map((col) => col.title)],
    body: body,
    startY: 20,
    theme: "grid",
    headStyles: { fillColor: "#EB1002" },
  });

  // Save the created PDF
  doc.save(`${fileName}.pdf`);
}

function jsonToHTML(data: Record<string, unknown>[]): string {
  let htmlString = "<table><thead><tr>";
  if (data.length > 0) {
    Object.keys(data[0]).forEach((key) => {
      htmlString += `<th>${key}</th>`;
    });
    htmlString += "</tr></thead><tbody>";

    data.forEach((entry) => {
      htmlString += "<tr>";
      Object.keys(entry).forEach((key) => {
        htmlString += `<td>${entry[key]}</td>`;
      });
      htmlString += "</tr>";
    });

    htmlString += "</tbody></table>";
  }
  return htmlString;
}

function jsonToCSV(data: Record<string, unknown>[]): string {
  let csvString = "";
  if (data.length > 0) {
    csvString += Object.keys(data[0]).join(",") + "\r\n"; // Add the header row

    data.forEach((row) => {
      csvString +=
        Object.values(row)
          .map((value) => `"${value}"`)
          .join(",") + "\r\n";
    });
  }
  return csvString;
}

// Function to download the CSV file
function download(data: string, filename: string) {
  // Create a Blob with the CSV data and type
  const blob = new Blob([data], { type: "text/csv" });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create an anchor tag for downloading
  const a = document.createElement("a");

  // Set the URL and download attribute of the anchor tag
  a.href = url;
  a.download = filename;

  // Trigger the download by clicking the anchor tag
  a.click();
}
