/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useState } from "react";
import {
  Flex,
  TableHeader,
  TableView,
  TableBody,
  Column,
  Row,
  Cell,
  ButtonGroup,
  Button,
} from "@adobe/react-spectrum";
import { useNavigate } from "react-router-dom";
import ContributionComponent from "../common/ContributionComponent";
import DefectForm from "./DefectForm";
import { DefectConfirmationProps } from "./types";

const DefectConfirmation: React.FC<DefectConfirmationProps> = ({
  formData,
}) => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false); //State management to show the defect form

  const handleBackToList = () => {
    navigate("/workfront");
  };

  const handleCreateDefect = () => {
    setShowForm(true); //Sets show form state to True when "Create a new defect" button is pressed
  };

  return (
    <>
      {showForm ? (
        <DefectForm />
      ) : (
        <ContributionComponent
          title="Defect Creation Confirmation"
          developerEmail="nfinsand@adobe.com, grimaud@adobe.com"
          developerSlack="#oasis-slack"
        >
          <TableView
            aria-label="Table for Defect Creation Confirmation"
            margin="size-500"
            overflowMode="wrap"
          >
            <TableHeader>
              <Column width="10%">Defect Name</Column>
              <Column width="35%">Description</Column>
              <Column width="5%">Ticket Number</Column>
              <Column width="5%">CRM</Column>
              <Column width="5%">Severity</Column>
              <Column width="10%">Application Area</Column>
              <Column width="10%">Environment</Column>
              <Column width="20%">Steps to Duplicate</Column>
            </TableHeader>
            <TableBody>
              <Row>
                <Cell>
                  <span style={{ whiteSpace: "pre-line" }}>
                    {formData["defect-name"]}
                  </span>
                </Cell>
                <Cell>
                  <span style={{ whiteSpace: "pre-line" }}>
                    {formData["description"]}
                  </span>
                </Cell>
                <Cell>
                  <span style={{ whiteSpace: "pre-line" }}>
                    {formData["ticket-number"]}
                  </span>
                </Cell>
                <Cell>
                  <span style={{ whiteSpace: "pre-line" }}>
                    {formData["crm"]}
                  </span>
                </Cell>

                <Cell>
                  <span style={{ whiteSpace: "pre-line" }}>
                    {formData["severity"]}
                  </span>
                </Cell>
                <Cell>
                  <span style={{ whiteSpace: "pre-line" }}>
                    {formData["application-area"]}
                  </span>
                </Cell>
                <Cell>
                  <span style={{ whiteSpace: "pre-line" }}>
                    {formData["environment"] === "OnDemand"
                      ? "Production"
                      : formData["environment"]}
                  </span>
                </Cell>
                <Cell>
                  <span style={{ whiteSpace: "pre-line" }}>
                    {formData["steps-duplicate"]}
                  </span>
                </Cell>
              </Row>
            </TableBody>
          </TableView>
          <Flex justifyContent="end" marginEnd="size-500">
            <ButtonGroup>
              <Button onPress={handleCreateDefect} variant={"accent"}>
                Create a new defect
              </Button>
              <Button onPress={handleBackToList} variant={"accent"}>
                Back to list
              </Button>
            </ButtonGroup>
          </Flex>
        </ContributionComponent>
      )}
    </>
  );
};

export default DefectConfirmation;
