/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lastAnalysisDate: "",
};

const lastAnalysisSlice = createSlice({
  name: "lastAnalysis",
  initialState,
  reducers: {
    setLastAnalysisDate: (state, action) => {
      state.lastAnalysisDate = action.payload;
    },
  },
});

export const { setLastAnalysisDate } = lastAnalysisSlice.actions;
export default lastAnalysisSlice.reducer;
