/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createSlice } from "@reduxjs/toolkit";
import { TempAdmin } from "../../types/tempAdmin";

const initialState: TempAdmin = {
  hipaaStatus: undefined,
  message: "",
  error: "",
  isLoaded: true,
  workInProgress: false,
  user: {
    id: 0,
    project: "",
    environment: "",
    email: "",
    password: "",
    username: "",
    ticket: "",
    adminUrl: "",
    adminUri: "",
  },
  users: [],
  zd: {},
  ticketStatus: "",
  status: 0,
  data: {},
  orgId: "",
  rengaId: "",
  pollingEnabled: false,
  requestCount: 0,
  deleteIsLoading: false,
};

const tempAdminSlice = createSlice({
  name: "tempAdmin",
  initialState,
  reducers: {
    setHipaaStatus: (state, action) => {
      state.hipaaStatus = action.payload;
    },
    setTempAdmin: (state, action) => {
      state.user = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setWorkInProgress: (state, action) => {
      state.workInProgress = action.payload;
    },
    setTicketStatus: (state, action) => {
      state.ticketStatus = action.payload;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
    setPollingEnabled: (state, action) => {
      state.pollingEnabled = action.payload;
    },
    setRequestCount: (state, action) => {
      state.requestCount = action.payload;
    },
    setDeleteIsLoading: (state, action) => {
      state.deleteIsLoading = action.payload;
    },
  },
});

export const {
  setHipaaStatus,
  setTempAdmin,
  setWorkInProgress,
  setError,
  setIsLoaded,
  setPollingEnabled,
  setRequestCount,
  setDeleteIsLoading,
  setErrorMessage,
} = tempAdminSlice.actions;
export default tempAdminSlice.reducer;
