/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { View, Flex, Heading } from "@adobe/react-spectrum";
import CfsTool from "../components/cfsTool/CfsTool/CfsTool";
import ContributionComponent from "../components/common/ContributionComponent";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const CFSPage: React.FC = () => {
  const userRole = useSelector((state: RootState) => state.userRole.userRole);

  if (userRole !== "prt" && userRole !== "admin") {
    return <></>;
  }

  return (
    <ContributionComponent
      title="Customer Facing Statement Generator"
      developerEmail="zspencer@adobe.com"
    >
      <Flex direction="column">
        <View>
          <Heading level={4}>Select Solution and Jira Tickets</Heading>
          <CfsTool />
        </View>
      </Flex>
    </ContributionComponent>
  );
};

export default CFSPage;
