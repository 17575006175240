/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { MouseEventHandler, useEffect, useState } from "react";
import { useAsyncList } from "@adobe/react-spectrum";
import SortOrderDown from "@spectrum-icons/workflow/SortOrderDown";
import SortOrderUp from "@spectrum-icons/workflow/SortOrderUp";

import {
  Button,
  ButtonGroup,
  Item,
  ListView,
  SearchField,
} from "@adobe/react-spectrum";
import {
  debounce,
  filter,
  forEach,
  get,
  has,
  isArray,
  isEmpty,
  omit,
  set,
} from "lodash";

import { ReactComponent as FilterIcon } from "../../assets/svg/filter_icon.svg";
import { ToastQueue } from "@react-spectrum/toast";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export interface OptionsMenu {
  id: string | number;
  name: string;
}

export interface FilterItem {
  id: string | number;
  name: string;
}

export interface FilterQuery {
  filterName: string;
  query?: string;
  filterQuery?: string; //"<search string from filter list>"
  page: number;
}

export type ORDER_ACTION = "descending" | "ascending";
export type FETCH_METHOD_TYPE = "GET" | "POST";
export type SortBy = { [key: string]: ORDER_ACTION };
export type Key = string | number;
export type Selection = "all" | Set<Key>;

const onInnerClick: MouseEventHandler<HTMLDivElement> = (e) =>
  e.stopPropagation();

const showSortIcon = (sortBy: string) => {
  return sortBy === "ascending" ? (
    <SortOrderUp width={18} height={18} />
  ) : (
    <SortOrderDown width={18} height={18} />
  );
};

interface DropDownFilterProps {
  name: string;
  options: OptionsMenu[];
  columnName: string;
  filterOption: boolean;
  hasColumnSortBy: boolean;
  defaultSelectedKeys?: Selection;
  endpointFilter?: string;
  endpointMethod?: FETCH_METHOD_TYPE;
  filterQuery?: FilterQuery;
  onApplyFilter: (items: string[]) => void;
  fetchOptionList?: (columnName: string) => OptionsMenu[];
  onSortOrder: (columnName: string, orderBy: ORDER_ACTION) => void;
}

interface Character {
  name: string;
}

const DropDownFilter: React.FC<DropDownFilterProps> = ({
  name,
  options,
  columnName,
  defaultSelectedKeys = new Set([]),
  filterOption,
  hasColumnSortBy,
  endpointFilter = "",
  endpointMethod = "POST",
  filterQuery,
  onSortOrder,
  onApplyFilter,
}) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showSortByIcon, setShowSortByIcon] = useState(hasColumnSortBy);
  const [filterMenu, setFilterMenu] = useState<OptionsMenu[]>(options);
  const [sortBy, setSortBy] = useState<ORDER_ACTION>("ascending");
  const [selectedKeys, setSelectedKeys] =
    useState<Selection>(defaultSelectedKeys);
  const [loadMoreMenu, setLoadMoreMenu] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const selectedColumnList = [localStorage.getItem("filterColumnName")];

  useEffect(() => {
    if (options.length > 0) {
      const filterOptionsMenu =
        searchText === ""
          ? options
          : filter(options, function (item) {
              return item.name.toLowerCase().includes(searchText.toLowerCase());
            });
      setFilterMenu(filterOptionsMenu);
    } else {
      listReload();
    }
  }, [searchText]);

  const token = useSelector((state: RootState) => state.auth.token);

  let list = useAsyncList<Character>({
    async load({ signal, cursor }) {
      let searchRequest = filterQuery ?? { filterName: "", query: "", page: 1 };

      if (
        cursor === "" ||
        cursor === undefined ||
        !filterOption ||
        filterQuery === undefined
      ) {
        setLoadMoreMenu(false);
        return {
          items: [],
          cursor: "",
        };
      }

      let searchURL = endpointFilter;

      let requestAPI: RequestInit = {
        method: endpointMethod,
        headers: {
          "Content-type": "application/json",
          "X-OKTA-Authorization": `Bearer ${token}`,
        },
        signal,
      };
      if (endpointMethod === "POST") {
        if (searchText !== "") {
          set(searchRequest, "filterQuery", searchText);
        } else {
          searchRequest = omit(searchRequest, ["filterQuery"]);
        }

        if (!isEmpty(cursor)) {
          const pageNumber = get(JSON.parse(cursor), "page", "1");
          set(searchRequest, "page", pageNumber);
        }

        set(requestAPI, "body", JSON.stringify({ ...searchRequest }));
      } else {
        const pageNumber =
          cursor !== undefined ? get(JSON.parse(cursor), "page", 1) : 1;
        searchURL = `${endpointFilter}?filterName=${searchRequest?.filterName}&page=${pageNumber}`;
        if (searchText !== "") {
          searchURL += `${searchURL}&filterQuery=${searchText}`;
        }
      }
      let res = await fetch(searchURL, requestAPI);

      const responseData = await res.json();

      const { data, meta } = responseData;
      if (has(meta, "publicMessage")) {
        setLoadMoreMenu(false);
        return { items: [], cursor: "" };
      }

      const metaInfo = has(meta, "current_page")
        ? responseData.meta
        : responseData.meta.paging;
      const currentPage = metaInfo.current_page;
      const hasMorePages = metaInfo.has_more_pages;

      let itemList: OptionsMenu[] = [];
      for (const listId in data) {
        if (data[listId] !== null) {
          let itemOption = data[listId]
            .replaceAll(/"/g, "")
            .replace(/[\[\]']+/g, "");
          const itemId = `${listId}${currentPage}`;
          itemList.push({ id: itemId, name: itemOption });
        }
      }
      setFilterMenu([...filterMenu, ...itemList]);
      if (hasMorePages && !loadMoreMenu) {
        setLoadMoreMenu(true);
      }

      return {
        items: [...itemList],
        cursor: hasMorePages
          ? JSON.stringify({ ...searchRequest, page: `${currentPage + 1}` })
          : "",
      };
    },
  });
  const listReload = debounce(() => list.reload(), 400);

  const handleSearch = (query: string) => {
    setSearchText(query);
  };

  const handleSort = () => {
    setShowSortByIcon(true);
    if (sortBy !== null) {
      const sortOrder = sortBy === "ascending" ? "descending" : "ascending";
      setSortBy(sortOrder);
      onSortOrder(columnName, sortOrder);
      return;
    }
    onSortOrder(columnName, "ascending");
    setSortBy("ascending");
  };

  const handleMenu = () => {
    setShowFilterMenu(!showFilterMenu);
  };

  const handleApplyFilter = (items: Selection) => {
    setSelectedKeys(items);
    const selectedOption = Array.from(items);
    if (isArray(selectedOption)) {
      //@ts-ignore Results will always be an array of strings in this case.
      onApplyFilter(selectedOption);
    }
    setShowFilterMenu(false);
  };

  return (
    <div className="dropdown-filter-wapper">
      <div
        className={`filter-column-wapper ${showSortByIcon ? "cols-3" : "cols-2"}`}
        onPointerDown={onInnerClick}
        onMouseDown={onInnerClick}
      >
        <span className="filter-column-text" onClick={handleSort}>
          {name}
        </span>
        <span
          className={`column-sort-icon`}
          onClick={handleSort}
          style={{ display: showSortByIcon ? "block" : "none" }}
        >
          {showSortIcon(sortBy)}
        </span>
        {filterOption && (
          <span
            onMouseOver={() => setShowFilterMenu(true)}
            onMouseOut={() => setShowFilterMenu(false)}
          >
            <button onClick={handleMenu} className="column-icon-button">
              <FilterIcon />
            </button>
          </span>
        )}
      </div>

      {showFilterMenu && (
        <div
          className="filter-wapper"
          onMouseOver={() => setShowFilterMenu(true)}
          onMouseOut={() => setShowFilterMenu(false)}
        >
          <div
            style={{ padding: 10 }}
            onPointerDown={onInnerClick}
            onMouseDown={onInnerClick}
          >
            <SearchField
              onChange={(search) => handleSearch(search)}
              defaultValue={searchText}
              onClear={() => listReload()}
              width="100%"
              UNSAFE_className="search-list"
              onBlur={({ target }) => {
                const inputTarget = target as HTMLInputElement;
                inputTarget.focus();
              }}
            />
          </div>
          <ListView
            items={options.length > 0 ? filterMenu : list.items}
            loadingState={list.loadingState}
            onLoadMore={loadMoreMenu ? list.loadMore : (): void => {}}
            selectionMode="multiple"
            maxWidth="size-6000"
            maxHeight="200px"
            UNSAFE_style={{ borderStyle: "none" }}
            defaultSelectedKeys={
              selectedColumnList.includes(columnName)
                ? selectedKeys
                : new Set([])
            }
            onSelectionChange={setSelectedKeys}
            UNSAFE_className="listview-option"
          >
            {(item) => <Item key={item.name}>{item.name}</Item>}
          </ListView>
          <ButtonGroup UNSAFE_className="filter-button-container">
            <Button
              variant="accent"
              onPress={() => handleApplyFilter(selectedKeys)}
              isDisabled={list.items.length === 0 && options.length === 0}
            >
              Apply
            </Button>
            <Button
              variant="primary"
              onPress={() => handleApplyFilter(new Set([]))}
              isDisabled={list.items.length === 0 && options.length === 0}
            >
              Clear
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export function handleSortIcon(columnIndex: Number) {
  const sortByEL = Array.from(
    document.getElementsByClassName(
      "column-sort-icon",
    ) as HTMLCollectionOf<HTMLElement>,
  );
  for (const i in sortByEL) {
    if (Number(i) !== columnIndex) {
      sortByEL[i].style.display = "none";
    } else {
      sortByEL[i].style.display = "block";
    }
  }
}

export default DropDownFilter;
