/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createSlice } from "@reduxjs/toolkit";
import { KnowledgeCenterState } from "../../types/knowledgeCenter";

const stateTransitions: Record<string, string[]> = {
  idle: ["loadingKnowledgeArticles"],
  loading: [
    "loadingKnowledgeArticles",
    "idle",
    "showArticlesList",
    "editing",
    "readyToGenerateArticle",
    "showDuplicateArticlesList",
    "view",
  ],
  loadingKnowledgeArticles: [
    "showArticlesList",
    "editing",
    "loading",
    "readyToGenerateArticle",
  ],
  showArticlesList: ["idle", "loading"],
  showDuplicateArticlesList: ["idle", "loading", "view"],
  readyToGenerateArticle: [
    "loading",
    "showArticlesList",
    "showDuplicateArticlesList",
  ],
  view: ["editing", "loading"],
  editing: ["loadingKnowledgeArticles", "loading", "view"],
};

const initialState: KnowledgeCenterState = {
  currentState: "idle", // Main state
  aiArticle: {
    text: "",
    isLoading: false,
  },
  kbArticles: [],
  kbArticlesFetched: false,
  duplicatedArticles: [],
  flags: {
    isIdle: false,
    isLoading: false,
    isLoadingKnowledgeArticles: false,
    isShowArticlesList: false,
    isShowDuplicatedArticlesList: false,
    isReadyToGenerateArticle: false,
    isView: false,
    isEditing: false,
  },
};

const knowledgeCenterSlice = createSlice({
  name: "knowledgeCenter",
  initialState,
  reducers: {
    /**
     * Transitions to a valid state and updates associated flags.
     */
    transitionState(state, action) {
      let targetState = action.payload.targetState;
      state.kbArticlesFetched = Object.keys(action.payload).includes(
        "kbArticlesFetched",
      )
        ? action.payload.kbArticlesFetched
        : state.kbArticlesFetched;

      // Update flags based on the current state;
      if (targetState === "kbArticlesFetched") {
        if (action.payload.kbArticles && action.payload.kbArticles.length > 0) {
          targetState = "showArticlesList";
        } else {
          targetState = "readyToGenerateArticle";
        }
      }
      if (stateTransitions[state.currentState]?.includes(targetState)) {
        state.currentState = targetState;
        state.aiArticle = action.payload.aiArticle || state.aiArticle;
        state.kbArticles = action.payload.kbArticles || state.kbArticles;
        state.duplicatedArticles =
          action.payload.duplicatedArticles || state.duplicatedArticles;

        state.flags = {
          isIdle: targetState === "idle",
          isLoading: targetState === "loading",
          isLoadingKnowledgeArticles:
            targetState === "loadingKnowledgeArticles",
          isShowArticlesList: targetState === "showArticlesList",
          isShowDuplicatedArticlesList:
            targetState === "showDuplicateArticlesList",
          isReadyToGenerateArticle: targetState === "readyToGenerateArticle",
          isView: targetState === "view",
          isEditing: targetState === "editing",
        };
      } else {
        console.warn(
          `Invalid transition: ${state.currentState} -> ${targetState}`,
        );
      }
    },
    setAiArticle: (state, action) => {
      state.aiArticle = { ...state.aiArticle, ...action.payload };
    },
    /**
     * Resets the state to its initial configuration.
     */
    resetState: () => initialState,
  },
});

export const { transitionState, setAiArticle, resetState } =
  knowledgeCenterSlice.actions;

export default knowledgeCenterSlice.reducer;
