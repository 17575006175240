/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { IMenuGroup, SidebarProps } from "../types/ISideNavbar";
import MenuData from "../config/MenuConfig";
import { CaseObject } from "../types/case";
import { extractPrefixFromEntitledProduct } from "./marketoPrefixExtractor";

function createSidebarConfig(caseObject: CaseObject | undefined): SidebarProps {
  const config: IMenuGroup = {
    OASIS: [],
    "External Links": [],
  };

  if (!caseObject) {
    return { config };
  }

  // Filter items by the specified product family
  const filteredItems = MenuData.filter(
    (item) =>
      item.Solution === "All Solutions" ||
      item.Solution === caseObject.productFamilyName,
  );

  const productMetadata = caseObject?.productMetadata;
  const projectIdEntry = productMetadata
    ? productMetadata.find((item) => item.displayName === "Project ID")
    : null;
  const commerceProjectId = projectIdEntry ? projectIdEntry.value : "";

  filteredItems.forEach((item) => {
    const type = item.LinkType;

    //Replace the placeholders in the URL with the actual values
    item.LinkURL = item.LinkURL.replace(
      ":caseInfoSuffix",
      `case/${caseObject.caseId}/org_id/${caseObject.orgId}/renga_id/${caseObject?.admin?.rengaId}`,
    )
      .replace("{orgId}", caseObject?.orgId)
      .replace("{orgName}", encodeURIComponent(caseObject.orgName))
      .replace("{caseId}", caseObject.caseId)
      .replace("{commerceProjectId}", commerceProjectId)
      .replace("{requestor_email}", encodeURIComponent(caseObject.email))
      .replace(
        "{marketoPrefix}",
        encodeURIComponent(
          extractPrefixFromEntitledProduct(caseObject.entitledProduct),
        ),
      );

    if (type === "Internal") {
      config["OASIS"].push(item);
      return;
    }
    if (type === "External") {
      config["External Links"].push(item);
      return;
    }
    if (!config[type]) {
      config[type] = []; // Initialize an empty array if this type hasn't been added to the config yet
    }
    config[type].push(item);
  });

  return { config };
}

export default createSidebarConfig;
