/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { View, Flex } from "@adobe/react-spectrum";
import SearchBar from "../search/SearchBar";
import Recents from "./Recents";
import EditUserPreferencesButton from "./EditUserPreferencesButton";

const SubNavbar: React.FC = () => {
  return (
    <View
      colorVersion={6}
      backgroundColor="indigo-900"
      paddingX="size-400"
      paddingY="size-150"
    >
      <Flex direction="row" justifyContent="space-between">
        <View>
          <SearchBar />
        </View>
        <Flex gap="size-50">
          <Recents />
          <EditUserPreferencesButton />
        </Flex>
      </Flex>
    </View>
  );
};
export default SubNavbar;
