/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { IMenuItem } from "../../types/ISideNavbar";
import CreateTemporaryAdmin from "../../pages/CreateTemporaryAdmin";
import DataDumpTool from "../../pages/DataDumpTool";

const CommerceMenuData: IMenuItem[] = [
  {
    Solution: "Commerce",
    LinkName: "SWAT Tool",
    LinkURL: "https://supportinsights.adobe.com/commerce/tab/main",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Customers and internal teams can use this tool to view the health of a customers site, as well as recommendations to improve their site performance. Also includes recommended patches.",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Commerce",
    LinkName: "Create Temporary Admin",
    LinkURL: "create-temporary-admin/:caseInfoSuffix",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Allows a support agent to create a temporary admin user for a customer.",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "Internal",
    ParameterType: "",
    component: <CreateTemporaryAdmin />,
  },
  {
    Solution: "Commerce",
    LinkName: "Data Dump Tool",
    LinkURL: "data-dump-tool/:caseInfoSuffix",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "Internal",
    ParameterType: "",
    component: <DataDumpTool />,
  },
  {
    Solution: "Commerce",
    LinkName: "Quality Patches Tool",
    LinkURL:
      "https://experienceleague.adobe.com/tools/commerce-quality-patches/index.html",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Provides information about the patches available to customers via Quality Patches Tool",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Commerce",
    LinkName: "ACC Tools",
    LinkURL: "https://acc-tools.corp.adobe.com/project/{commerceProjectId}",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Provides insights into the health of a customers site, as well as recommendations to improve their site performance.",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Commerce",
    LinkName: "Security Scan",
    LinkURL:
      "https://experienceleague.adobe.com/docs/commerce-admin/systems/security/security-scan.html?lang=en",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Will run a comprehensive security scan for the customer.",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Commerce",
    LinkName: "Observability for Adobe Commerce (OAC)",
    LinkURL:
      "https://experienceleague.adobe.com/docs/commerce-operations/tools/observation-for-adobe-commerce/access.html?lang=en",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Detailed New Relic data about a customers site, performance and resource utilizations.",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Commerce",
    LinkName: "New Relic",
    LinkURL: "https://one.newrelic.com/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "New Relic is a monitoring tool available to customers. Comes pre-configured with a basic set of alerts and customers can also modify.",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Commerce",
    LinkName: "P.sh Console",
    LinkURL: "https://console.adobecommerce.com/user/{commerceProjectId}",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "P.sh Console",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Commerce",
    LinkName: "Splunk",
    LinkURL: "https://splunk.or1.adobe.net/en-US/app/launcher/home",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Commerce",
    LinkName: "Columbus",
    LinkURL: "https://columbus.corp.adobe.com/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Commerce",
    LinkName: "ABO / Login-As",
    LinkURL:
      "https://abo.services.adobe.com/customers/search?query={requestor_email}",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Commerce",
    LinkName: "Panorama",
    LinkURL: "https://pmg.corp.adobe.com/PanoramaECJ",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
];

export default CommerceMenuData;
