/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import React, { useEffect } from "react";
import BranchSelector from "./BranchSelector";
import {
  Content,
  Flex,
  Heading,
  InlineAlert,
  Text,
} from "@adobe/react-spectrum";
import FormView from "../../common/FormView";
import DeleteButton from "./DeleteButton";

const DeployedLinks: React.FC = () => {
  function openLink(url: string) {
    window.open(url, "_blank");
  }

  const dump = useSelector((state: RootState) => state.dump.data);

  if (!dump.dump) {
    return null;
  }

  return (
    <FormView
      title={"Deploy Dumps"}
      body={
        <Flex width={500} direction="column">
          <BranchSelector />
          {/*TODO: style error message with red color "negative" variant*/}
          {dump.dump.message && (
            <InlineAlert variant={"info"} marginTop={30} marginBottom={30}>
              <Heading>Latest deployed dump:</Heading>
              <Content>
                <p dangerouslySetInnerHTML={{ __html: dump.dump.message }} />
              </Content>
            </InlineAlert>
          )}
          <Flex direction="row" gap="size-350" margin="size-50">
            <b>When:</b>
            <Text>{dump.dump.installation_date}</Text>
          </Flex>
          <Flex direction="row" gap="size-450" margin="size-50">
            <b>By:</b>
            <Text>
              <b>{dump.dump.collected_by}</b>
            </Text>
          </Flex>
          <Flex direction="row" gap="size-350" margin="size-50">
            <b>Dumps:</b>
            <Text>{dump.dump.dump_dir}</Text>
          </Flex>
          <Flex direction="row" gap="size-350" margin="size-50">
            <b>Website:</b>
            <Text>
              <a
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => openLink(dump.dump.link)}
              >
                Frontend
              </a>
            </Text>
          </Flex>
          <Flex direction="row" gap="size-350" margin="size-50">
            <b>Admin:</b>
            <Text>
              <a
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => openLink(dump.dump.link + "admin")}
              >
                Backend
              </a>
            </Text>
          </Flex>
        </Flex>
      }
      footerInfo={
        "Dump process has been completed, please check the information. " +
        'If you need to delete the Dump, please click "Delete Dump" button.'
      }
      footerButtonComponent={<DeleteButton />}
    />
  );
};

export default DeployedLinks;
