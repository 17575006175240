/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { Dispatch } from "redux";
import { ToastQueue } from "@react-spectrum/toast";
import { CaseObject } from "../types/case";
import { setIsTicketRefreshing } from "../store/case/caseSlice";

interface CollectDynamicsDataParams {
    caseObject: CaseObject;
    dynamicsCollectData: any;
    productFamilyCode: string;
    dispatch: Dispatch;
    setIsDisabled: (disabled: boolean) => void;
    silent?: boolean;
}


export const collectDynamicsData = async ({
                                              caseObject,
                                              dynamicsCollectData,
                                              productFamilyCode,
                                              dispatch,
                                              setIsDisabled,
                                              silent = false,
                                          }: CollectDynamicsDataParams) => {
    try {
        await dynamicsCollectData({
            payload: { case: caseObject },
            productFamilyCode: productFamilyCode,
        }).unwrap();
        ToastQueue.positive("Data Successfully Refreshed", { timeout: 5000 });
        setIsDisabled(false);
        dispatch(setIsTicketRefreshing(false));
    } catch (error: any) {
        if (!silent) {
            ToastQueue.negative(
                error.data?.meta?.errors?.length
                    ? error.data.meta.errors.join("<br>")
                    : "An error occurred while refreshing the ticket data. Please try again.",
                { timeout: 5000 }
            );
        }
        setIsDisabled(false);
        dispatch(setIsTicketRefreshing(false));
    }
};
