/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import History from "@spectrum-icons/workflow/History";
import {
  DialogTrigger,
  Divider,
  Flex,
  ActionButton,
  Text,
} from "@adobe/react-spectrum";
import { Dialog } from "react-aria-components";

const Recents = () => {
  const recentCases = useSelector((state: RootState) => state.user.recentCases);
  let recentCasesList = null;

  if (recentCases.length > 0) {
    recentCasesList = recentCases.map((recentCase, index) => (
      <a className="recents-link" key={index} href={recentCase.url}>
        {recentCase.caseId}
      </a>
    ));
  } else {
    recentCasesList = <Text>No recent cases</Text>;
  }

  return (
    <DialogTrigger type="popover" crossOffset={-50}>
      <ActionButton staticColor="white" isQuiet>
        <History />
        <Text>Recents</Text>
      </ActionButton>
      <Dialog className="recents-dialog">
        <Flex direction="column" width="size-2000">
          <span className="recents-dialog-heading">Recents</span>
          <Divider size="S" marginY="size-150" />
          <Flex direction="column" gap="size-150">
            <span className="recents-section-title">CASES</span>
            {recentCasesList}
          </Flex>
        </Flex>
      </Dialog>
    </DialogTrigger>
  );
};

export default Recents;
