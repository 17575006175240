/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Button, ProgressCircle, View } from "@adobe/react-spectrum";
import FormView from "../../common/FormView";

type UserInfo = {
  deleteAdmin: any;
  tempAdminObject: any;
  buttonMsg: string;
};

const UserInfo: React.FC<UserInfo> = ({
  deleteAdmin,
  tempAdminObject,
  buttonMsg,
}) => {
  return (
    <FormView
      title={"Admin User Management"}
      body={
        <View marginTop={20}>
          <div>
            Admin Link:{" "}
            <i>
              <a
                href={
                  tempAdminObject?.user?.adminUrl +
                  tempAdminObject?.user?.adminUri
                }
                target="_blank"
                rel="noreferrer"
              >
                {tempAdminObject?.user?.adminUri}
              </a>
            </i>
          </div>
          <div>
            Ticket: <b>{tempAdminObject?.user?.ticket}</b>
          </div>
          <div>
            Username: <b>{tempAdminObject?.user?.username}</b>
          </div>
          <div>
            Password: <b>{tempAdminObject?.user?.password}</b>
          </div>
        </View>
      }
      footerInfo={
        'Click on "Remove admin user" button to remove existing temp admin user'
      }
      footerButtonComponent={
        <Button
          variant={"cta"}
          onPress={() => deleteAdmin()}
          isDisabled={!tempAdminObject.isLoaded}
          style="fill"
          margin={"size-150"}
        >
          {!tempAdminObject.isLoaded && (
            <ProgressCircle
              aria-label="Loading…"
              isIndeterminate
              size="S"
              marginEnd="size-150"
            />
          )}
          {buttonMsg.length > 0 ? buttonMsg : "Remove admin user"}
        </Button>
      }
    />
  );
};

export default UserInfo;
