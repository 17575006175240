/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

export function extractPrefixFromEntitledProduct(
  entitledProduct?: string,
): string {
  if (entitledProduct === undefined) {
    return "";
  }

  const parts: string[] = entitledProduct.split(/\s*-\s*/);

  // Check if the entitled product is in the expected format (single hyphen separating product name and prefix)
  // and if the prefix contains any non-alphanumeric characters
  if (parts.length !== 2 || !/^[a-zA-Z0-9]+$/.test(parts[1])) {
    return "";
  }

  return parts[1];
}
