/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import {
  ActionBarContainer,
  Content,
  Divider,
  Flex,
  Heading,
  Text,
  View,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import MessageComponent from "../common/MessageComponent";

const OrgNotesDetailsTable = () => {
  const orgNotesData = useSelector(
    (state: RootState) => state.case.internalNotes,
  );
  if (orgNotesData?.length === 0) {
    return (
      <MessageComponent title="No Org Notes to Display"></MessageComponent>
    );
  }
  return (
    <div>
      <ActionBarContainer>
        <Divider marginBottom={"size-100"} marginTop={"size-100"} />
        <Content maxHeight={"size-4600"}>
          {orgNotesData?.map((note, index) => (
            <View key={index}>
              <Flex justifyContent="space-between">
                <Heading
                  level={4}
                  margin={"size-50"}
                  marginStart={0}
                  maxWidth={"size-4600"}
                >
                  <Text
                    UNSAFE_style={{
                      fontSize: "14px",
                    }}
                  >
                    Note {index + 1}: <Text>{note.note_id}</Text>
                  </Text>
                </Heading>
              </Flex>
              <Flex>
                <Heading level={5} margin={"size-50"} marginStart={0}>
                  <Text
                    UNSAFE_style={{
                      fontSize: "14px",
                    }}
                  >
                    Title: <Text>{note.subject}</Text>
                  </Text>
                </Heading>
              </Flex>
              {/* <Flex>
                <Heading level={5} margin={"size-50"} marginStart={0}>
                  <Text
                    UNSAFE_style={{
                      marginBottom: "6px",
                      fontSize: "14px",
                    }}
                  >
                    Status Value: <Text>{note.status}</Text>
                  </Text>
                </Heading>
              </Flex> */}
              <Text>{note.description}</Text>
              {orgNotesData?.length - 1 > index && (
                <Divider
                  size={"S"}
                  marginBottom={"size-150"}
                  marginTop={"size-150"}
                />
              )}
            </View>
          ))}
        </Content>
      </ActionBarContainer>
    </div>
  );
};

export default OrgNotesDetailsTable;
