/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { Key } from "react";
import {
  View,
  ProgressCircle,
  Flex,
  ActionGroup,
  Item,
} from "@adobe/react-spectrum";
import Copy from "@spectrum-icons/workflow/Copy";
import Refresh from "@spectrum-icons/workflow/Refresh";
import { AiSummary } from "../../../types/case";
import { ToastQueue } from "@react-spectrum/toast";
import AiMarkdownRenderer from "../AiMarkdownRenderer";
import { PromptMatrixKey } from "./prompts";

export interface AiResponseProps {
  summary: AiSummary | undefined;
  prompt: string;
  promptKey?: PromptMatrixKey;
  askQuestion: (a: string, promptKey?: PromptMatrixKey) => void;
}

const AiResponse: React.FC<AiResponseProps> = ({
  summary,
  prompt,
  promptKey,
  askQuestion,
}) => {
  const handleSummaryButton = (key: Key) => {
    if (typeof key === "string") {
      if (key === "regenerate") {
        askQuestion(prompt, promptKey);
        // analytics.track("aiSummaryButtonPress", {
        //   caseId: caseId,
        //   prompt: prompt,
        // });
      } else if (key === "copy") {
        navigator.clipboard
          .writeText(summary?.text || "")
          .then(() =>
            ToastQueue.positive("Copied to clipboard", { timeout: 3000 }),
          )
          .catch(() =>
            ToastQueue.negative("Failed to copy to clipboard", {
              timeout: 5000,
            }),
          );
      }
    }
  };

  if (!summary)
    return (
      <View>
        <Flex direction="column">
          <ProgressCircle aria-label="Loading…" isIndeterminate />
        </Flex>
      </View>
    );

  const failed = (
    <p>
      <strong>AI summarization failed: </strong>
      An error occurred while responding to your query. Please try again with a
      different prompt.
    </p>
  );

  const response =
    !summary.isLoading && !summary.text ? (
      failed
    ) : (
      <AiMarkdownRenderer text={summary.text || ""} />
    );

  return (
    <View UNSAFE_className="response">
      <div id={"ai-response"}>{response}</div>
      {summary.isLoading && (
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      )}
      <Flex justifyContent="start">
        <ActionGroup
          density="compact"
          isQuiet={true}
          onAction={handleSummaryButton}
        >
          <Item
            key="copy"
            data-analytics-link-name="Copy AI Summary"
            data-analytics-view-name="AI Summary"
          >
            <Copy />
          </Item>
          <Item
            key="regenerate"
            data-analytics-link-name="Regenerate AI Summary"
            data-analytics-view-name="AI Summary"
          >
            <Refresh />
          </Item>
        </ActionGroup>
      </Flex>
    </View>
  );
};

export default AiResponse;
