/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createSlice } from "@reduxjs/toolkit";
import type { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";
import { UserPreferences } from "../types/UserPreferences";
import { ProductFamily } from "../types/ProductFamily";
import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "../utils/localStorageHelpers";
import { RecentCase } from "../types/case";

interface UserSlice {
  userDetails: UserClaims<CustomUserClaims> | null;
  error: string | null;
  preferences?: UserPreferences<ProductFamily>;
  openPreferencesModal: boolean;
  recentCases: RecentCase[];
}

const initialState: UserSlice = {
  userDetails: null,
  error: null,
  openPreferencesModal: false,
  recentCases: loadFromLocalStorage<RecentCase[]>("recentCases") || [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userDetails = action.payload;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearUser: (state) => {
      state.userDetails = null;
      state.error = null;
    },
    setUserPreferences: (state, action) => {
      state.preferences = action.payload;
    },
    setOpenPreferencesModal: (state, action) => {
      state.openPreferencesModal = action.payload;
    },
    addRecentCase: (state, action) => {
      const newCase: RecentCase = action.payload;
      // Remove duplicates
      const filteredCases = state.recentCases.filter(
        (existingCase) => existingCase.caseId !== newCase.caseId,
      );
      // Add the new case to the beginning
      const updatedCases = [newCase, ...filteredCases];
      // Limit to 5 cases
      state.recentCases = updatedCases.slice(0, 5);
      // Persist to localStorage
      saveToLocalStorage("recentCases", state.recentCases);
    },
  },
});

export const {
  setUser,
  setError,
  clearUser,
  setUserPreferences,
  setOpenPreferencesModal,
  addRecentCase,
} = userSlice.actions;

export default userSlice.reducer;
