/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import {
  AlertDialog,
  Button,
  DialogContainer,
  Flex,
  ProgressCircle,
  Text,
} from "@adobe/react-spectrum";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteButtonMutation } from "../../../services/dumpTool";
import {
  disableDeleteButton,
  enableDeleteButton,
  deleteDumpAction,
  mapError,
} from "../../../store/dump/dumpSlice";
import { RootState } from "../../../store/store";
import getProjectId from "../../../utils/getProjectId";
import * as stateTypes from "../../../types/stateTypes";

const DeleteButton: React.FC = () => {
  const dispatch = useDispatch();
  const dumpObject = useSelector((state: RootState) => state.dump);
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const productMetadata = caseObject?.productMetadata ?? [];
  const projectUrlObject = productMetadata.find(
    (item) => item.displayName === "Project URL",
  );
  const projectUrl = projectUrlObject?.value || "";
  const baseUrl = dumpObject.data.apiUrl;
  const state = useSelector((state: RootState) => state.dump);

  const user = useSelector(
    (state: RootState) => (state.user.userDetails?.name as string) ?? "",
  );
  let projectId = "";
  let branch = "";
  const queryString = "?requester=" + "currentUser.name";
  let [isOpen, setOpen] = React.useState(false);
  const [deleteButton, { data, isLoading: isDeleting, isSuccess }] =
    useDeleteButtonMutation();

  if (projectUrl) {
    projectId = getProjectId(projectUrl);
  }

  if (dumpObject.data.chosenBranch) {
    branch = dumpObject.data.chosenBranch;
  }

  const deleteDump = (
    projectId: string,
    branch: string,
    queryString: string,
  ) => {
    deleteButton({
      baseUrl: baseUrl,
      projectId: projectId,
      branch: branch,
      queryString: queryString,
      name: user,
    });
    if (!isDeleting) {
      dispatch(disableDeleteButton());

      switch (data?.dump_status) {
        case "delete_in_progress":
          dispatch(deleteDumpAction(true));
          dispatch(enableDeleteButton());
          break;
        case "map_error":
          dispatch(mapError("error"));
          dispatch(enableDeleteButton());
          break;
      }
    }
    if (!isDeleting && !isSuccess) {
      dispatch(enableDeleteButton());
      dispatch(mapError("error when deleting dumps"));
    }
  };

  return (
    <Flex wrap gap="size-500">
      <Button
        variant="cta"
        aria-label="Delete Dump"
        isDisabled={state.currentState === stateTypes.DELETE_IN_PROGRESS_STATE}
        onPress={() => setOpen(true)}
        margin={"size-150"}
      >
        {state.data.intermediateState &&
        !(
          state.currentState === stateTypes.DEPLOY_SUCCESS_STATE ||
          state.currentState === stateTypes.DUMP_STUCK_STATE ||
          state.currentState === stateTypes.DUMP_IN_PROGRESS_STATE ||
          state.currentState === stateTypes.DEPLOY_IN_PROGRESS_STATE ||
          state.currentState === stateTypes.TABLE_SIZE_IS_NOT_COLLECTED_STATE ||
          state.currentState === stateTypes.TABLE_SIZE_IN_PROGRESS_STATE
        ) ? (
          <ProgressCircle
            aria-label="Loading…"
            isIndeterminate
            size="S"
            marginEnd="size-150"
          />
        ) : (
          <></>
        )}
        <Text>Delete Dump</Text>
      </Button>
      <DialogContainer onDismiss={() => setOpen(false)}>
        {isOpen && (
          <AlertDialog
            title="Delete dump"
            variant="destructive"
            primaryActionLabel="Delete"
            cancelLabel="Cancel"
            onPrimaryAction={() => deleteDump(projectId, branch, queryString)}
          >
            Are you sure you want to delete Dump?
          </AlertDialog>
        )}
      </DialogContainer>
    </Flex>
  );
};

export default DeleteButton;
