/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useEffect, useState } from "react";
import { useGetUserBannerDataQuery } from "../services/supportInsights";
import { CODE } from "../config/BannerConfig";
import { setBannerData } from "../store/bannerSlice";
import { useDispatch } from "react-redux";
import {
  getLocalTimeZone,
  now,
  parseAbsoluteToLocal,
} from "@internationalized/date";
import { convertToISODateUTCTimeZone } from "../utils/dateTimeUtils";

export const useBannerVisibility = (token: string | undefined) => {
  const dispatch = useDispatch();
  const skip = !token;
  const { data, error, isLoading, refetch } = useGetUserBannerDataQuery(
    { CODE, token: token as string },
    { skip },
  );
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  useEffect(() => {
    const checkBannerVisibility = () => {
      if (!data || !Array.isArray(data)) {
        setIsMessageVisible(false);
        return;
      }
      if (data.length > 0) {
        const endDate = parseAbsoluteToLocal(
          convertToISODateUTCTimeZone(data[0].data.endDate),
        );

        dispatch(setBannerData(data[0]));
        if (endDate.compare(now(getLocalTimeZone())) < 0) {
          setIsMessageVisible(false);
        } else {
          setIsMessageVisible(true);
        }
      } else {
        setIsMessageVisible(false);
      }
    };
    checkBannerVisibility();
    const intervalId = setInterval(checkBannerVisibility, 60000);
    return () => clearInterval(intervalId);
  }, [data, dispatch]);
  return { data, isMessageVisible, isLoading, error, refetch };
};
