/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  useGetEnvironmentListQuery,
  useLazySkipTablesModalQuery,
} from "../services/dumpTool";
import {
  mapError,
  showSkipTablesList,
  tableSizeInProgress,
} from "../store/dump/dumpSlice";
import getProjectId from "../utils/getProjectId";

interface ProductMetadata {
  displayName: string;
  value: string;
}

const CollectingTableSizes = () => {
  const dispatch = useDispatch();
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const productMetadata = caseObject?.productMetadata ?? [];
  const projectUrl =
    productMetadata.find(
      (item: ProductMetadata) => item.displayName === "Project URL",
    )?.value || "";
  const user = useSelector(
    (state: RootState) => (state.user.userDetails?.name as string) ?? "",
  );
  const dumpObject = useSelector((state: RootState) => state.dump.data);
  const baseUrl = dumpObject.apiUrl;

  let projectId = "";
  if (projectUrl) {
    projectId = getProjectId(projectUrl);
  }
  const ticketId = caseObject?.caseId ?? "";
  const branch = dumpObject.chosenBranch;
  const { isLoading: environmentLoading } = useGetEnvironmentListQuery({
    baseUrl,
    projectId,
    branch,
    ticketId,
  });

  const [
    skipTableModal,
    {
      data: skipTablesList,
      isLoading: tableListIsLoading,
      isSuccess,
      error: tableListError,
    },
  ] = useLazySkipTablesModalQuery();

  const showSkipTablesModal = (branch: string) => {
    skipTableModal({ baseUrl, projectId, branch, user });
    if (isSuccess && skipTablesList) {
      switch (skipTablesList.dump_status) {
        case "table_size_collected":
          if (!tableListIsLoading || !tableListError) {
            dispatch(showSkipTablesList(skipTablesList.tables));
          }
          break;
        case "table_size_in_progress":
          dispatch(tableSizeInProgress());
          break;
        case "map_error":
          dispatch(mapError("error message"));
          break;
      }
    }
  };

  return {
    showSkipTablesModal,
    tableListIsLoading,
    environmentLoading,
    branch,
  };
};

export default CollectingTableSizes;
