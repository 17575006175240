/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { usePagination, DOTS } from "../../hooks/usePagination";

interface PaginationProps {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
  className: string;
}

const Pagination: React.FC<PaginationProps> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage = 1,
  pageSize,
  className,
}) => {
  const paginationRange =
    usePagination({
      currentPage,
      totalCount,
      siblingCount,
      pageSize,
    }) ?? [];

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={"pagination-container pagination-bar"}>
      <li
        className={`pagination-item pagination-item-left-arrow  pagination-item-border ${currentPage === 1 ? "disabled pagination-item-text-grey" : "pagination-item-text-blue"}`}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, key) => {
        if (pageNumber === DOTS) {
          return (
            <li className="pagination-item dots" key={key}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            className={`pagination-item ${pageNumber === currentPage ? "selected" : "pagination-item-border"}`}
            onClick={() => onPageChange(Number(pageNumber))}
            key={key}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`pagination-item pagination-item-right-arrow pagination-item-border ${currentPage === lastPage ? "disabled pagination-item-text-grey" : "pagination-item-text-blue"}`}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
