/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Flex, Item, TabList, TabPanels, Tabs } from "@adobe/react-spectrum";
import "../../styles/main.scss";
import EnvironmentDetailsTable from "./EnvironmentDetailsTable";
import OrgNotesDetailsTable from "./OrgNotesDetailsTable";

const AccountDetailsTabContainer: React.FC = () => {
  return (
    <Tabs aria-label="Augmented Analysis Tabs">
      <Flex>
        <TabList
          UNSAFE_className="analytics-tabs"
          flex="1 1 auto"
          minWidth="0px"
        >
          <Item key="environment" data-analytics-link-name="Environment tab">
            Environment
          </Item>
          {/* <Item key="adobeteams" data-analytics-link-name="Adobe Teams tab">
            Adobe Teams
          </Item> */}
          <Item key="orgnotes" data-analytics-link-name="Org Notes tab">
            Org Notes
          </Item>
        </TabList>
      </Flex>
      <TabPanels marginTop="size-200">
        <Item key="environment">
          <EnvironmentDetailsTable />
        </Item>
        <Item key="orgnotes">
          <OrgNotesDetailsTable />
        </Item>
      </TabPanels>
    </Tabs>
  );
};

export default AccountDetailsTabContainer;
