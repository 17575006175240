/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useState, useEffect } from "react";
import {
  Heading,
  Flex,
  Text,
  Dialog,
  Divider,
  Content,
  TextField,
  Checkbox,
  ButtonGroup,
  Button,
  DialogContainer,
  Image,
} from "@adobe/react-spectrum";
import DropdownPicker from "./DropdownPicker";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import {
  useGetProductFamiliesQuery,
  useGetJobRolesQuery,
  useGetUserPreferencesQuery,
  useSetUserPreferencesMutation,
} from "../../services/supportInsights";
import SlackLogo from "../../assets/svg/Slack_Logo.svg";
import { ToastQueue } from "@react-spectrum/toast";
import {
  setUserPreferences,
  setOpenPreferencesModal,
} from "../../store/userSlice";
import { ProductFamily } from "../../types/ProductFamily";

const UserPreferenceSetup = () => {
  const dispatch = useDispatch();
  const userPreferences = useSelector(
    (state: RootState) => state.user.preferences,
  );
  const openPreferencesModal = useSelector(
    (state: RootState) => state.user.openPreferencesModal,
  );

  const { data: userPreferencesResponse, isLoading: isUserPreferencesLoading } =
    useGetUserPreferencesQuery();

  const { data: productFamiliesResponse, isLoading: isProductFamiliesLoading } =
    useGetProductFamiliesQuery();
  const { data: jobRolesResponse, isLoading: isJobRolesLoading } =
    useGetJobRolesQuery();

  const [updateUserPreferences, { isLoading: isUpdatingPreferences }] =
    useSetUserPreferencesMutation();

  const [formState, setFormState] = useState({
    selectedJobRole: "",
    selectedProductFamilies: [] as string[],
    receiveCaseSlackNotifications: false,
    receiveOrgSlackNotifications: false,
    userInputJobRole: "",
  });

  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const handleFormChange = (field: string, value: any) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    let jobRoleName = formState.selectedJobRole;
    if (isOtherSelected && formState.userInputJobRole) {
      jobRoleName = formState.userInputJobRole;
    }

    const payload = {
      job_role_name: jobRoleName,
      product_families: formState.selectedProductFamilies,
      case_slack_notifications: formState.receiveCaseSlackNotifications,
      org_slack_notifications: formState.receiveOrgSlackNotifications,
    };

    try {
      const response = await updateUserPreferences({ payload }).unwrap();
      ToastQueue.positive("User Preferences Updated Successfully", {
        timeout: 5000,
      });
      dispatch(setUserPreferences(response.data));
      dispatch(setOpenPreferencesModal(false));
    } catch (error: any) {
      const errorMessage =
        error?.data?.message ||
        "An error occurred while updating user preferences. Please try again later.";
      ToastQueue.negative(errorMessage, { timeout: 5000 });
    }
  };

  const obtainProductFamilyNames = (productFamilies: ProductFamily[]) => {
    return productFamilies.map((productFamily) => productFamily.name);
  };

  useEffect(() => {
    if (formState.selectedJobRole === "Other...") {
      setIsOtherSelected(true);
    } else {
      setIsOtherSelected(false);
    }
  }, [formState.selectedJobRole]);

  useEffect(() => {
    if (
      userPreferencesResponse &&
      !userPreferencesResponse.data.job_role_name
    ) {
      dispatch(setOpenPreferencesModal(true));
    }
    if (userPreferencesResponse) {
      dispatch(setUserPreferences(userPreferencesResponse.data));
    }
  }, [dispatch, userPreferencesResponse]);

  useEffect(() => {
    if (userPreferences) {
      setFormState({
        selectedJobRole: userPreferences.job_role_name,
        selectedProductFamilies: obtainProductFamilyNames(
          userPreferences.product_families,
        ),
        receiveCaseSlackNotifications: userPreferences.case_slack_notifications,
        receiveOrgSlackNotifications: userPreferences.org_slack_notifications,
        userInputJobRole: "",
      });
    }
  }, [userPreferences]);

  if (
    isUserPreferencesLoading ||
    isProductFamiliesLoading ||
    isJobRolesLoading
  ) {
    return null;
  }

  const isSubmitButtonDisabled = !(
    formState.selectedJobRole &&
    formState.selectedProductFamilies.length > 0 &&
    (!isOtherSelected || (isOtherSelected && formState.userInputJobRole))
  );

  return (
    <DialogContainer onDismiss={() => dispatch(setOpenPreferencesModal(false))}>
      {openPreferencesModal && (
        <Dialog height="60vh">
          <Heading>
            <Flex alignItems="center" gap="size-100">
              <Text>User Preferences</Text>
            </Flex>
          </Heading>
          <Divider />
          <Content>
            <Flex direction="column" gap="size-200">
              <TextField
                width="100%"
                label="Organization"
                isReadOnly
                defaultValue="Digital Experience"
              />
              <DropdownPicker
                defaultValue="Select Your Role"
                items={jobRolesResponse?.data}
                defaultSelection={
                  formState.selectedJobRole ? [formState.selectedJobRole] : []
                }
                label="Role"
                selectionMode="single"
                setSelectedItemParent={(value) =>
                  handleFormChange("selectedJobRole", value[0])
                }
              />
              {isOtherSelected && (
                <TextField
                  width="100%"
                  label="Other Role"
                  description="Please specify your role"
                  onChange={(value) =>
                    handleFormChange("userInputJobRole", value)
                  }
                />
              )}
              <DropdownPicker
                defaultValue="Select The Products You Support"
                items={productFamiliesResponse?.data}
                defaultSelection={formState.selectedProductFamilies}
                label="Supported Product Families"
                selectionMode="multiple"
                setSelectedItemParent={(value) =>
                  handleFormChange("selectedProductFamilies", value)
                }
              />
              <Flex direction="column" gap="size-100" marginTop="size-300">
                <Image src={SlackLogo} alt="Slack Logo" width="25%" />
                <Checkbox
                  isSelected={formState.receiveCaseSlackNotifications}
                  onChange={(value) =>
                    handleFormChange("receiveCaseSlackNotifications", value)
                  }
                >
                  Send me notifications about updates to Cases in my watchlist.
                </Checkbox>
                <Checkbox
                  isSelected={formState.receiveOrgSlackNotifications}
                  onChange={(value) =>
                    handleFormChange("receiveOrgSlackNotifications", value)
                  }
                >
                  Send me notifications about updates to Orgs in my watchlist.
                </Checkbox>
              </Flex>
            </Flex>
          </Content>
          <ButtonGroup>
            <Button
              variant="secondary"
              onPress={() => dispatch(setOpenPreferencesModal(false))}
            >
              Cancel
            </Button>
            <Button
              isDisabled={isSubmitButtonDisabled}
              variant="accent"
              isPending={isUpdatingPreferences}
              onPress={handleSubmit}
            >
              Submit
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
};

export default UserPreferenceSetup;
