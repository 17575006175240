/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { IMenuItem } from "../../types/ISideNavbar";

const MarketoMeasureMenuData: IMenuItem[] = [
  {
    Solution: "Marketo Measure",
    LinkName: "Support Wiki",
    LinkURL:
      "https://wiki.corp.adobe.com/display/AMMCS/Adobe+Marketo+Measure+Support+Home",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Marketo Measure Support Wiki",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo Measure",
    LinkName: "Admin Console",
    LinkURL:
      "https://agent.adminconsole.adobe.com/organizations/{orgId}/contracts",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Admin Console link for Customer Org",
    PointOfContact: "KC Reynolds",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo Measure",
    LinkName: "Marketo Measure ExL",
    LinkURL:
      "https://experienceleague.adobe.com/en/docs/marketo-measure/using/home",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Experience League Home For Marketo Measure",
    PointOfContact: "KC Reynolds",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Marketo Measure",
    LinkName: "Marketo Measure Splunk",
    LinkURL: "https://splunk.or1.adobe.net/en-US/app/adobe_bizible/dashboards",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Marketo Measure Splunk Dashboards",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
];

export default MarketoMeasureMenuData;
