/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

export function formatDate(date: string): string {
  if (date === "") return "";
  const formatter = new Intl.DateTimeFormat(["en"], {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    timeZone: "UTC",
  });
  return formatter.format(new Date(date));
}

export function formatDateLocal(date: string): string {
  if (date === "") return "";
  const formatter = new Intl.DateTimeFormat(["en"], {
    month: "short", // Abbreviated month name
    day: "numeric", // Day of the month
    year: "numeric", // Full year
    hour: "numeric", // Hour
    minute: "numeric", // Minute
    hour12: true, // AM/PM format
    timeZoneName: "short", // Time zone abbreviation
  });
  return formatter.format(new Date(date));
}

export function getDate(inputString: string) {
  const date = new Date(inputString);
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${month}/${day}/${year}`;
}
