/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { View, ProgressCircle } from "@adobe/react-spectrum";
import * as stateTypes from "../../../types/stateTypes";
import CollectTableSizesButton from "./CollectTableSizeButton";
import SizeTableList from "./SizeTableList";
import DeployInProgress from "./DeployInProgress";
import DeployedLinks from "./DeployedLinks";
import DeleteButton from "./DeleteButton";
import useDumpTool from "../../../hooks/useDumpTool";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import CollectingTableSizes from "./CollectingTableSizes";
import FormView from "../../common/FormView";
import CollectingDumps from "./CollectingDumps";

const DumpTool: React.FC = () => {
  const data = useSelector((state: RootState) => state.dump);
  useDumpTool();

  return (
    <View>
      {data.currentState === stateTypes.PROJECT_ID_ERROR_STATE ? (
        <FormView title={"Deploy Dumps"} body={<p>Incorrect project id</p>} />
      ) : data.currentState === stateTypes.ENVIRONMENT_ERROR_STATE ? (
        <FormView
          title={"Deploy Dumps"}
          body={<p>Incorrect environment or wrong ticket type</p>}
          footerInfo={
            "If the project id is correct and still not loading, please check if your vpn is enabled and connected"
          }
        />
      ) : null}
      {data.currentState === stateTypes.MAP_ERROR_STATE ? (
        <FormView
          title={"Deploy Dumps"}
          body={
            <ProgressCircle size="S" aria-label="Loading…" isIndeterminate />
          }
          footerInfo={data.data.intermediateMessage}
          footerButtonComponent={<DeleteButton />}
        />
      ) : null}
      {data.currentState === stateTypes.DUMP_IN_PROGRESS_STATE ? (
        <CollectingDumps />
      ) : null}
      {data.currentState === stateTypes.TABLE_SIZE_IN_PROGRESS_STATE ? (
        <CollectingTableSizes />
      ) : null}
      {data.currentState === stateTypes.TABLE_SIZE_IS_NOT_COLLECTED_STATE ? (
        <CollectTableSizesButton />
      ) : null}
      {data.currentState === stateTypes.TABLE_SIZE_COLLECTED_STATE ? (
        <SizeTableList />
      ) : null}
      {data.currentState === stateTypes.DEPLOY_IN_PROGRESS_STATE ? (
        <DeployInProgress />
      ) : null}
      {data.data.intermediateState &&
      data.currentState !== stateTypes.DEPLOY_SUCCESS_STATE &&
      data.currentState !== stateTypes.DUMP_IN_PROGRESS_STATE ? (
        <FormView
          title={"Deploy Dumps"}
          body={
            <>
              <p>{data.data.intermediateMessage}</p>
            </>
          }
          footerInfo={"Operation in progress. Please wait"}
          footerButtonComponent={<DeleteButton />}
        />
      ) : null}
      {data.currentState === stateTypes.DEPLOY_SUCCESS_STATE ? (
        <DeployedLinks />
      ) : null}
      {data.currentState === stateTypes.HIPAA_ERROR_STATE ? (
        <>
          <p>HIPPA enabled account. The dump widget is restricted.</p>
        </>
      ) : null}
    </View>
  );
};

export default DumpTool;
