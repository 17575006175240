/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

const getAPIEndpointUrl = (region?: string) => {
  let url = window.env.REACT_APP_DUMP_TOOL_API_US;

  if (!region) {
    return url;
  }

  let listOfEuropeProjects = [
    "eu",
    "eu-1",
    "eu-2",
    "eu-3",
    "eu-4",
    "eu-5",
    "ap",
    "ap-3",
    "ap-4",
  ];

  // Extracting only the region name.
  if (listOfEuropeProjects.includes(region.split(".")[0])) {
    url = window.env.REACT_APP_DUMP_TOOL_API_EU || "";
  }

  return url;
};

export default getAPIEndpointUrl;
