/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { PropsWithChildren } from "react";
import { View, Flex } from "@adobe/react-spectrum";
import logo from "../../assets/svg/aug_analysis_logo.svg";

const DataContainer: React.FC<
  PropsWithChildren<{
    label: string;
  }>
> = ({ label, children }) => {
  return (
    <div className="data-container-wrapper">
      <View borderRadius="regular" backgroundColor="gray-50">
        <Flex direction="column" gap="size-50">
          <View padding="size-250">
            <Flex gap="size-150" direction="row" alignContent="center">
              <img className="aug-analysis-logo" src={logo} alt=""></img>
              <p className="aug-analysis-header">{label}</p>
            </Flex>
          </View>
          <View paddingX="size-1000" paddingBottom="size-500">
            {children}
          </View>
        </Flex>
      </View>
    </div>
  );
};

export default DataContainer;
