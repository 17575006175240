/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createSlice } from "@reduxjs/toolkit";
import { CfsState } from "../../types/cfs";

const initialState: CfsState = {
  aiSummary: {
    isLoading: false,
    prompt: "",
    text: "",
  },
  jiraTickets: [],
  systemPrompt: "",
  product: "",
  additionalContext: "",
};

const cfsSlice = createSlice({
  name: "cfs",
  initialState,
  reducers: {
    setProduct(state, action) {
      state.product = action.payload;
    },
    setAdditionalContext(state, action) {
      state.additionalContext = action.payload;
    },
    setAiSummary: (state, action) => {
      state.aiSummary = { ...state.aiSummary, ...action.payload };
    },
    setJiraTickets: (state, action) => {
      state.jiraTickets = [...action.payload];
    },
    setSystemPrompt: (state, action) => {
      state.systemPrompt = action.payload;
    },
    setJiraSummaries: (state, action) => {
      state.jiraSummaries = { ...state.jiraSummaries, ...action.payload };
    },
  },
});

export const {
  setAiSummary,
  setJiraTickets,
  setSystemPrompt,
  setProduct,
  setJiraSummaries,
  setAdditionalContext,
} = cfsSlice.actions;
export default cfsSlice.reducer;
