/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useDispatch, useSelector } from "react-redux";
import { setAiSummary } from "../../store/cfs/cfsSlice";
import { RootState } from "../../store/store";
import { fetchAIResponseStream } from "../../services/requestServices";

export const useGenerateCfs = () => {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);
  const cfsState = useSelector((state: RootState) => state.cfsAiSummery);
  const additionalContext = cfsState.additionalContext;
  const jiraTickets = cfsState.jiraTickets;
  const selectedProduct = cfsState.product;

  const generateCfs = () => {
    dispatch(
      setAiSummary({
        text: "",
        isLoading: true,
        prompt: additionalContext,
      }),
    );

    const replacements = [{ key: "{product}", value: selectedProduct }];

    let updatedSystemPrompt = cfsState.systemPrompt;
    replacements.forEach(({ key, value }) => {
      updatedSystemPrompt = updatedSystemPrompt?.replace(
        new RegExp(key, "g"),
        value,
      );
    });

    const updatedMessages = [{ role: "system", content: updatedSystemPrompt }];
    const requestBody = {
      messages: updatedMessages,
      jiraTickets: jiraTickets,
      additionalContext: additionalContext ?? "",
    };

    const url = `${window.env.REACT_APP_API_BASE}cfs/generate`;
    fetchAIResponseStream(url, token, requestBody, (response) =>
      dispatch(setAiSummary(response)),
    ).then((obj) => {
      if (obj?.error) {
        dispatch(
          setAiSummary({
            text: "Customer Facing Statement AI failed to generate response",
            isLoading: false,
          }),
        );
      }
    });
  };

  return [cfsState, generateCfs] as const;
};
