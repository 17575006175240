/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useState } from "react";
import { Button, Text, ProgressCircle } from "@adobe/react-spectrum";
import Refresh from "@spectrum-icons/workflow/Refresh";
import {
  useDynamicsCollectDataMutation,
  useRefreshTicketMutation,
} from "../../services/supportInsights";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import familyNameToCode from "../../utils/familyNameToCode";
import { handleRefresh } from "../../utils/handleRefresh";
import { collectDynamicsData } from "../../utils/collectDynamicsData";

function RefreshDataButton() {
  const dispatch = useDispatch();
  const [refreshTicketData, { isLoading: ticketIsLoading }] =
    useRefreshTicketMutation();
  const [dynamicsCollectData, { isLoading: dynamicsIsLoading }] =
    useDynamicsCollectDataMutation();
  const [isDisabled, setIsDisabled] = useState(false);

  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const caseId = caseObject?.caseId ?? "";
  const orgId = caseObject?.orgId ?? "";
  const rengaId = caseObject?.admin?.rengaId ?? "";
  const productFamily = caseObject?.productFamilyName ?? "";
  const productFamilyCode = familyNameToCode(productFamily);

  const handleRefreshClick = () => {
    handleRefresh({
      caseId,
      orgId,
      rengaId,
      productFamily,
      refreshTicketData,
      setIsDisabled,
      dispatch,
    }).then((updatedCaseObject) => {
      if (updatedCaseObject !== undefined && !dynamicsIsLoading) {
        collectDynamicsData({
          caseObject: updatedCaseObject,
          dynamicsCollectData,
          productFamilyCode,
          dispatch,
          setIsDisabled,
        });
      }
    });
  };

  return (
    <Button
      variant="cta"
      aria-label="Refresh data"
      isDisabled={ticketIsLoading || dynamicsIsLoading || isDisabled}
      onPress={handleRefreshClick}
    >
      {ticketIsLoading || dynamicsIsLoading ? (
        <ProgressCircle
          aria-label="Loading…"
          isIndeterminate
          size="S"
          marginEnd="size-150"
        />
      ) : (
        <Refresh />
      )}
      <Text>Refresh All</Text>
    </Button>
  );
}

export default RefreshDataButton;
