/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useState, SetStateAction, Dispatch, useEffect } from "react";
import { ListBox, Item, TextField } from "@adobe/react-spectrum";
import styled from "styled-components";
import { useHover } from "react-aria";
import type { Selection, Key } from "@adobe/react-spectrum";
import { ProductFamily } from "../../types/ProductFamily";

const StyledDiv = styled.div`
  position: relative;

  .textFieldContainer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .chevronIcon {
    position: absolute;
    right: 12px;
    font-size: 1em;
    top: 50%;
    transform: translateY(-50%); /* Center vertically */
    transition: transform 0.3s ease;
    pointer-events: none;
    transform: rotate(0deg);
  }

  .chevronOpen {
    transform: rotate(180deg);
    color: #147af3;
  }

  .listBoxOpen,
  .listBoxClosed {
    position: absolute;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #147af3;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
    overflow-y: auto;
  }

  .listBoxOpen {
    transform: scaleY(1);
  }

  .listBoxClosed {
    transform: scaleY(0);
    pointer-events: none;
  }

  .textFieldOpen input {
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    border-color: #147af3;
    color: #147af3;
  }
`;

type ItemObject = {
  id: number;
  name: string;
};

export interface DropdownPickerProps {
  defaultValue: string;
  items?: ItemObject[] | ProductFamily[];
  label: string;
  selectionMode: "single" | "multiple";
  setSelectedItemParent: Dispatch<SetStateAction<any>>;
  defaultSelection?: string | string[];
}

const DropdownPicker: React.FC<DropdownPickerProps> = ({
  defaultValue,
  items,
  label,
  selectionMode,
  setSelectedItemParent,
  defaultSelection,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>(defaultValue);
  const [selectedKeys, setSelectedKeys] = useState<Iterable<Key>>(new Set());

  const { hoverProps } = useHover({
    onHoverStart: () => setOpen(true),
    onHoverEnd: () => setOpen(false),
  });

  useEffect(() => {
    if (defaultSelection && defaultSelection.length > 0) {
      setSelectedItem(Array.from(defaultSelection).join(", "));
      setSelectedKeys(new Set(defaultSelection));
    } else {
      setSelectedItem(defaultValue);
      setSelectedKeys(new Set());
    }
  }, [defaultSelection, defaultValue]);

  const handleSelectionChange = (keys: Selection): void => {
    const newSelectedKeys = keys as Set<Key>;
    if (newSelectedKeys.size >= 1) {
      const selectedKeysString = Array.from(newSelectedKeys).join(", ");
      setSelectedItem(selectedKeysString);
      setSelectedKeys(newSelectedKeys);
      setSelectedItemParent(Array.from(newSelectedKeys));
    } else {
      setSelectedItem(defaultValue);
      setSelectedKeys(new Set());
      setSelectedItemParent([]);
    }
  };

  if (!items) {
    return null;
  }

  return (
    <StyledDiv {...hoverProps}>
      <div className="textFieldContainer">
        <TextField
          width="100%"
          label={label}
          isReadOnly
          value={selectedItem}
          UNSAFE_className={isOpen ? "textFieldOpen" : "textFieldClosed"}
        />
        <span className={`chevronIcon ${isOpen ? "chevronOpen" : ""}`}>▼</span>
      </div>
      <ListBox
        UNSAFE_className={isOpen ? "listBoxOpen" : "listBoxClosed"}
        aria-label={label}
        selectionMode={selectionMode}
        selectedKeys={selectedKeys}
        disallowEmptySelection={true}
        onSelectionChange={handleSelectionChange}
      >
        {items.map((item) => (
          <Item key={item.name}>{item.name}</Item>
        ))}
      </ListBox>
    </StyledDiv>
  );
};

export default DropdownPicker;
