/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { View, Picker, Item } from "@adobe/react-spectrum";
import useBranchSelector from "../../../hooks/useBranchSelector";

const BranchSelector: React.FC = () => {
  const { chooseEnvironmentHandler, dumpObject } = useBranchSelector();

  return (
    <View>
      {dumpObject?.environmentList && (
        <Picker
          label="Branch:"
          items={dumpObject.environmentList}
          selectedKey={dumpObject.chosenBranch}
          onSelectionChange={(selected) => {
            if (selected) {
              chooseEnvironmentHandler(String(selected));
            }
          }}
        >
          {(item) => <Item>{item["title"]}</Item>}
        </Picker>
      )}
    </View>
  );
};

export default BranchSelector;
