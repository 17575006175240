/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { Key } from "react";
import {
  View,
  ProgressCircle,
  Flex,
  ActionGroup,
  Item,
} from "@adobe/react-spectrum";
import Copy from "@spectrum-icons/workflow/Copy";
import Refresh from "@spectrum-icons/workflow/Refresh";
import { ToastQueue } from "@react-spectrum/toast";
import AiMarkdownRenderer from "../../aiAssistant/AiMarkdownRenderer";
import { useGenerateCfs } from "../../../hooks/cfsTool/useGenerateCfs";

export interface CfStatementProps {}

const CfStatement: React.FC<CfStatementProps> = ({}) => {
  const [cfsState, generateCfs] = useGenerateCfs();
  const summary = cfsState?.aiSummary;

  const handleSummaryButton = (key: Key) => {
    if (typeof key === "string") {
      if (key === "regenerate") {
        generateCfs();
      } else if (key === "copy") {
        navigator.clipboard
          .writeText(summary?.text || "")
          .then(() =>
            ToastQueue.positive("Copied to clipboard", { timeout: 3000 }),
          )
          .catch(() =>
            ToastQueue.negative("Failed to copy to clipboard", {
              timeout: 5000,
            }),
          );
      }
    }
  };
  const loading = (
    <View>
      <Flex direction="column">
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    </View>
  );

  if (!summary) {
    return loading;
  }

  const failed = (
    <p>
      <strong>Statement failed to generate</strong>
    </p>
  );

  const response =
    !summary.isLoading && !summary.text ? (
      failed
    ) : (
      <AiMarkdownRenderer text={summary.text || ""} />
    );

  return (
    <View UNSAFE_className="response">
      {response}
      {summary.isLoading && loading}
      <Flex justifyContent="start">
        <ActionGroup
          density="compact"
          isQuiet={true}
          onAction={handleSummaryButton}
        >
          <Item key="copy">
            <Copy
              UNSAFE_className="analytics-track-me"
              data-analytics-link-name="Copy Statement"
              data-analytics-view-name="CfStatment"
            />
          </Item>
          <Item key="regenerate">
            <Refresh
              UNSAFE_className="analytics-track-me"
              data-analytics-link-name="Regenerate Statement"
              data-analytics-view-name="CfStatment"
            />
          </Item>
        </ActionGroup>
      </Flex>
    </View>
  );
};

export default CfStatement;
