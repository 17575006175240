/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useState } from "react";
import { View, Flex, Heading } from "@adobe/react-spectrum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setAiSummary } from "../../../store/case/caseSlice";
import AiFooter from "./AiFooter";
import AiQuestion from "./AiQuestion";
import AiResponse from "./AiResponse";
import { fetchAIResponseStream } from "../../../services/requestServices";
import { promptMatrix, PromptMatrixKey } from "./prompts";
import { ToastQueue } from "@react-spectrum/toast";
import AiSuggestions from "../AiSuggestions/AiSuggestions";
import AiKnowledgeCenter from "../AiKnowledgeCenter/AiKnowledgeCenter";

function useAIQuestion() {
  const dispatch = useDispatch();

  const aiSummary = useSelector((state: RootState) => state.case.aiSummary)!;
  const question = aiSummary.prompt;
  const caseId =
    useSelector((state: RootState) => state.case.caseObject)?.caseId || "";

  const token = useSelector((state: RootState) => state.auth.token);

  const askQuestion = (question: string, promptKey: string | null) => {
    dispatch(
      setAiSummary({
        text: "",
        isLoading: true,
        prompt: question,
      }),
    );

    const requestBody = {
      caseId,
      prompt: question,
      promptKey: promptKey,
    };
    const url = `${window.env.REACT_APP_API_BASE}dynamics/${caseId}/aiSummary`;
    fetchAIResponseStream(url, token, requestBody, (response) =>
      dispatch(setAiSummary(response)),
    );
  };

  return [question, aiSummary, askQuestion] as const;
}

const AiSummary: React.FC = () => {
  const [question, aiSummary, setQuestion] = useAIQuestion();
  const [promptKey, setPromptKey] = React.useState<PromptMatrixKey | null>(
    null,
  );
  const { isLoading } = aiSummary;

  const [promptVisible, setPromptVisible] = useState(true);

  const setKey = (key: PromptMatrixKey) => {
    switch (key) {
      case "suggest-resolution":
      case "other-knowledge-center":
        // setQuestion('');
        setAiSummary({ text: "", isLoading: false, prompt: "" });
        setPromptKey(key);
        return;
    }

    const selectedPrompt = promptMatrix[key];
    if (!selectedPrompt || !selectedPrompt.prompt)
      return ToastQueue.negative(
        "Unable to execute the prompt. Please try a different one.",
        { timeout: 3000 },
      );
    askQuestion(selectedPrompt.prompt, key);
  };

  const askQuestion = (question: string, key: PromptMatrixKey | null) => {
    setPromptKey(key);
    setQuestion(question, key);
  };

  const promptItem = promptMatrix[promptKey as PromptMatrixKey];

  if (!question && !promptKey)
    return (
      <View>
        <Flex direction="column" gap="single-line-height">
          {!promptVisible && (
            <Flex direction="row" justifyContent="center">
              <View UNSAFE_style={{ textAlign: "center" }}>
                <Heading level={2}>
                  Let&rsquo;s get started with AI Assistant!
                </Heading>
                <p>Select one of the options below, or write your own query.</p>
              </View>
            </Flex>
          )}
          <AiFooter
            isLoading={isLoading}
            askQuestion={(prompt) =>
              askQuestion(prompt, "investigate-custom-prompt")
            }
            setKey={setKey}
            promptVisible={promptVisible}
            setPromptVisible={setPromptVisible}
          />
        </Flex>
      </View>
    );

  const response = (() => {
    switch (promptKey) {
      case "suggest-resolution":
        return (
          <View>
            <AiSuggestions />
          </View>
        );
      case "other-knowledge-center":
        return (
          <View>
            <AiKnowledgeCenter />
          </View>
        );
      default:
        return (
          <View>
            <AiResponse
              summary={aiSummary}
              prompt={question}
              promptKey={promptKey ?? void 0}
              askQuestion={(prompt, promptKey) =>
                askQuestion(prompt, promptKey ?? null)
              }
            />
          </View>
        );
    }
  })();

  const editable = !["suggest-resolution", "other-knowledge-center"].includes(
    promptKey || "",
  );

  return (
    <View>
      <Flex direction="column" gap="single-line-height">
        <AiQuestion
          askQuestion={(prompt) => askQuestion(prompt, promptKey)}
          prompt={question}
          editable={editable && !isLoading}
          isHidden={promptKey === "other-knowledge-center"}
        >
          {promptItem ? <em>{promptItem.label}</em> : question}
        </AiQuestion>
        {response}
        <AiFooter
          isLoading={aiSummary.isLoading}
          askQuestion={(prompt) =>
            askQuestion(prompt, "investigate-custom-prompt")
          }
          setKey={setKey}
          promptVisible={promptVisible}
          setPromptVisible={setPromptVisible}
        />
      </Flex>
    </View>
  );
};

export default AiSummary;
