/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

const CLIENT_ID = window.env.REACT_APP_CLIENT_ID as string;
const ISSUER = window.env.REACT_APP_ISSUER as string;
const OKTA_TESTING_DISABLEHTTPSCHECK = window.env
  .REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK as string;

//const BASENAME = import.meta.env.BASE_URL || '';
// BASENAME includes trailing slash
const REDIRECT_URI = window.env.REACT_APP_REDIRECT_URI as string;

if (!ISSUER) throw Error("ISSUER not defined");
if (!CLIENT_ID) throw Error("CLIENT_ID not defined");
if (!OKTA_TESTING_DISABLEHTTPSCHECK)
  throw Error("OKTA_TESTING_DISABLEHTTPSCHECK not defined");
if (!REDIRECT_URI) throw Error("REDIRECT_URI not defined");

const OktaConfig = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};

export default OktaConfig;
