/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { transitionState } from "../store/knowledgeCenter/knowledgeCenterSlice";

export function useKbArticles(caseId: string) {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);

  const reload = () => {
    getKbArticles();
  };

  const getKbArticles = () => {
    const url = `${window.env.REACT_APP_API_BASE}knowledgeCenter/articles/${caseId}`;
    dispatch(
      transitionState({
        targetState: "loadingKnowledgeArticles",
      }),
    );
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-OKTA-Authorization": `Bearer ${token}`,
      },
    }).then((response) => {
      if (!response.body || !response.ok) {
        dispatch(
          transitionState({
            targetState: "kbArticlesFetched",
            aiArticle:
              "Sorry, but AI suggestions are not available at the moment.",
          }),
        );
      }
      const jsonData = response.json();
      jsonData.then((data) => {
        dispatch(
          transitionState({
            targetState: "kbArticlesFetched",
            kbArticlesFetched: true,
            kbArticles: data?.data,
          }),
        );
      });
    });
  };

  return [getKbArticles, reload] as const;
}
