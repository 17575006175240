/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import creative_logo from "../assets/svg/search_landing_page.svg";
import { Link } from "@adobe/react-spectrum";

const DefaultHomePage: React.FC = () => {
  return (
    <div className="search_container">
      <div className="search_heading_wrapper">
        <p className="search_heading">
          An automated One Stop Integration Shop.
        </p>
        <p className="search_description">
          An internal-facing portal that provides engineers with contextual
          tooling and insights, troubleshooting data points, recommendations,
          and all required information for case resolution. Now, you don't need
          to navigate through multiple applications and platforms; everything
          you need is consolidated into one cohesive environment.
        </p>
        <p className="search_description">
          Search for a Case Number, IMS Org Id or Customer Name to get started.
        </p>
        <Link
          variant={"primary"}
          UNSAFE_className={"search_link"}
          target={"_blank"}
          href={
            "https://wiki.corp.adobe.com/display/CESTRATINTELandOPS/Get+Started"
          }
        >
          Want to learn more? Read these knowledge base articles.
        </Link>
        <section className="FeatureCard">
          <p>
            The following are solution-specific intergrations with Oasis. Use
            the search bar above to find a Dynamics case or one of the following
            integrations related to your solution.
          </p>
          <h3 style={{ margin: 0 }}>Workfront</h3>
          <ul style={{ margin: 0 }}>
            <li>
              <a
                href="/workfront"
                target="_blank"
                className="analytics-track-me"
                data-analytics-link-name="Workfront Automation"
                data-analytics-view-name="Landing page"
              >
                Workfront Automation
              </a>
            </li>
            <li>
              <a
                href="/workfront/create-defect"
                target="_blank"
                className="analytics-track-me"
                data-analytics-link-name="Workfront Automation: Create a Defect"
                data-analytics-view-name="Landing page"
              >
                Create a Defect
              </a>
            </li>
          </ul>
        </section>
      </div>
      <div className="search_image_wrapper">
        <img className="search_homepage-img" src={creative_logo} alt="" />
      </div>
    </div>
  );
};

export default DefaultHomePage;
