/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect, useState, useRef } from "react";
import {
  TableView,
  Column,
  TableHeader,
  TableBody,
  Row,
  Cell,
  Flex,
  View,
  ProgressCircle,
} from "@adobe/react-spectrum";
import { useGetFindingsQuery } from "../../services/supportInsights";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import MessageComponent from "../common/MessageComponent";
import { Finding } from "../../types/finding";
import FindingModal from "./FindingModal";
import familyNameToCode from "../../utils/familyNameToCode";
import lowImpact from "../../assets/svg/low_impact_icon.svg";
import mediumImpact from "../../assets/svg/medium_impact_icon.svg";
import highImpact from "../../assets/svg/high_impact_icon.svg";
import severeImpact from "../../assets/svg/severe_impact_icon.svg";
import elevatedImpact from "../../assets/svg/elevated_impact_icon.svg";
import { micromark } from "micromark";
import { gfm, gfmHtml } from "micromark-extension-gfm";
import { ToastQueue } from "@react-spectrum/toast";
import Pagination from "../common/Pagination";

import {
  COLUMNS_FINDING_TABLE,
  FINDING_PER_PAGE,
} from "../../constants/finding";
import DropDownFilter, {
  ORDER_ACTION,
  handleSortIcon,
} from "../common/DropDownFilter";
import { findIndex, get, isEmpty, set, startCase, toLower } from "lodash";
import {
  FindingRequest,
  fetchFindingByCategoryOrImpact,
} from "../../services/requestServices";
import { setFindings } from "../../store/case/caseSlice";
import getApiRoute from "../../utils/getApiRoute";
import { latestDataPointDate } from "../../utils/latestDataPointDate";
import { setLastAnalysisDate } from "../../store/lastAnalysisSlice";
import {
  setSortBy as setSortByAction,
  setFilterColumn as setFilterColumnAction,
} from "../../store/findings/findingsSlice";

const riskLevelIcons = {
  low: lowImpact,
  medium: mediumImpact,
  high: highImpact,
  severe: severeImpact,
  elevated: elevatedImpact,
};

export default function FindingsTable() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const filterColumn = useSelector(
    (state: RootState) => state.findings.filterColumn,
  );
  const sortBy = useSelector((state: RootState) => state.findings.sortBy);

  const filterColumnRef = useRef(filterColumn);
  const sortByRef = useRef(sortBy);

  const tableData =
    useSelector((state: RootState) => state.case.findings) ?? [];
  const dispatch = useDispatch();

  const caseId =
    useSelector((state: RootState) => state.case.caseObject?.caseId) ?? "";
  const productFamily =
    useSelector(
      (state: RootState) => state.case.caseObject?.productFamilyName,
    ) ?? "";
  const isTicketRefreshing = useSelector(
    (state: RootState) => state.case.isTicketRefreshing,
  );
  const isDataCollecting = useSelector(
    (state: RootState) => state.case.isDataCollecting,
  );

  const productFamilyCode = familyNameToCode(productFamily);
  const filterURL = getApiRoute(
    `/api/v1/products/${productFamilyCode}/dynamics/${caseId}/findings/filter`,
  );
  const token = useSelector((state: RootState) => state.auth.token);

  const { data, error, isLoading, isFetching } = useGetFindingsQuery(
    {
      caseId: caseId,
      productFamily: productFamilyCode,
    },
    {
      skip: !caseId || !productFamily || isTicketRefreshing,
    },
  );

  useEffect(() => {
    filterColumnRef.current = filterColumn;
    sortByRef.current = sortBy;
  }, [filterColumn, sortBy]);

  const getColumnFilter = (column: string) => {
    const searchColumnIndex = findIndex(COLUMNS_FINDING_TABLE, ["uid", column]);
    const findingRequest = { caseId: caseId, productFamily: productFamilyCode };
    set(findingRequest, `page`, 1);
    return COLUMNS_FINDING_TABLE[searchColumnIndex].filterList;
  };

  useEffect(() => {
    const publicMessage = get(error, "data.meta.publicMessage", "");
    const errorMessage = get(error, "status");
    const findingData = get(data, "data", []);

    if (!isFetching && publicMessage !== "") {
      ToastQueue.negative(publicMessage, { timeout: 5000 });
    }
    if (!isFetching && findingData.length > 0) {
      setTotalRecord(get(data, "meta.findings_count", 0));
      dispatch(setFindings([...findingData]));
    }
    if (errorMessage === "FETCH_ERROR") {
      ToastQueue.negative(
        "Sorry for the inconvenience, we are facing Data Retrieval Error. Please visit after sometime.",
        { timeout: 5000 },
      );
    }
    if (findingData.length !== 0) {
      const lastAnalysisDate = latestDataPointDate(findingData);
      if (lastAnalysisDate) {
        dispatch(setLastAnalysisDate(lastAnalysisDate));
      }
    }
  }, [data, dispatch, error, isFetching, isTicketRefreshing]);

  const handleSortOrder = (columnName: string, orderBy: ORDER_ACTION) => {
    const currentFilterColumn = filterColumnRef.current;
    const searchColumnIndex = findIndex(COLUMNS_FINDING_TABLE, [
      "uid",
      columnName,
    ]);
    const findingRequest = { caseId: caseId, productFamily: productFamilyCode };
    set(findingRequest, `sortOrder`, `{\"${columnName}\": \"${orderBy}\"}`);
    set(findingRequest, `page`, 1);

    const impact = get(currentFilterColumn, "impact", []);
    const categories = get(currentFilterColumn, "categories", []);
    if (impact.length > 0) {
      set(findingRequest, "impact", impact);
    }
    if (categories.length > 0) {
      set(findingRequest, "categories", categories);
    }
    dispatch(setSortByAction({ column: columnName, orderby: orderBy }));
    callSearchCase(findingRequest);
    setCurrentPage(1);
    handleSortIcon(searchColumnIndex);
  };

  const handleFilter = (columnName: string, filterList: string[]) => {
    const currentSortBy = sortByRef.current;
    const currentFilterColumn = filterColumnRef.current;
    const findingRequest = { caseId: caseId, productFamily: productFamilyCode };
    const filterColumns = { ...currentFilterColumn };
    localStorage.setItem("filterColumnName", columnName);
    set(filterColumns, `${columnName}`, filterList);

    if (!isEmpty(currentSortBy)) {
      set(
        findingRequest,
        `sortOrder`,
        `{\"${get(currentSortBy, "column", "")}\": \"${get(currentSortBy, "orderby", "")}\"}`,
      );
    }
    set(findingRequest, `page`, 1);
    set(findingRequest, `${columnName}`, filterList);

    const impact = get(filterColumns, "impact", []);
    const categories = get(filterColumns, "categories", []);
    if (impact.length > 0) {
      set(findingRequest, "impact", impact);
    }
    if (categories.length > 0) {
      set(findingRequest, "categories", categories);
    }
    setCurrentPage(1);
    dispatch(setFilterColumnAction(filterColumns));
    callSearchCase(findingRequest);
  };

  const handlePaging = (page: number) => {
    const currentSortBy = sortByRef.current;
    const currentFilterColumn = filterColumnRef.current;
    const findingRequest = { caseId: caseId, productFamily: productFamilyCode };
    const impact = get(currentFilterColumn, "impact", []);
    const categories = get(currentFilterColumn, "categories", []);
    if (page > 0 && page !== currentPage) {
      set(findingRequest, "page", page);
      if (!isEmpty(currentSortBy)) {
        set(
          findingRequest,
          `sortOrder`,
          `{\"${get(currentSortBy, "column", "")}\": \"${get(currentSortBy, "orderby", "")}\"}`,
        );
      }
      if (impact.length > 0) {
        set(findingRequest, "impact", impact);
      }
      if (categories.length > 0) {
        set(findingRequest, "categories", categories);
      }
      setCurrentPage(page);
      callSearchCase(findingRequest);
    }
  };

  const callSearchCase = (requestPayload: FindingRequest) => {
    setTableLoading(true);
    if (!isEmpty(requestPayload)) {
      fetchFindingByCategoryOrImpact(requestPayload, token)
        .then((response) => {
          const findingList = get(response, "data.data", []);
          setTotalRecord(get(response, "data.meta.findings_count", 0));
          dispatch(setFindings(findingList));
          setTableLoading(false);
        })
        .catch((error: any) => {
          ToastQueue.negative(
            "Sorry for the inconvenience, we are facing Data Retrieval Error. Please visit after sometime.",
            { timeout: 5000 },
          );
        });
    }
  };

  if (isDataCollecting || isTicketRefreshing) {
    return (
      <MessageComponent title="Refreshing Findings" isLoading={true}>
        Refreshing findings. This may take a couple of minutes.
      </MessageComponent>
    );
  }

  if (isLoading) {
    return <MessageComponent title="Loading Findings" isLoading={true} />;
  }

  if (!caseId || !productFamily || error) {
    return (
      <MessageComponent title="Failed to retrieve data">
        An error occurred while retrieving the data. Please refresh the page to
        try again.
      </MessageComponent>
    );
  }

  if (!data || data.data.length === 0) {
    return (
      <MessageComponent title="Findings unavailable">
        No findings have been discovered for this case. Check back later.
      </MessageComponent>
    );
  }

  return (
    <View>
      <View UNSAFE_className="search-table-wrapper">
        {tableLoading && (
          <View
            backgroundColor="gray-400"
            padding="size-300"
            UNSAFE_className="search-table-loading-wrapper"
          >
            <ProgressCircle
              aria-label="Loading…"
              staticColor="white"
              isIndeterminate
            />
          </View>
        )}
        <TableView
          aria-label="Findings Table"
          UNSAFE_className="table-container"
        >
          <TableHeader columns={COLUMNS_FINDING_TABLE}>
            {(column) => (
              //   @ts-ignore
              <Column key={column.uid} width={column.width}>
                {column.filter || column.sortBy ? (
                  <DropDownFilter
                    name={column.name}
                    endpointFilter={filterURL.href}
                    endpointMethod="GET"
                    filterQuery={{ page: 1, filterName: column.uid }}
                    fetchOptionList={getColumnFilter}
                    columnName={column.uid}
                    filterOption={column.filter}
                    hasColumnSortBy={false}
                    options={[]}
                    onSortOrder={handleSortOrder}
                    onApplyFilter={(items) => handleFilter(column.uid, items)}
                  />
                ) : (
                  column.name
                )}
              </Column>
            )}
          </TableHeader>
          <TableBody>
            {renderTableRows(tableData, caseId, productFamily)}
          </TableBody>
        </TableView>
      </View>
      <View paddingY="size-250">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={totalRecord}
          pageSize={FINDING_PER_PAGE}
          onPageChange={handlePaging}
          siblingCount={3}
        />
      </View>
    </View>
  );
}

function renderTableRows(
  data: Finding[],
  caseId: string,
  productFamily: string,
): JSX.Element[] {
  // type DeclarationID = number;
  // const groupedFindings: Record<DeclarationID, Finding[]> = {};

  // for (const finding of data) {
  //   if (!groupedFindings[finding.finding_declaration_id]) {
  //     groupedFindings[finding.finding_declaration_id] = [];
  //   }
  //   groupedFindings[finding.finding_declaration_id].push(finding);
  // }

  return Array.from(Object.values(data)).map((findings, index) => (
    <Row key={index}>
      <Cell>
        <FindingModal
          findings={[findings]}
          caseId={caseId}
          productFamily={productFamily}
        />
      </Cell>
      <Cell>
        {findings.categories.replaceAll(/"/g, "").replace(/[\[\]']+/g, "")}
      </Cell>
      <Cell>
        <Flex gap="size-100">
          <img
            alt=""
            className="risk-level-icon"
            src={
              riskLevelIcons[
                findings.impact.toLowerCase() as keyof typeof riskLevelIcons
              ] ?? ""
            }
          />
          {startCase(toLower(findings.impact))}
        </Flex>
      </Cell>
      <Cell>
        <div
          className="finding-documentation-list"
          dangerouslySetInnerHTML={{
            __html:
              micromark(findings.documentation, "utf-8", {
                extensions: [gfm()],
                htmlExtensions: [gfmHtml()],
                allowDangerousHtml: true,
              }) +
              `<img src="data:null;," onerror="hydrateLinks(this)" alt="">`,
          }}
        ></div>
      </Cell>
    </Row>
  ));
}

// @ts-expect-error -- HACK: Use something other than images to move the datapoint table
window.hydrateLinks ??= (element: HTMLElement) => {
  const links = element.parentElement?.querySelectorAll("a") ?? [];
  for (const link of Array.from(links)) {
    link.target = "_blank";
    link.onclick = () => {
      window.open(link.href);
    };
  }
  element.parentElement?.removeChild(element);
};
