/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Flex, ProgressCircle, View } from "@adobe/react-spectrum";
import CreateUser from "./CreateUser";
import UserInfo from "./UserInfo";
import useTempAdmin from "../../../hooks/useTempAdmin";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Hipaa from "./Hipaa";
import FormView from "../../common/FormView";

const TempAdmin: React.FC = () => {
  const { handleDeleteAdmin, handleCreateAdmin, tempAdminObject } =
    useTempAdmin();
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const ticketStatus = caseObject?.statusReasonText?.toLowerCase() || "";
  const hipaa = tempAdminObject.hipaaStatus;

  const disabledButton = [
    "resolution provided",
    "pending response",
    "resolution confirmed",
    "issue obsolete",
    "auto closed",
    "cancel/misroute",
    "merged",
  ].includes(ticketStatus);
  const buttonMsg = disabledButton
    ? `Disabled in the status ${caseObject?.statusReasonText}`
    : "";

  return (
    <Flex>
      {hipaa === undefined ? (
        <>
          <FormView
            title={"Loading..."}
            body={
              <View>
                <div>
                  <ProgressCircle
                    aria-label="Loading…"
                    isIndeterminate
                    size="S"
                    marginEnd="size-150"
                  />
                </div>
              </View>
            }
            footerInfo={
              "If the loading is taking too long, please check if your vpn is enabled and connected"
            }
          />
        </>
      ) : hipaa ? (
        <Hipaa />
      ) : !tempAdminObject.user.email ? (
        <CreateUser
          createAdmin={handleCreateAdmin}
          disabledButton={disabledButton}
          buttonMsg={buttonMsg}
        />
      ) : tempAdminObject.user.email ? (
        <UserInfo
          deleteAdmin={handleDeleteAdmin}
          tempAdminObject={tempAdminObject}
          buttonMsg={buttonMsg}
        />
      ) : (
        <ProgressCircle
          aria-label="Loading…"
          isIndeterminate
          size="S"
          marginEnd="size-150"
        />
      )}
    </Flex>
  );
};

export default TempAdmin;
