/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createSlice } from "@reduxjs/toolkit";
import { Case, InternalNote } from "../../types/case";

const initialState: Case = {
  ticketId: "",
  orgId: "",
  orgName: "",
  rengaId: "",
  searchText: "",
  aiSummary: {
    isLoading: false,
    prompt: "",
    text: "",
  },
  aiSuggestions: {
    isLoading: false,
  },
  caseList: null,
  findings: [],
  isTicketRefreshing: false,
  isDataCollecting: false,
  internalNotes: [],
  meta: {
    ticket_id: 0,
    org_id: 0,
  },
};

const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    setCase: (state, action) => {
      state.caseObject = action.payload;
    },
    setTicketId: (state, action) => {
      state.ticketId = action.payload;
    },
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
    setOrgName: (state, action) => {
      state.orgName = action.payload;
    },
    setRengaId: (state, action) => {
      state.rengaId = action.payload;
    },
    setAiSummary: (state, action) => {
      state.aiSummary = { ...state.aiSummary, ...action.payload };
    },
    setAiSuggestions: (state, action) => {
      state.aiSuggestions = { ...state.aiSuggestions, ...action.payload };
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setCaseList: (state, action) => {
      state.caseList = { ...action.payload };
    },
    setFindings: (state, action) => {
      state.findings = [...action.payload];
    },
    setIsTicketRefreshing: (state, action) => {
      state.isTicketRefreshing = action.payload;
    },
    setIsDataCollecting: (state, action) => {
      state.isDataCollecting = action.payload;
    },
    setCaseMeta: (state, action) => {
      state.meta = { ...state.meta, ...action.payload };
    },
    setInternalNotes: (state, action) => {
      state.internalNotes = action.payload; // Replace all internalNotes
    },
  },
});

export const {
  setCase,
  setTicketId,
  setOrgId,
  setOrgName,
  setRengaId,
  setAiSummary,
  setAiSuggestions,
  setSearchText,
  setCaseList,
  setFindings,
  setIsTicketRefreshing,
  setIsDataCollecting,
  setCaseMeta,
  setInternalNotes,
} = caseSlice.actions;
export default caseSlice.reducer;
