/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { View, Flex, ProgressCircle } from "@adobe/react-spectrum";

interface UserHomePageCardProps {
  children?: React.ReactNode;
  count?: number;
  title?: string;
  isLoading?: boolean;
}

const HomePageCard: React.FC<UserHomePageCardProps> = ({
  children,
  count,
  title,
  isLoading = false,
}) => {
  return (
    <View
      borderRadius="regular"
      backgroundColor="gray-50"
      paddingX="size-200"
      paddingY="size-200"
      UNSAFE_className="user-page-card"
      height={"size-3000"}
    >
      <View overflow="auto" height="100%">
        <Flex marginBottom="size-200" direction="row" gap="size-100">
          {title && <p className="user-page-card-title">{title}</p>}
          {count && count > 0 && (
            <p className="user-page-card-title-count">{count}</p>
          )}
        </Flex>
        <View overflow="auto" height="80%">
          {isLoading ? (
            <Flex justifyContent="center" alignItems="center" height="100%">
              <ProgressCircle size="M" isIndeterminate />
            </Flex>
          ) : (
            children
          )}
        </View>
      </View>
    </View>
  );
};

export default HomePageCard;
