/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

// src/layouts/TabContainer.tsx
import React from "react";
import Navbar from "../components/common/Navbar"; // Adjust the import path as needed
import { Grid, View } from "@adobe/react-spectrum";
import "../styles/main.scss";
import "../styles/BaseLayout.scss";
import Footerlogo from "../assets/svg/Footer_logo.svg";
import { Outlet } from "react-router-dom";

type MinimalLayoutProps = {
  children?: React.ReactNode; // Optional children prop
};
const MinimalLayout: React.FC<MinimalLayoutProps> = ({ children }) => {
  return (
    <Grid
      areas={["header  header", "content content", "footer footer"]}
      columns={["1fr", "5fr"]}
      rows={["max-content", "auto", "30px"]}
      height="100vh"
      gap="size-0"
    >
      <View
        gridArea="header"
        backgroundColor="gray-200"
        UNSAFE_style={{ position: "sticky", top: 0, zIndex: 2 }}
      >
        <Navbar />
      </View>

      <View
        gridArea="content"
        overflow="auto"
        UNSAFE_style={{ maxHeight: "calc(100vh - 81px)" }} // Subtract the total height of the header and footer
      >
        {" "}
        {/* Adjust the subtraction to account for header and feedback banner height */}
        {children || <Outlet />}
      </View>
      <View gridArea="footer" UNSAFE_className="footer">
        <View UNSAFE_className="footer_left_section">
          <img className="Footer-adobeLogo" src={Footerlogo} alt=""></img>
          <View UNSAFE_style={{ paddingTop: "3px" }}>
            &copy; Copyright © 2024 Adobe. All rights reserved.
          </View>
        </View>
        <View UNSAFE_className="footer_right_section">
          <a
            className="footer_right_section_link"
            href="https://wiki.corp.adobe.com/display/CESTRATINTELandOPS/Privacy+Policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            className="footer_right_section_link"
            href="https://wiki.corp.adobe.com/display/CESTRATINTELandOPS/Terms+of+Service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{" "}
        </View>
        {/* Footer content here */}
      </View>
    </Grid>
  );
};

export default MinimalLayout;
