/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface userRoleSlice {
  userRole: string | null;
  error: string | null;
}

const initialState: userRoleSlice = {
  userRole: null,
  error: null,
};

export const userRoleSlice = createSlice({
  name: "userrole",
  initialState,
  reducers: {
    setUserRole: (state, action: PayloadAction<string>) => {
      state.userRole = action.payload;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setUserRole, setError } = userRoleSlice.actions;

export default userRoleSlice.reducer;
