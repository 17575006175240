/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import {
  useGetEnvironmentListQuery,
  useGetEnvironmentQuery,
  useGetProjectHipaaStatusQuery,
  useGetProjectRegionQuery,
  useUpdateBranchMutation,
} from "../services/dumpTool";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  chooseBranch,
  dumpInLoadingEnvironmentState,
  environmentError,
  tableSizeNotCollected,
  dumpInProgress,
  projectIdError,
  dumpStuck,
  tableSizeInProgress,
  showSkipTablesList,
  deleteDumpAction,
  enableDeleteButton,
  dumpAndDeploySuccess,
  deployInProgress,
  mapError,
  setApiUrl,
  hipaaError,
} from "../store/dump/dumpSlice";
import { useEffect } from "react";
import getAPIEndpointUrl from "../utils/getApiEndpointUrl";
import getProjectId from "../utils/getProjectId";
import getEnvironment from "../utils/getEnvironment";

// Custom hook for fetching environments and handling environment-related logic
const useEnvironmentData = (
  projectId: string,
  branchToRequest: string,
  ticketId: string,
) => {
  const dispatch = useDispatch();
  const dumpObject = useSelector((state: RootState) => state.dump);
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const productMetadata = caseObject?.productMetadata ?? [];
  const baseUrl = dumpObject.data.apiUrl;

  let defaultBranch =
    productMetadata
      .find((item) => item.displayName === "Environment")
      ?.value.toLowerCase() || "";
  //workaround for Stage issue in Dynamic Environment field
  defaultBranch = getEnvironment(defaultBranch);

  const vpnError =
    "Can not use widget, make sure you are using VPN connection.";

  const { data: environmentResponse, isLoading } = useGetEnvironmentQuery(
    { baseUrl, projectId, ticketId },
    { skip: !projectId },
  );
  const [
    updateBranch,
    {
      isLoading: updateBranchIsLoading,
      data: branch,
      error: branchUpdateError,
    },
  ] = useUpdateBranchMutation();

  useEffect(() => {
    if (!branchToRequest) {
      if (isLoading || vpnError !== dumpObject.data.intermediateMessage) {
        dispatch(dumpInLoadingEnvironmentState());
      }

      if (!environmentResponse) {
        dispatch(environmentError());
        return;
      }

      const { dump_status, environment } = environmentResponse;

      switch (dump_status) {
        case "branch_chosen":
          if (environment) {
            dispatch(chooseBranch(environment));
          } else if (defaultBranch) {
            dispatch(chooseBranch(defaultBranch));
            updateBranch({ baseUrl, branch: defaultBranch, ticketId });
          }
          break;

        case "none":
          dispatch(chooseBranch(defaultBranch));
          updateBranch({ baseUrl, branch: defaultBranch, ticketId });
          break;

        case "invalid_environment":
          dispatch(dumpInProgress(vpnError));
          break;

        default:
          break;
      }
    }
  }, [isLoading]);
};

const useDumpTool = () => {
  const dispatch = useDispatch();
  const dumpObject = useSelector((state: RootState) => state.dump);
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const productMetadata = caseObject?.productMetadata ?? [];

  const projectUrl =
    productMetadata.find((item) => item.displayName === "Project URL")?.value ||
    "";
  let baseUrl = getAPIEndpointUrl() ?? "";

  let projectId = "";
  const branchToRequest = dumpObject.data.chosenBranch;
  const ticketId = caseObject?.caseId ?? "";

  if (projectUrl) {
    projectId = getProjectId(projectUrl) ?? "";
    if (!projectId) {
      dispatch(projectIdError());
    }
  }

  //Checking project region and updating api url
  const { data: region } = useGetProjectRegionQuery(
    { baseUrl, projectId },
    { pollingInterval: 3000 },
  );
  baseUrl = getAPIEndpointUrl(region?.region) ?? "";

  // Handle fetching environment data
  useEnvironmentData(projectId, branchToRequest, ticketId);

  const {
    data: environmentList,
    currentData,
    isLoading,
  } = useGetEnvironmentListQuery(
    { baseUrl, projectId, branch: branchToRequest, ticketId },
    { pollingInterval: 3000, skip: !branchToRequest },
  );

  if (environmentList && !projectId) {
    dispatch(projectIdError());
  }

  const { data: hipaaStatus } = useGetProjectHipaaStatusQuery(
    { baseUrl, projectId },
    { pollingInterval: 3000 },
  );

  useEffect(() => {
    dispatch(setApiUrl(baseUrl));

    if (hipaaStatus?.hipaa === true) {
      dispatch(hipaaError());
    } else {
      switch (environmentList?.dump_status) {
        case "table_size_collected":
          if (environmentList.tables) {
            dispatch(showSkipTablesList(environmentList.tables));
          }
          break;

        case "deploy_in_progress":
          dispatch(
            deployInProgress({
              message: "Deploy in progress.",
              progress:
                "progress" in environmentList
                  ? environmentList.progress
                  : undefined,
              stage:
                "stage" in environmentList ? environmentList.stage : undefined,
              latestDeployLogs:
                "latestDeployLogs" in environmentList
                  ? environmentList.latestDeployLogs
                  : undefined,
              logLocation:
                "logLocation" in environmentList
                  ? environmentList.logLocation
                  : undefined,
            }),
          );
          break;

        case "installed":
          dispatch(dumpAndDeploySuccess(environmentList));
          dispatch(enableDeleteButton());
          break;

        case "collect_in_progress":
          dispatch(dumpInProgress(environmentList.message));
          break;

        case "delete_in_progress":
          dispatch(deleteDumpAction(true));
          dispatch(enableDeleteButton());
          break;

        case "table_size_in_progress":
          dispatch(tableSizeInProgress());
          break;

        case "map_error":
          dispatch(mapError("Map error"));
          break;

        case "collect_failed":
          dispatch(dumpStuck(environmentList.message));
          console.log("Dump stuck" + environmentList.message);
          break;

        case "none":
          dispatch(tableSizeNotCollected());
          break;

        default:
          break;
      }
    }
  }, [
    hipaaStatus,
    dumpObject.data.apiUrl,
    dumpObject.data.dump_status,
    environmentList?.dump_status,
    dumpObject.data.chosenBranch,
    currentData,
  ]);
};

export default useDumpTool;
