/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import {
  Dialog,
  DialogTrigger,
  Heading,
  Content,
  Button,
} from "@adobe/react-spectrum";
import Close from "@spectrum-icons/workflow/Close";
import logo from "../../assets/svg/adobe_logo.svg";
import AccountDetailsTabContainer from "./AccountDetailsTabContainer";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
interface AccountDetailsModalProps {
  onClose: () => void;
}

const AccountDetailsModal: React.FC<AccountDetailsModalProps> = ({
  onClose,
}) => {
  const orgId = useSelector((state: RootState) => state.case.caseObject?.orgId);
  const orgName = useSelector(
    (state: RootState) => state.case.caseObject?.orgName,
  );
  return (
    <Dialog UNSAFE_className="oasis-dialog">
      <header className="oasis-dialog__ribbon">
        <div className="oasis-dialog__ribbon_group">
          <img className="modal-adobeLogo" src={logo} alt="" />
          <span className="">Account Information</span>
        </div>
        <div className="oasis-dialog__ribbon_group">
          <Button
            variant="overBackground"
            aria-label="Close"
            UNSAFE_className={"oasis-dialog__ribbon-button"}
            onPress={onClose}
          >
            <Close size={"S"} />
          </Button>
        </div>
      </header>
      <Content>
        <div className="account-details">
          <span className="account-details-orgid">
            IMS Org ID:
            <span className="orgId-color">{orgId}</span>
          </span>
          <span className="account-details-orgid">
            Org Name:
            <span className="orgId-color">
              {orgName ? orgName : "Not Provided"}
            </span>
          </span>
        </div>
        <AccountDetailsTabContainer />
      </Content>
    </Dialog>
  );
};

export default AccountDetailsModal;
