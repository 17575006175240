/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const healthCheckApi = createApi({
  reducerPath: "healthCheckApi",
  baseQuery: fetchBaseQuery({
    baseUrl: new URL("/", window.env.REACT_APP_API_BASE).toString(),
    timeout: 10000,
  }),
  endpoints: (builder) => ({
    getHealthCheck: builder.query<{ status: "ok" }, void>({
      query: () => `/api/health`,
    }),
  }),
});

export const { useGetHealthCheckQuery } = healthCheckApi;
