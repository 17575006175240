/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import {
  Button,
  ButtonGroup,
  Flex,
  Heading,
  View,
} from "@adobe/react-spectrum";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import TableDataView from "./TableDataView";
import { useAiArticle } from "../../../hooks/useAiArticle";
import { resetState } from "../../../store/knowledgeCenter/knowledgeCenterSlice";

function DuplicatedArticles() {
  const knowledgeCenterState = useSelector(
    (state: RootState) => state.knowledgeCenter,
  );
  const [, , , reload] = useAiArticle();
  let [isReviewed, setIsReviewed] = React.useState(false);
  const dispatch = useDispatch();

  let duplicatedArticlesCollection = [];
  const limitOfArticlesToShow = 3;
  for (let i = 0; i < limitOfArticlesToShow; i++) {
    duplicatedArticlesCollection.push({
      URL: knowledgeCenterState.duplicatedArticles[i].url,
      TEXT:
        knowledgeCenterState.duplicatedArticles[i].text.slice(0, 100) + "...",
    });
  }
  return (
    <View width={"100%"}>
      <Flex direction="row" justifyContent="left" marginTop={"size-150"}>
        <Heading level={2}>
          Please check the below articles to ensure there is no duplicate.
        </Heading>
      </Flex>
      {TableDataView(duplicatedArticlesCollection, (url: string) => {
        window.open(url, "_blank");
      })}
      <Flex direction="row" justifyContent="left" marginTop={"size-150"}>
        <input
          type="checkbox"
          id="scales"
          name="scales"
          checked={isReviewed}
          onChange={(e) => setIsReviewed(e.target.checked)}
        />
        <label htmlFor="scales">
          I have reviewed and verified there is no duplicate that is exists in
          this list.
        </label>
      </Flex>
      <Flex direction="row" justifyContent="left" marginTop={"size-150"}>
        <ButtonGroup>
          <Button
            variant={"cta"}
            isDisabled={!isReviewed}
            data-analytics-link-name="Review New Article"
            data-analytics-view-name="Knowledge Articles"
            onPress={() => {
              reload();
            }}
          >
            Create new article
          </Button>
          <Button
            variant={"secondary"}
            marginStart={"size-150"}
            data-analytics-link-name="Duplicate Confirmed By User"
            data-analytics-link-type="exit"
            data-analytics-view-name="Knowledge Articles"
            onPress={() => {
              dispatch(resetState());
            }}
          >
            Cancel, I found a duplicate
          </Button>
        </ButtonGroup>
      </Flex>
    </View>
  );
}

export default DuplicatedArticles;
