/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import FormView from "../../common/FormView";
import { ProgressCircle, View } from "@adobe/react-spectrum";
import DeleteButton from "./DeleteButton";

const CollectingDumps: React.FC = () => {
  return (
    <FormView
      title={"Deploy Dumps"}
      body={
        <View>
          <p>Collecting dump files</p>
          <ProgressCircle size="S" aria-label="Loading…" isIndeterminate />
        </View>
      }
      footerInfo={"Downloading dump files, please wait..."}
      footerButtonComponent={<DeleteButton />}
    />
  );
};

export default CollectingDumps;
