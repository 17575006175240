/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { InternalNote } from "../../types/case";
import { DialogTrigger, Flex, Link, Text } from "@adobe/react-spectrum";
import AccountDetailsModal from "../augmentedAnalysis/AccountDetailsModal";
import WatchlistSwitch from "./WatchlistSwitch";

interface OrgInfoProps {
  orgId: number;
  orgCode: string | undefined;
  orgName: string | undefined;
  orgInternalNotes: InternalNote[] | undefined;
}

const OrgInfo: React.FC<OrgInfoProps> = ({ orgId, orgCode }) => {
  const isUserPersonaFeatureActive =
    localStorage.getItem("userPersonaFeatureActive") === "true";
  return (
    <Flex direction="column" gap={5}>
      <Text>{orgCode ? orgCode : "Org Id Unavailable"}</Text>
      <DialogTrigger>
        <Link isQuiet>View Account Details</Link>
        {(close) => <AccountDetailsModal onClose={close} />}
      </DialogTrigger>
      {isUserPersonaFeatureActive && (
        <WatchlistSwitch itemId={orgId} itemType="org" />
      )}
    </Flex>
  );
};

export default OrgInfo;
