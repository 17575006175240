/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import Modal from "./Modal";
import {
  ActionBar,
  ActionBarContainer,
  Cell,
  Column,
  Item,
  Link,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
  Selection,
  Key,
  View,
  ProgressCircle,
} from "@adobe/react-spectrum";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import React, { useEffect, useMemo, useState } from "react";
import MessageComponent from "../common/MessageComponent";
import { useGetDataPointsQuery } from "../../services/supportInsights";
import Bug from "@spectrum-icons/workflow/Bug";
import Download from "@spectrum-icons/workflow/Download";
import Refresh from "@spectrum-icons/workflow/Refresh";
import { DataPoint } from "../../types/datapoints";
import { formatDate } from "../../utils/formatDate";
import familyNameToCode from "../../utils/familyNameToCode";
import { ToastQueue } from "@react-spectrum/toast";

import {
  Format,
  convertAndCopyForDataPoints,
  convertDataPointsToPDF,
  downloadDataPointsAsCsv,
  aggregateDataPoints,
} from "../../utils/dataExportUtils";
import { useDynamicsCollectDataMutation } from "../../services/supportInsights";

import { DataAggregator } from "../../utils/dataAggregator";
import BugReporter from "../../utils/BugReporter";
import Pagination from "../common/Pagination";
import { get, isEmpty, orderBy, uniqBy, upperFirst } from "lodash";
import DropDownFilter, {
  ORDER_ACTION,
  OptionsMenu,
  handleSortIcon,
} from "../common/DropDownFilter";
import { latestDataPointDate } from "../../utils/latestDataPointDate";
import { setLastAnalysisDate } from "../../store/lastAnalysisSlice";

interface DataPointTable {
  code: string;
  source?: string;
  dataPoint: DataPoint[];
}

interface ColumnSortOrder {
  name: string;
  orders: "asc" | "desc";
}

interface ColumnFilter {
  name: string;
  options: string[];
}

const PageSize = 20;
const excludedDatapointsFromTable = ["bash-dsat", "bash-predictive-dsat"];

const DataPointsTable = () => {
  let [selectedKeys, setSelectedKeys] = React.useState<Selection>(new Set());
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataPoint, setDataPoint] = useState<DataPointTable[]>([]);
  const [totalRecord, setTotalRecord] = useState<number>(0);

  const [nameDataPoint, setNameDataPoint] = useState<OptionsMenu[]>([]);
  const [sourceDataPoint, setSourceDataPoint] = useState<OptionsMenu[]>([]);
  const [filterColumn, setFilterColumn] = useState<ColumnFilter | null>(null);
  const [sortBy, setSortBy] = useState<ColumnSortOrder | null>(null);

  const caseID = useSelector((state: RootState) => state.case.ticketId);
  const productFamily =
    useSelector(
      (state: RootState) => state.case.caseObject?.productFamilyName,
    ) ?? "";
  const isTicketRefreshing = useSelector(
    (state: RootState) => state.case.isTicketRefreshing,
  );
  const isDataCollecting = useSelector(
    (state: RootState) => state.case.isDataCollecting,
  );
  const productFamilyCode = familyNameToCode(productFamily);
  const { data, error, isFetching, isLoading } = useGetDataPointsQuery(
    {
      caseId: caseID,
      productFamily: productFamilyCode,
    },
    {
      skip: !caseID || !productFamily || isTicketRefreshing,
    },
  );
  const datapoints = data?.data ?? [];
  const datapointsMeta: any = data?.meta ?? {};
  const datapointsError: any =
    datapointsMeta &&
    (datapointsMeta.error != null ? datapointsMeta.error : "");

  const [dynamicsCollectData, { isLoading: dynamicsIsLoading }] =
    useDynamicsCollectDataMutation();
  const caseObject = useSelector((state: RootState) => state.case.caseObject);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const errorPublicMessage = get(error, "data.meta.publicMessage", "");
    const publicMessage = get(data, "meta.publicMessage", "");
    const networkError = get(error, "status");
    if (
      !isFetching &&
      !isTicketRefreshing &&
      (publicMessage || errorPublicMessage)
    ) {
      ToastQueue.negative(publicMessage ? publicMessage : errorPublicMessage, {
        timeout: 5000,
      });
    }
    if (networkError === "FETCH_ERROR") {
      ToastQueue.negative(
        "Sorry for the inconvenience, we are facing Data Retrieval Error. Please visit after sometime.",
        { timeout: 5000 },
      );
    }
    if (data) {
      const datapoints = data?.data ?? [];
      const lastAnalysisDate = latestDataPointDate(datapoints);
      if (lastAnalysisDate) {
        dispatch(setLastAnalysisDate(lastAnalysisDate));
      }

      // Sorting from newest to oldest
      const reversedDatapoints = [...datapoints].reverse();

      const codesToDataPointsMap = new Map<string, DataPoint[]>();
      for (const datapoint of reversedDatapoints) {
        if (!excludedDatapointsFromTable.includes(datapoint.code)) {
          if (codesToDataPointsMap.has(datapoint.code)) {
            codesToDataPointsMap.get(datapoint.code)?.push(datapoint);
          } else {
            codesToDataPointsMap.set(datapoint.code, [datapoint]);
          }
        }
      }
      const dataList = Array.from(
        codesToDataPointsMap.entries(),
        ([code, dataPoint]) => ({
          code,
          source: upperFirst(code.split("-")[0]),
          dataPoint,
        }),
      );
      setDataPoint(dataList);

      const nameList = dataList.map((item, index) => ({
        id: index + 1,
        name: get(item, "dataPoint[0].name", ""),
      }));
      setNameDataPoint(nameList);

      const sourceList = uniqBy(
        dataList.map((item, index) => {
          return { id: index + 1, name: item.dataPoint[0].connector ?? "" };
        }),
        "name",
      );
      setSourceDataPoint(sourceList);
    }
  }, [data, dispatch, error, isFetching, isTicketRefreshing]);

  const currentTableData = useMemo<DataPointTable[]>(() => {
    let dataPointList = [...dataPoint];

    if (!isEmpty(filterColumn) && filterColumn.options.length > 0) {
      if (filterColumn.name === "connector") {
        dataPointList = dataPointList.filter((item) =>
          filterColumn.options.includes(item.dataPoint[0].connector ?? ""),
        );
      } else if (filterColumn.name === "name") {
        dataPointList = dataPointList.filter((item) =>
          filterColumn.options.includes(item.dataPoint[0].name),
        );
      }
    }

    if (!isEmpty(sortBy)) {
      dataPointList = orderBy(
        dataPointList,
        [`dataPoint[0].${sortBy.name}`],
        [sortBy.orders],
      );
    }

    setTotalRecord(dataPointList.length);
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return dataPointList.slice(firstPageIndex, lastPageIndex);
  }, [dataPoint, currentPage, sortBy, filterColumn]);

  const onAction = (key: Key) => {
    const keys = selectedKeys;
    const selectedDataPoints =
      keys !== "all"
        ? datapoints.filter((dp) => keys.has(dp.code))
        : datapoints;
    switch (key) {
      case "downloadCsv": {
        if (!selectedDataPoints.length) return;
        return downloadDataPointsAsCsv(
          selectedDataPoints,
          "DataPoints",
          ["Name", "Connector", "Collected at", "Description", "Org", "Value"],
          ["name", "connector", "created_at", "description", "org", "value"],
        );
      }
      case "download": {
        if (!selectedDataPoints.length) return;
        return convertDataPointsToPDF(selectedDataPoints, "DataPoints");
      }
      case "bug": {
        const seen = new Set<string>();
        const buggyDatapoints = selectedDataPoints.filter((dp) => {
          if (seen.has(dp.code)) return false;
          seen.add(dp.code);
          return true;
        });
        const reportUrl = BugReporter.generateUrl({
          summary: `Issue with ${buggyDatapoints.length} data points for case ${caseID}`,
          description:
            "*Data points*:\n" +
            buggyDatapoints.map((dp) => `* ${dp.name} (${dp.code})`).join("\n"),
        });
        return window.open(reportUrl);
      }
      case "refresh": {
        if (!selectedDataPoints.length || isDisabled) return;
        setIsDisabled(true);
        return dynamicsCollectData({
          payload: {
            case: caseObject,
            data_points: Array.from(
              new Set(selectedDataPoints.map((dp) => dp.code)),
            ),
          },
          productFamilyCode: productFamilyCode,
        })
          .unwrap()
          .then(() => {
            ToastQueue.positive("Data Successfully Refreshed", {
              timeout: 5000,
            });
            setIsDisabled(false);
          })
          .catch((error) => {
            ToastQueue.negative(
              error.data?.meta?.errors?.length
                ? error.data.meta.errors.join("<br>")
                : "An error occurred while refreshing the ticket data. Please try again.",
              { timeout: 5000 },
            );
            setIsDisabled(false);
          });
      }
    }
  };

  const handleSortOrder = (columnName: string, orderBy: ORDER_ACTION) => {
    handleSortIcon(columnName === "name" ? 0 : 1);
    setSortBy({
      name: columnName,
      orders: orderBy === "ascending" ? "asc" : "desc",
    });
    setCurrentPage(1);
  };

  const handleFilter = (columnName: string, filterList: string[]) => {
    localStorage.setItem("filterColumnName", columnName);
    setFilterColumn({ name: columnName, options: filterList });
    setCurrentPage(1);
  };

  if (isDataCollecting || isTicketRefreshing) {
    return (
      <MessageComponent title="Refreshing Datapoints" isLoading={true}>
        Refreshing datapoints. This may take a couple of minutes.
      </MessageComponent>
    );
  }

  if (isLoading) {
    return <MessageComponent title="Loading Datapoints" isLoading={true} />;
  }

  if (datapointsError) {
    return (
      <MessageComponent title="Failed to retrieve data">
        {datapointsError}
      </MessageComponent>
    );
  }
  if (!datapoints.length && !error) {
    //TODO: add toast
    return (
      <MessageComponent title="Data points unavailable">
        No data points were collected for this case. Check back later.
      </MessageComponent>
    );
  }
  if (error) {
    return (
      <MessageComponent title="Failed to retrieve data">
        An error occurred while retrieving the data. Please refresh the page to
        try again.
      </MessageComponent>
    );
  }

  const datapointEntries: Array<[string, DataPoint[]]> =
    aggregateDataPoints(datapoints);

  if (datapointEntries.length === 0) {
    return (
      <MessageComponent title="No Datapoints to Display">
        No data points were collected for this case. Check back later.
      </MessageComponent>
    );
  }

  return (
    <div>
      <ActionBarContainer>
        <TableView
          aria-label="Data points table"
          selectionMode="multiple"
          selectedKeys={selectedKeys}
          onSelectionChange={setSelectedKeys}
          UNSAFE_className="table-container"
        >
          <TableHeader>
            <Column width={"2fr"}>
              {nameDataPoint.length > 0 && (
                <DropDownFilter
                  name={`Name`}
                  columnName={"name"}
                  filterOption={true}
                  hasColumnSortBy={false}
                  options={nameDataPoint}
                  onSortOrder={handleSortOrder}
                  onApplyFilter={(items) => handleFilter("name", items)}
                />
              )}
            </Column>
            <Column width="1fr">Data</Column>
            <Column width="1fr">
              {sourceDataPoint.length > 0 && (
                <DropDownFilter
                  name={`Source`}
                  columnName={"connector"}
                  filterOption={true}
                  hasColumnSortBy={false}
                  options={sourceDataPoint}
                  onSortOrder={handleSortOrder}
                  onApplyFilter={(items) => handleFilter("connector", items)}
                />
              )}
            </Column>
            <Column width="2fr">Last Collection Date</Column>
          </TableHeader>
          <TableBody>
            {currentTableData.map(({ dataPoint }) => (
              <Row key={dataPoint[0].code}>
                <Cell>{dataPoint[0].name}</Cell>
                <Cell>{formatDatapoints(dataPoint)}</Cell>
                <Cell>{extractDataSource(dataPoint[0])}</Cell>
                <Cell>{formatDate(dataPoint[0].created_at)}</Cell>
              </Row>
            ))}
          </TableBody>
        </TableView>
        <View paddingY="size-250">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalRecord}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
            siblingCount={3}
          />
        </View>
        <ActionBar
          isEmphasized
          selectedItemCount={selectedKeys === "all" ? "all" : selectedKeys.size}
          onAction={onAction}
          onClearSelection={() => setSelectedKeys(new Set())}
        >
          <Item key="bug">
            <Bug />
            <Text>Report issue</Text>
          </Item>
          <Item key="downloadCsv">
            <Download />
            <Text>Download CSV</Text>
          </Item>
          <Item key="download">
            <Download />
            <Text>Download PDF</Text>
          </Item>
          <Item key="refresh">
            {dynamicsIsLoading ? (
              <ProgressCircle
                aria-label="Loading…"
                isIndeterminate
                size="S"
                marginEnd="size-150"
              />
            ) : (
              <Refresh />
            )}
            <Text>Refresh</Text>
          </Item>
        </ActionBar>
      </ActionBarContainer>
    </div>
  );
};

export default DataPointsTable;

function formatDatapoints(datapoints: DataPoint[]) {
  const aggregator = new DataAggregator();
  aggregator.add(datapoints);

  if (!aggregator.hasData()) {
    return <em>No data found</em>;
  }

  // If the datapoints have mixed data, format them individually.
  return (
    <Modal
      contentType="Datapoint"
      dialogHeader={`Data for ${datapoints[0].name}`}
      dialogDownloadFormat={Format.pdf}
      dialogDescription={`${datapoints[0].description}`}
      dialogShowDownloadBtn={true}
      dialogJSON={aggregator.aggregate()}
      dialogDownloadFun={convertAndCopyForDataPoints}
      dialogShowSourceBtn={!!datapoints[0].source_link}
      dialogShowSourceLink={
        datapoints[0]?.source_link &&
        getSourceLink(datapoints[0].source_link)?.linkUrl
          ? getSourceLink(datapoints[0].source_link)?.linkUrl
          : undefined
      }
      dialogShowSourceText={
        datapoints[0]?.source_link &&
        getSourceLink(datapoints[0].source_link)?.linkText
          ? getSourceLink(datapoints[0].source_link)?.linkText
          : undefined
      }
      trigger={
        <Link
          UNSAFE_className="analytics-track-me"
          data-analytics-link-name={datapoints[0].name}
          data-analytics-view-name="Data points List"
        >
          View data
        </Link>
      }
    >
      {aggregator.render()}
    </Modal>
  );
}

const sourceMap = {
  bash: "Bash",
  nrql: "New Relic",
  splunk: "Splunk",
} as const;

function extractDataSource(datapoint: DataPoint) {
  const source = datapoint.connector;
  const sourceLabel =
    source in sourceMap ? sourceMap[source as keyof typeof sourceMap] : source;
  if (datapoint?.source_link) {
    const sourceLink = getSourceLink(datapoint.source_link);
    if (sourceLink?.linkUrl) {
      return renderLink(sourceLabel, sourceLink.linkUrl);
    }
  }
  return sourceLabel;
}

function renderLink(linkText: string, linkUrl: string) {
  return (
    <Link href={linkUrl} target="_blank">
      {linkText}
    </Link>
  );
}

function getSourceLink(
  linkData: string,
): { linkText: string; linkUrl: string } | null {
  const linkDataParsed = JSON.parse(linkData);
  const linkText: string = linkDataParsed!.text;
  const linkUrl: string = linkDataParsed!.url;
  if (linkText && linkUrl) {
    return { linkText, linkUrl };
  }
  return null;
}
